import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "../HSM.css";
import { StateBoxProps, StateToolsProps } from "../utils/interface/HSM";
import HSMStateOwnership from "./HSMStateOwnership";
import HSMConditionsBox from "./HSMConditionsBox";
import HSMStateTools from "./HSMStateTools";
import ReactPlayer from "react-player";
import { FaExternalLinkAlt, FaExpand, FaTimes } from "react-icons/fa";

// Add a type declaration for debounce timers
interface Window {
  textUpdateTimeouts?: {[key: string]: NodeJS.Timeout};
}

export const HSMStateBox = ({
  type,
  text,
  imageLink,
  videoLink,
  setImageLink,
  setVideoLink,
  preconditions,
  setPreconditions,
  postconditions,
  setPostconditions,
  setText,
  ownership,
  setOwnership,
  discussionButtons,
  hideTools = false,
  viewOnlyMode,
  editing,
  deleteSelf,
  showComments,
  showPapers,
  papers,
  onClick,
  setCursorPointer,
  setCursorAuto,
  humanMode,
  onClickDeepout,
  setActivityProps,
  uid,
  setIsUnsavedChanges,
}: StateBoxProps) => {
  const [showingConditions, setShowingConditions] = useState(false);
  const textareaRef = useRef(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [localText, setLocalText] = useState(text || "");
  // Use a ref to store the debounce timer
  const textUpdateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  // Add ref to store cursor position
  const cursorPositionRef = useRef<number | null>(null);

  // Update local text when props change
  useEffect(() => {
    setLocalText(text || "");
  }, [text]);

  // Add a debug effect that logs activity structure when it might have been updated
  useEffect(() => {
    if (setActivityProps && uid) {
      // Add this debugging function to check current activity state
      const logActivityState = () => {
        // CHANGED: Don't use setActivityProps for debugging to avoid recursion
        console.log(`Text updated in state ${uid}: "${localText}"`);
      };
      
      // Log state after each text change (debounced)
      const timeoutId = setTimeout(logActivityState, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [localText, uid, setActivityProps]);

  // Debug log for troubleshooting
  useEffect(() => {
    console.log(`HSMStateBox rendered for type: ${type}, uid: ${uid || 'unknown'}`);
    console.log(`showComments defined: ${!!showComments}, showPapers defined: ${!!showPapers}`);
    
    // Log image link information 
    if (imageLink) {
      console.log(`State has imageLink: ${imageLink}`);
    } else {
      console.log(`State ${uid} has no imageLink prop`);
    }
  }, [type, uid, showComments, showPapers, imageLink]);

  const onClickTrash = () => {
    deleteSelf();
  };

  const onClickConditions = () => {
    setShowingConditions((prev) => !prev);
  };

  const emptyOnClick = () => {};

  const updateActivityWithLinks = (key: string, value: string) => {
    // Get the current activity state first
    setActivityProps((prevActivity: any) => {
      try {
        // Defensive check for undefined activity
        if (!prevActivity) {
          console.error("Cannot update with undefined activity");
          return {}; 
        }
        
        // Create a deep copy of the activity to avoid reference issues
        let updatedActivity;
        try {
          // Add extra logging to diagnose potential issues
          console.log(`About to copy activity for key=${key}, value=${value}`);
          
          // Ensure the activity is a valid object before trying to copy it
          if (typeof prevActivity !== 'object') {
            console.error("prevActivity is not an object:", typeof prevActivity);
            return prevActivity;
          }
          
          updatedActivity = JSON.parse(JSON.stringify(prevActivity));
          console.log("Activity copied successfully");
        } catch (parseError) {
          console.error("Error parsing activity:", parseError);
          
          // If JSON serialization fails, try a manual shallow copy
          console.log("Attempting manual copy...");
          updatedActivity = {...prevActivity};
          
          // Only copy children array if it exists
          if (prevActivity.children && Array.isArray(prevActivity.children)) {
            updatedActivity.children = [...prevActivity.children];
          }
        }
        
        // Make sure buildingBlocks is initialized
        if (!updatedActivity.buildingBlocks) {
          updatedActivity.buildingBlocks = {
            userFunctionality: "",
            userBehavior: "",
            environmentModel: "",
            caregiverFunctionality: "",
            caregiverBehavior: "",
            robotModel: ""
          };
        }
        
        // If this is a specific state (has uid), update the media links for this state
        if (uid) {
          console.log(`Attempting to set ${key}=${value} for state ${uid}`);
          
          // Recursively find and update the state with matching uid
          const updateStateMedia = (state) => {
            if (!state) return false;
            
            if (state.uid === uid) {
              // Update the specified property for this specific state
              state[key] = value;
              
              // Ensure the state has buildingBlocks
              if (!state.buildingBlocks) {
                state.buildingBlocks = {
                  userFunctionality: "",
                  userBehavior: "",
                  environmentModel: "",
                  caregiverFunctionality: "",
                  caregiverBehavior: "",
                  robotModel: ""
                };
              }
              
              console.log(`Successfully updated ${key} for state ${uid}:`, value);
              return true;
            }
            
            // If this state has children, search them
            if (state.children && Array.isArray(state.children)) {
              for (let i = 0; i < state.children.length; i++) {
                if (updateStateMedia(state.children[i])) {
                  return true;
                }
              }
            }
            return false;
          };
          
          // Start the recursive search from the root
          const found = updateStateMedia(updatedActivity);
          
          if (!found) {
            // If we couldn't find the state in the tree, set it directly in the root
            console.warn(`Could not find state ${uid} in nested children, setting directly on root`);
            updatedActivity[key] = value;
          }
        } else {
          // If no specific state (uid), update at the activity level
          updatedActivity[key] = value;
        }
        
        // Verify the updated activity is valid before returning
        try {
          JSON.stringify(updatedActivity);
          return updatedActivity;
        } catch (stringifyError) {
          console.error("Updated activity failed JSON test:", stringifyError);
          return prevActivity; // Return original on error
        }
      } catch (error) {
        console.error("Error in updateActivityWithLinks:", error);
        return prevActivity; // Return unchanged on error
      }
    });
  };

  // Create local state for image and video links in case props are not provided
  const [localImageLink, setLocalImageLink] = useState(imageLink || '');
  const [localVideoLink, setLocalVideoLink] = useState(videoLink || '');

  // If the props change, update local state
  useEffect(() => {
    setLocalImageLink(imageLink || '');
  }, [imageLink]);

  useEffect(() => {
    setLocalVideoLink(videoLink || '');
  }, [videoLink]);

  // Create fallback functions for setImageLink and setVideoLink
  const safeSetImageLink = (link: string) => {
    // Update local state
    setLocalImageLink(link);
    // If setImageLink is provided as a prop, use it
    if (setImageLink) {
      setImageLink(link);
    } else {
      console.warn("setImageLink not provided, using fallback mechanism");
      // If setActivityProps is available, use it to update the state
      if (setActivityProps) {
        updateActivityWithLinks('imageLink', link);
      }
    }
  };

  const safeSetVideoLink = (link: string) => {
    // Update local state
    setLocalVideoLink(link);
    // If setVideoLink is provided as a prop, use it
    if (setVideoLink) {
      setVideoLink(link);
    } else {
      console.warn("setVideoLink not provided, using fallback mechanism");
      // If setActivityProps is available, use it to update the state
      if (setActivityProps) {
        updateActivityWithLinks('videoLink', link);
      }
    }
  };

  let toolsProps: StateToolsProps = {
    onClickComments: () => {
      showComments();
    },
    onClickPapers: () => {
      showPapers();
    },
    onClickTrash: onClickTrash || emptyOnClick,
    onClickDeeper: onClick || emptyOnClick,
    onClickConditions: onClickConditions || emptyOnClick,
    onClickDeepout: onClickDeepout || emptyOnClick,
    onImageFileSelected: async (file: File) => {
      // Using our safe setter instead of direct check
      if (!setImageLink && !setActivityProps) {
        console.error("Neither setImageLink nor setActivityProps provided");
        return;
      }
      
      console.log("Starting image upload for state:", uid);
      
      const formData = new FormData();
      formData.append("file", file);
      // Add state and avatar information to track the association
      if (uid) formData.append("stateId", uid);
      
      try {
        // Get the backend URL from env variable or fallback to a default
        const backendUrl = process.env.REACT_APP_BACKEND_BASE_URI || 'http://localhost:8080';
        console.log(`Uploading image to: ${backendUrl}/upload`);
        
        const response = await fetch(
          `${backendUrl}/upload`, 
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          const imageUrl = data.fileUrl;
          console.log("Received fileUrl from server:", imageUrl);

          // Construct absolute URL properly
          const fullImageUrl = `${backendUrl}${imageUrl}`;
          console.log("Setting image URL to:", fullImageUrl);
          
          try {
            // First set it locally so UI updates immediately
            if (setImageLink) {
              setImageLink(fullImageUrl);
            }
            
            // Then update activity props with the link for the specific state
            if (uid) {
              console.log(`Updating state ${uid} with imageLink: ${fullImageUrl}`);
              updateActivityWithLinks('imageLink', fullImageUrl);
            } else {
              console.warn("No UID for state, setting imageLink at root level");
              updateActivityWithLinks('imageLink', fullImageUrl);
            }
            
            // Mark as unsaved changes
            if (setIsUnsavedChanges) {
              setIsUnsavedChanges(true);
            }
            
            // Log changes for debugging
            console.log(`Image updated for state ${uid}, waiting for Save Changes button to save to database`);
            
            // Log current state to help with debugging
            setTimeout(() => {
              // Don't use setActivityProps for debugging to avoid recursion
              console.log(`Image link set for state ${uid}:`, fullImageUrl);
            }, 500);
          } catch (linkError) {
            console.error("Error setting image link:", linkError);
          }
        } else {
          console.error("Error uploading image", await response.text());
          alert("Failed to upload image. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Error uploading image. Please check your connection.");
      }
    },

    onVideoUrlEntered: (url: string) => {
      // Use our safe video link setter
      safeSetVideoLink(url);
      console.log("Video URL set for state", uid, ":", url);
      
      // Mark as unsaved changes
      if (setIsUnsavedChanges) {
        setIsUnsavedChanges(true);
      }
      
      // Update the activity state with the video link
      if (setActivityProps && uid) {
        console.log(`Updating state ${uid} with videoLink: ${url}`);
        updateActivityWithLinks('videoLink', url);
        
        // Log changes for debugging
        console.log(`Video URL updated for state ${uid}, waiting for Save Changes button to save to database`);
        
        // Log current state to help with debugging
        setTimeout(() => {
          console.log(`Video link set for state ${uid}:`, url);
        }, 500);
      }
    },
    type: type || "",
    discussionButtons: discussionButtons || true,
    editing: editing || false,
    setCursorAuto: setCursorAuto || emptyOnClick,
    setCursorPointer: setCursorPointer || emptyOnClick,
    isHumanMode: humanMode || false,
    invis: hideTools,
  };
  
  console.log(`HSMStateBox for ${type}: showComments=${!!showComments}, showPapers=${!!showPapers}`);

  useEffect(() => {
    if (editing && textareaRef.current) {
      textareaRef.current.focus();
      
      // Use saved cursor position if available, otherwise default to end
      const cursorPosition = cursorPositionRef.current !== null 
        ? cursorPositionRef.current 
        : (localText?.length || 0);
        
      textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [editing, text]);

  let content;

  if (editing) {
    let textAreaProps = {
      value: localText,
      onChange: (e) => {
        const newText = e.target.value;
        
        // Save current cursor position before state update
        cursorPositionRef.current = e.target.selectionStart;
        
        // Update local text immediately to make the UI responsive
        setLocalText(newText);
        
        // Then update the component state and parent components
        if (setText) {
          setText(newText);
        }
        
        // Always update the activity props to ensure the parent components have the latest text
        if (setActivityProps && uid) {
          // Update the activity state for saving - but don't send to backend yet
          updateActivityWithLinks('text', newText);
          
          // Mark as unsaved changes
          if (setIsUnsavedChanges) {
            setIsUnsavedChanges(true);
          }
          
          // Log changes for debugging
          console.log(`Text updated for state ${uid}, waiting for Save Changes button to save to database`);
        }
        
        // Set cursor position in next render cycle
        setTimeout(() => {
          if (textareaRef.current && cursorPositionRef.current !== null) {
            textareaRef.current.setSelectionRange(
              cursorPositionRef.current, 
              cursorPositionRef.current
            );
          }
        }, 0);
      },
      ref: textareaRef,
      style: {
        background: "lightgreen",
      },
      className: "HSM-state-text ",
      placeholder: type,
    };
    content = <textarea {...textAreaProps} />;
  } else {
    let spanProps = {
      className: "HSM-state-text",
      onMouseEnter: setCursorPointer || emptyOnClick,
      onMouseLeave: setCursorAuto || emptyOnClick,
      style: {
        overflowY: "auto" as "auto",
        cursor: "default"
      },
    };
    content = (
      <span {...spanProps}>
        <p>{localText}</p>
      </span>
    );
  }

  let stateClass;
  let levelClass;

  switch (type) {
    case "activity":
      stateClass = "HSM-state cactivity";
      levelClass = "level 0";
      break;
    case "composite task":
      stateClass = "HSM-state comptask";
      levelClass = "level 1";
      break;
    case "task":
      stateClass = "HSM-state task";
      levelClass = "level 2";
      break;
    case "composite skill":
      stateClass = "HSM-state compskill";
      levelClass = "level 3";
      break;
    case "skill":
      stateClass = "HSM-state skill";
      levelClass = "level 4";
      break;
    default:
      stateClass = "HSM-state";
      levelClass = "";
      break;
  }

  // Get parent element classname to adjust style if in tree view
  const isInTreeView = document.querySelector('.hsm-tree-view') !== null;

  // Add effect to handle body class when image modal is shown
  useEffect(() => {
    if (showImageModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    
    // Cleanup on unmount
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [showImageModal]);

  // Add effect to handle body class when video modal is shown
  useEffect(() => {
    if (showVideoModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    
    // Cleanup on unmount
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [showVideoModal]);

  return (
    <div className={"HSM-Wrapper " + levelClass}>
      <div className={stateClass}>
        <HSMStateOwnership
          editing={editing || false}
          ownership={ownership || ""}
          humanMode={humanMode || false}
          setOwnership={setOwnership || (() => {})}
        />
        
        <div className="state-content-wrapper">
          <div className="HSM-state-text">{content}</div>
          
          <div className="media-sidebar">
            {(localImageLink || imageLink) && (
              <div 
                className="media-square image-box relative clickable-media"
                onClick={() => setShowImageModal(true)}
              >
                <img
                  src={localImageLink || imageLink}
                  alt="Uploaded image"
                  className="state-image"
                  onError={(e) => {
                    console.error('Image failed to load:', localImageLink || imageLink);
                    if (uid) {
                      console.error(`Image error for state ${uid}`);
                    }
                    e.currentTarget.src = 'https://via.placeholder.com/150?text=Image+Not+Found';
                  }}
                  onLoad={() => console.log("Image loaded successfully:", localImageLink || imageLink)}
                />
                {editing && (
                  <button 
                    className="media-remove-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      // Clear both local state and activity state
                      if (setImageLink) setImageLink('');
                      updateActivityWithLinks('imageLink', '');
                    }}
                  >
                    ✕
                  </button>
                )}
                <div className="media-expand-icon">
                  <FaExpand />
                </div>
              </div>
            )}
            
            {localVideoLink && (
              <div 
                className="media-square video-box relative clickable-media"
                onClick={() => setShowVideoModal(true)}
              >
                <ReactPlayer
                  url={localVideoLink}
                  controls={false}
                  width="100%"
                  height="100%"
                  className="state-video"
                  light={true}
                  config={{
                    youtube: {
                      playerVars: { modestbranding: 1 }
                    },
                    vimeo: {
                      playerOptions: { byline: false, portrait: false, title: false }
                    }
                  }}
                  onError={(e) => {
                    console.error('Video failed to load:', localVideoLink, e);
                  }}
                />
                <div className="media-controls">
                  <button
                    className="video-external-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(localVideoLink, "_blank");
                    }}
                    title="Open in new window"
                  >
                    <FaExternalLinkAlt />
                  </button>
                  {editing && (
                    <button 
                      className="video-remove-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        safeSetVideoLink('');
                      }}
                      title="Remove video"
                    >
                      ✕
                    </button>
                  )}
                </div>
                <div className="media-expand-icon">
                  <FaExpand />
                </div>
              </div>
            )}
          </div>
        </div>
        
        {!viewOnlyMode && (
          <HSMStateTools {...toolsProps} />
        )}
      </div>
      
      {type === "task" && showingConditions && (
        <HSMConditionsBox
          preconditions={preconditions || ""}
          setPreconditions={setPreconditions || (() => {})}
          postconditions={postconditions || ""}
          setPostconditions={setPostconditions || (() => {})}
          editing={editing || false}
        />
      )}

      {/* Image Modal */}
      {showImageModal && ReactDOM.createPortal(
        <div className="media-modal-overlay" onClick={() => setShowImageModal(false)}>
          <div className="media-modal-content image-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="media-modal-close" onClick={() => setShowImageModal(false)}>
              <FaTimes />
            </button>
            <img 
              src={localImageLink || imageLink} 
              alt="Full size" 
              className="media-modal-image"
              onError={(e) => {
                e.currentTarget.src = 'https://via.placeholder.com/400?text=Image+Not+Found';
              }}
            />
          </div>
        </div>,
        document.body
      )}

      {/* Video Modal */}
      {showVideoModal && ReactDOM.createPortal(
        <div className="media-modal-overlay" onClick={() => setShowVideoModal(false)}>
          <div className="media-modal-content video-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="media-modal-close" onClick={() => setShowVideoModal(false)}>
              <FaTimes />
            </button>
            <ReactPlayer
              url={localVideoLink}
              controls
              width="100%"
              height="100%"
              playing
              className="media-modal-video"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

export default HSMStateBox;
