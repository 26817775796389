import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CenteredSpinner } from "./Spinner";
import {
  Order,
  WorkflowProps,
  WorkflowTableProps,
} from "../utils/interface/ADLSPage";
import { getComparator, headCells, stableSort } from "../utils/ADLSPageUtils";
import ADLSPageTableHead from "./ADLSPageTableHead";
import ADLSPAGETableBody from "./ADLSPAGETableBody";
import ADLSPageSearch from "./ADLSPageSearch";
import ADLSRowcount from "./ADLSRowcount";
import ADLSPagination from "./ADLSPagination";

export function ADLSPageTable(props: WorkflowTableProps) {
  const { avatar } = useParams();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof WorkflowProps>("category");
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const [adlsLoaded, setAdlsLoaded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(1);
  const [refresh, setrefresh] = useState(false);
  const [userContext, setUserContext] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
  };

  const handleSearch = (text) => {
    setSearchText(text);
    filterRows(text);
  };

  const filterRows = (text) => {
    if (!text.trim()) {
      setFilteredRows(rows);
      return;
    }
    
    const lowercasedFilter = text.toLowerCase();
    const filteredData = rows.filter((row) => {
      // Search across all searchable fields
      return (
        row.name.toLowerCase().includes(lowercasedFilter) ||
        row.category.toLowerCase().includes(lowercasedFilter) ||
        row.status.toLowerCase().includes(lowercasedFilter) ||
        (row.reviewStatus && row.reviewStatus.toLowerCase().includes(lowercasedFilter)) ||
        (row.viewMode && row.viewMode.toLowerCase().includes(lowercasedFilter))
      );
    });
    
    setFilteredRows(filteredData);
    
    // Set current page back to 1 when searching
    setCurrentPage(1);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof WorkflowProps
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (url) => {
    window.open(url, "_self");
  };

  const calculatePageCount = (totalData, rowsPerPage) => {
    return Math.ceil(totalData / rowsPerPage) === 0
      ? 1
      : Math.ceil(totalData / rowsPerPage);
  };

  useEffect(() => {
    setpageCount(calculatePageCount(filteredRows.length, rowsPerPage));
  }, [rowsPerPage]);

  useEffect(() => {
    // Get user context data
    const userContextData = sessionStorage.getItem("user");
    if (userContextData) {
      setUserContext(JSON.parse(userContextData));
    }
  }, []);

  useEffect(() => {
    var url: string;
    
    // Check if the user is an admin or superadmin
    const isAdminOrSuperAdmin = props.userContext && 
      (props.userContext.name === "admin" || 
       props.userContext.role === "admin" || 
       props.userContext.role === "superadmin");
    
    // Determine the appropriate API endpoint based on user role
    let path;
    if (isAdminOrSuperAdmin) {
      // Admin/superadmin can see all workflows (public and private from all users)
      path = `/adls/${avatar}/admin`;
    } else if (props.userContext) {
      // Regular users can see public workflows and their own private workflows
      path = `/adls/${avatar}/author/${props.userContext.id}`;
    } else {
      // Non-logged in users can only see public workflows
      path = `/adls/${avatar}`;
    }

    url = `${process.env.REACT_APP_BACKEND_BASE_URI}${path}`;
    console.log(`Fetching workflows using: ${path}`);

    fetch(url)
      .then((res) => res.json())
      .then((categories) => {
        const pendingWorkflows = [];
        const approvedWorkflows = [];

        categories.forEach((category) => {
          category.children.forEach((hsmObj) => {
            // For non-logged in users:
            // - Only show public workflows that are approved
            if (!props.userContext) {
              // Skip if not a public workflow
              if (!hsmObj.visibility) {
                return;
              }
              
              // Skip if not approved
              if (hsmObj.reviewStatus !== "Approved" && hsmObj.reviewStatus !== "approved" && 
                  !(hsmObj.approved === true)) {
                return;
              }
            } else {
              // For logged-in users:
              // - Include all public workflows
              // - Include user's own private workflows
              // - Skip other users' private workflows unless admin
              
              // Skip private workflows that don't belong to the current user (unless admin)
              if (!isAdminOrSuperAdmin && !hsmObj.visibility && props.userContext.id !== hsmObj.author) {
                return;
              }
            }

            // Map old approved field to reviewStatus if needed
            const reviewStatus = hsmObj.reviewStatus || 
              (hsmObj.approved === true ? "Approved" : 
              (hsmObj.approved === false ? "Pending" : "Pending"));

            // Set URL based on user login state and workflow properties
            let workflowUrl;
            if (!props.userContext) {
              // For non-logged in users, only view mode
              workflowUrl = "/view/" + hsmObj._id;
            } else if (props.userContext.id === hsmObj.author && reviewStatus !== "Approved") {
              // For authors of non-approved workflows, edit mode
              workflowUrl = "/edit/" + hsmObj._id;
            } else {
              // For everyone else, view mode
              workflowUrl = "/view/" + hsmObj._id;
            }

            const workflow = {
              id: hsmObj._id,
              name: hsmObj.text,
              category: hsmObj.adl,
              status: !hsmObj.visibility ? "Private" : "Public",
              url: workflowUrl,
              viewMode: hsmObj.viewMode,
              author: hsmObj.author,
              avatar: hsmObj.avatar,
              visibility: hsmObj.visibility,
              reviewStatus: reviewStatus
            };

            if (reviewStatus !== "Approved") {
              pendingWorkflows.push(workflow);
            } else {
              approvedWorkflows.push(workflow);
            }
          });
        });

        // Sort workflows by review status: empty/Approved first, then Reviewed, then Pending
        const sortByReviewStatus = (a, b) => {
          // Helper function to get sort priority (lower number = higher priority)
          const getPriority = (status) => {
            if (!status || status === "Approved") return 0;
            if (status === "Reviewed") return 1;
            if (status === "Pending") return 2;
            return 3; // any other status
          };
          
          const priorityA = getPriority(a.reviewStatus);
          const priorityB = getPriority(b.reviewStatus);
          
          return priorityA - priorityB;
        };

        // Sort both arrays
        pendingWorkflows.sort(sortByReviewStatus);
        approvedWorkflows.sort(sortByReviewStatus);

        const sortedRows = [...approvedWorkflows, ...pendingWorkflows];

        setRows(sortedRows);
        setFilteredRows(sortedRows);
        console.log("Loaded workflows:", sortedRows.length);
      })
      .then(() => {
        setrefresh(true);
        setAdlsLoaded(true);
      })
      .catch(err => {
        console.error("Error fetching workflows:", err);
        setAdlsLoaded(true); // Set to true to remove spinner even if error occurs
      });
  }, [refresh, props.userContext, avatar]);

  return !adlsLoaded ? (
    <div className="row col-6  ">
      <CenteredSpinner />
    </div>
  ) : (
    <Box sx={{ width: "100%" }}>
      <div>
        <div className="flex gap-4 align-items-center mb-4">
          <ADLSRowcount
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <ADLSPageSearch onSearch={handleSearch} />
          {searchText && (
            <div className="text-sm text-gray-600">
              {filteredRows.length} results found for "{searchText}"
            </div>
          )}
        </div>

        <div className="mb-2">
          <div className="w-full overflow-x-auto bg-white rounded-lg shadow-md">
            <table className="min-w-full divide-y divide-gray-200">
              <ADLSPageTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              {!refresh ? (
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td 
                      colSpan={
                        (userContext 
                          ? headCells.length + 1 // All columns + actions
                          : headCells.filter(cell => cell.alwaysVisible !== false).length + 1) // Only always visible columns + actions
                      } 
                      className="text-center py-8"
                    >
                      <CenteredSpinner />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <ADLSPAGETableBody
                  rows={filteredRows}
                  order={order}
                  orderBy={orderBy}
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  handleClick={handleClick}
                  props={props}
                  setrefresh={setrefresh}
                />
              )}
            </table>
          </div>
        </div>
        <div className="flex align-items-center justify-center mt-5 ">
          <ADLSPagination
            count={pageCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </Box>
  );
}

export default ADLSPageTable;
