import React, { useState, useEffect } from "react";
import { FaRuler, FaVideo, FaTrash, FaComment, FaGlobe, FaEdit } from "react-icons/fa";
import { PiNetworkFill } from "react-icons/pi";
import { MdApproval } from "react-icons/md";
import ModalVideo from "./modalvideo";
import { CenteredSpinner } from "./Spinner";
import NewWorkflowButton from "./NewWorkFlowButton";
import { WorkflowCaregiver } from "../utils/HSMUtils";
import axios from "axios";
import { UserOccupation } from "../utils/AppUtlis";
import { toast } from "react-toastify";
import { db, collection, addDoc } from "../firebase";
import EditAvatar from "./EditAvatar";

const Card = ({
  imageUrl,
  title,
  paragraph,
  userContext,
  _id,
  videos,
  setPlaylistOpen,
  setVideoUrl,
  videoUrl,
  status,
  measurements,
  setAvatarsLoaded,
  visibility,
  created_by,
  admin,
  user,
  comment,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hoveredIcons, setHoveredIcons] = useState(Array(7).fill(false));
  const [helpBox, setHelpBox] = useState({
    text: "",
    visible: false,
    x: 0,
    y: 0,
  });
  const [showEditAvatarModal, setShowEditAvatarModal] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [youtubeOptions, setYoutubeOptions] = useState({});
  // Reference to track if component is mounted
  const isMounted = React.useRef(true);

  // Set isMounted to false when component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Reset image loading state when component mounts or _id changes
  useEffect(() => {
    setImgLoaded(false);
    setImgLoadError(false);
  }, [_id]);

  const publicWorkflowsUrl = "/adls/" + _id;
  const CommentPagesUrl = "/CommentsPage/" + _id;
  
  // First use imageUrl if provided, otherwise construct an absolute URL from the root
  // Convert any relative URLs to absolute URLs
  const getAbsoluteUrl = (url) => {
    if (!url) return `${window.location.origin}/${_id}.gif`;
    
    // Check if the URL is already absolute
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    
    // Remove any leading ./ or ../
    const cleanPath = url.replace(/^\.\/|^\.\.\//, '');
    
    // Create absolute URL
    return `${window.location.origin}/${cleanPath}`;
  };
  
  const imgSrc = getAbsoluteUrl(imageUrl);
  
  // We don't need to track tried paths anymore since we're using absolute URLs
  const [imgLoadError, setImgLoadError] = useState(false);
  
  // Function to handle image loading errors
  const handleImageError = (e) => {
    console.error("Failed to load avatar image:", {
      originalSrc: e.target.src,
      avatarId: _id,
      providedImageUrl: imageUrl
    });
    setImgLoadError(true);
    setImgLoaded(true); // Still mark as loaded to remove spinner
  };

  // Log image details when component loads
  useEffect(() => {
    console.log("Avatar image details:", {
      id: _id,
      originalImageUrl: imageUrl,
      calculatedSrc: imgSrc
    });
  }, [_id, imageUrl, imgSrc]);

  const defaultCaregiver =
    userContext &&
    userContext.occupation &&
    userContext.occupation === UserOccupation.ROBOTICIST
      ? WorkflowCaregiver.ROBOT
      : WorkflowCaregiver.HUMAN;

  const isOwner = userContext && created_by === userContext.id;

  const handleMouseEnter = (index, text, event) => {
    const newHoveredIcons = [...hoveredIcons];
    newHoveredIcons[index] = true;
    setHoveredIcons(newHoveredIcons);

    const { clientX: x, clientY: y } = event;
    setHelpBox({ text, visible: true, x, y });
  };

  const handleMouseLeave = (index) => {
    const newHoveredIcons = [...hoveredIcons];
    newHoveredIcons[index] = false;
    setHoveredIcons(newHoveredIcons);

    setHelpBox({ ...helpBox, visible: false });
  };

  const avatarApproval = async () => {
    try {
      const userData = JSON.parse(sessionStorage.getItem("user"));
      
      if (!userData) {
        toast.error("Authentication required. Please log in.");
        return;
      }
      
      // Set up headers with content type
      const headers = {
        "Content-Type": "application/json"
      };
      
      console.log("Avatar approval attempt:", {
        role: userData.role,
        hasToken: !!userData.access_token,
        avatarId: _id
      });
      
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${_id}/status`,
          {
            status: "ACCEPTED",
            userRole: userData.role // Send user role directly instead of isSuperAdmin flag
          },
          { headers }
        );
        
        if (isMounted.current) {
          handleAddCredits(created_by, 10, userContext);
          if (setAvatarsLoaded) setAvatarsLoaded(false);
          toast.success("Avatar approved successfully!");
        }
      } catch (axiosError) {
        console.log("Avatar approval error:", axiosError.response ? {
          status: axiosError.response.status,
          data: axiosError.response.data
        } : "No response data");
        
        if (axiosError.response && axiosError.response.status === 401) {
          toast.error("Your session has expired. Please log in again.");
        } else if (axiosError.response && axiosError.response.status === 403) {
          toast.error("You don't have permission to approve avatars.");
        } else if (isMounted.current) {
          toast.error(axiosError.response?.data?.error || "Failed to approve avatar");
        }
      }
    } catch (error) {
      console.log("Error parsing user data:", error);
      if (isMounted.current) {
        toast.error("Authentication error. Please log in again.");
      }
    }
  };

  const avatarVisibility = async (value) => {
    try {
      const userData = JSON.parse(sessionStorage.getItem("user"));
      
      // Debug auth data
      console.log("Auth data for visibility change:", {
        hasUserData: !!userData,
        hasToken: userData?.access_token ? "Yes" : "No",
        tokenFirstChars: userData?.access_token ? userData.access_token.substring(0, 10) + "..." : "N/A",
        userRole: userData?.role || "None"
      });
      
      // Check if user is admin or superadmin
      if (!userData || (userData.role !== 'admin' && userData.role !== 'superadmin')) {
        toast.error("Only administrators or superadmin can change avatar visibility");
        return;
      }

      // For superadmin, don't require token
      const headers = {
        "Content-Type": "application/json"
      };

      // Only add Authorization header if user is admin (not superadmin)
      if (userData.role === 'admin' && userData.access_token) {
        headers["Authorization"] = `Bearer ${userData.access_token}`;
      }
      
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${_id}/visibility`,
          {
            visibility: value,
          },
          { headers }
        );
        
        // Only update state if component is still mounted
        if (isMounted.current) {
          if (setAvatarsLoaded) {
            setAvatarsLoaded(false);
          }
          toast.success(`Avatar visibility set to ${value ? "public" : "private"}`);
        }
      } catch (axiosError) {
        console.log("Visibility update error:", axiosError.response ? {
          status: axiosError.response.status,
          data: axiosError.response.data
        } : "No response data");
        
        if (axiosError.response && axiosError.response.status === 401) {
          toast.error("Your session has expired. Please log in again.");
        } else if (isMounted.current) {
          const errorMessage = axiosError.response?.data?.error || "Failed to update avatar visibility";
          toast.error(errorMessage);
        }
      }
    } catch (error) {
      console.log("Error parsing user data:", error);
      if (isMounted.current) {
        toast.error("Authentication error. Please log in again.");
      }
    }
  };

  const confirmAndToggleVisibility = () => {
    const newVisibility = !visibility;
    const message = `Are you sure you want to make this avatar ${
      newVisibility ? "public" : "private"
    }?`;

    if (window.confirm(message)) {
      avatarVisibility(newVisibility);
    }
  };

  const deleteAvatar = async () => {
    try {
      const userData = JSON.parse(sessionStorage.getItem("user"));
      
      if (!userData || !userData.access_token) {
        toast.error("Authentication required. Please log in.");
        return;
      }
      
      const authHeader = `Bearer ${userData.access_token}`;
      
      try {
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": authHeader,
            },
          }
        );
        
        if (isMounted.current) {
          handleAddCredits(created_by, -10, userContext);
          if (setAvatarsLoaded) setAvatarsLoaded(false);
        }
      } catch (axiosError) {
        console.log("Avatar deletion error:", axiosError.response ? {
          status: axiosError.response.status,
          data: axiosError.response.data
        } : "No response data");
        
        if (axiosError.response && axiosError.response.status === 401) {
          toast.error("Your session has expired. Please log in again.");
        } else if (isMounted.current) {
          toast.error(axiosError.response?.data?.error || "Failed to delete avatar");
        }
      }
    } catch (error) {
      console.log("Error parsing user data:", error);
      if (isMounted.current) {
        toast.error("Authentication error. Please log in again.");
      }
    }
  };

  const saveCommentToDB = async (comment) => {
    try {
      await addDoc(collection(db, "notifications"), {
        text: comment.text,
        category: "comment",
        targeted_user: comment.target,
        createdby: comment.user.role,
      });
    } catch (err) {
      console.error("Error saving comment", err);
    }
  };

  const handleAddCredits = async (userId, amount, user) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm/credits`,
        {
          userId,
          amount,
        }
      );

      if (response.status === 201 && isMounted.current) {
        toast.success(` ${response.data.message}`);
        const newComment = {
          text: response.data.message,
          target: userId,
          user: user,
          timestamp: new Date(),
        };
        await saveCommentToDB(newComment);
      }
    } catch (error) {
      console.error("Error adding credits:", error);
      if (isMounted.current) {
        toast.error("Failed to update credits");
      }
    }
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const viewPublicWorkflows = () => {
    window.open(publicWorkflowsUrl, "_self");
    sessionStorage.setItem("activeButton", "3");
  };

  const viewcommentpage = () => {
    window.open(CommentPagesUrl, "_self");
  };

  const viewMeasurements = () => {
    window.open(`${measurements}`, "_blank");
  };

  const viewVideos = () => {
    // Check if videos URL contains a playlist
    let playlistId = null;
    let videoId = videos;
    
    try {
      // If it's a full URL, extract the playlist ID if present
      if (videos.includes('youtube.com') || videos.includes('youtu.be')) {
        const url = new URL(videos);
        // Extract playlist ID if it exists
        playlistId = url.searchParams.get('list');
        
        // Extract video ID
        if (videos.includes('youtube.com/watch')) {
          videoId = url.searchParams.get('v') || videos;
        } else if (videos.includes('youtu.be/')) {
          videoId = videos.split('youtu.be/')[1]?.split('?')[0] || videos;
        }
      }
    } catch (e) {
      console.error("Error parsing video URL:", e);
    }
    
    setVideoUrl(videoId);
    setPlaylistOpen(true);
    setModalIsOpen(true);
    
    // Store playlist ID to be used by the ModalVideo component
    if (playlistId) {
      // Update youtube player props to include playlist
      setYoutubeOptions({
        list: playlistId,
        listType: 'playlist'
      });
    }
  };

  const handleEditAvatar = () => {
    setShowEditAvatarModal(true);
  };

  // Add effect to auto-hide success dialog
  useEffect(() => {
    if (showSuccessDialog) {
      const timer = setTimeout(() => setShowSuccessDialog(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessDialog]);

  return (
    <div className="card w-80 flex justify-center items-center relative">
      <div className="card bg-light relative">
        {!imgLoaded && <CenteredSpinner />}
        {
          <img
            className="mx-5"
            src={imgSrc}
            style={{ display: imgLoaded ? "block" : "none" }}
            onLoad={() => setImgLoaded(true)}
            onError={handleImageError}
            alt={title || "Avatar"}
          />
        }
      </div>
      <div className="card-body">
        <div className="flex justify-between items-center mb-2">
          <h5 className="card-title relative">{title}</h5>
          {status === "CREATED" && (
            <span className="px-3 py-1 text-sm font-medium text-yellow-800 bg-yellow-100 rounded-full">
              Not Approved
            </span>
          )}
        </div>
        <div className="relative">
          <p
            className={`card-text ${expanded ? "expanded" : "collapsed"} mb-2`}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: expanded ? "none" : "3",
              lineHeight: "1.5",
              maxHeight: expanded ? "none" : "4.5em",
            }}
          >
            {paragraph}
          </p>
          {paragraph.length > 90 && (
            <button
              onClick={toggleExpanded}
              className="text-blue-600 hover:text-blue-800 text-sm font-medium transition-colors duration-200"
              style={{
                display: "block",
                width: "100%",
                textAlign: "left",
                padding: "4px 0",
              }}
            >
              {expanded ? "Show Less" : "Read More"}
            </button>
          )}
        </div>

        <div className="flex flex-col space-y-4 mr-2 ml-2">
          <button
            className="rounded-lg w-40 h-10 border flex align-items-center hover:text-white border-black hover:bg-black"
            onMouseEnter={(e) =>
              handleMouseEnter(0, "View detailed measurements", e)
            }
            onMouseLeave={() => handleMouseLeave(0)}
            onClick={viewMeasurements}
          >
            <FaRuler
              className="text-xl mr-2 ml-2"
              style={{ color: hoveredIcons[0] ? "white" : "black" }}
            />
            <div>Measurements</div>
          </button>
          <button
            className="rounded-lg w-40 h-10 border flex align-items-center hover:text-white border-black hover:bg-black"
            onMouseEnter={(e) =>
              handleMouseEnter(1, "Watch mobility videos", e)
            }
            onMouseLeave={() => handleMouseLeave(1)}
            onClick={viewVideos}
          >
            <FaVideo
              className="text-xl mr-2 ml-2"
              style={{ color: hoveredIcons[1] ? "white" : "black" }}
            />
            <span>Mobility Videos</span>
          </button>
          {status !== "CREATED" && (
            <button
              className="rounded-lg w-40 h-10 border border-black flex align-items-center hover:bg-black hover:text-white"
              onMouseEnter={(e) =>
                handleMouseEnter(2, "View public workflows", e)
              }
              onMouseLeave={() => handleMouseLeave(2)}
              onClick={viewPublicWorkflows}
            >
              <PiNetworkFill
                className="text-xl mr-2 ml-2"
                style={{ color: hoveredIcons[2] ? "white" : "black" }}
              />
              <div>View Workflows</div>
            </button>
          )}

          {admin && (
            <>
              <button
                className="rounded-lg w-40 h-10 border border-black flex align-items-center hover:text-white border-black hover:bg-black"
                onMouseEnter={(e) =>
                  handleMouseEnter(3, "Comment this avatar", e)
                }
                onMouseLeave={() => handleMouseLeave(3)}
                onClick={viewcommentpage}
              >
                <FaComment
                  className="text-xl mr-2 ml-2"
                  style={{ color: hoveredIcons[3] ? "white" : "black" }}
                />
                Comment
              </button>
            </>
          )}

          {admin && status === "CREATED" && (
            <>
              <button
                className="rounded-lg w-40 h-10 border border-black flex align-items-center bg-blue-500 hover:bg-blue-400 hover:text-white"
                onMouseEnter={(e) =>
                  handleMouseEnter(5, "Approve this avatar", e)
                }
                onMouseLeave={() => handleMouseLeave(5)}
                onClick={avatarApproval}
              >
                <MdApproval
                  className="text-xl mr-2 ml-2"
                  style={{ color: hoveredIcons[5] ? "white" : "black" }}
                />
                Approve
              </button>
            </>
          )}

          {admin && status !== "CREATED" ? (
            <>
              <button
                className={`rounded-lg w-40 h-10 border border-black flex items-center 
                ${
                  !visibility ? "bg-green-600 text-white" : "hover:bg-black hover:text-white"
                }`}
                onMouseEnter={(e) =>
                  handleMouseEnter(
                    4,
                    visibility ? "Make it private" : "Make it public",
                    e
                  )
                }
                onMouseLeave={() => handleMouseLeave(4)}
                onClick={confirmAndToggleVisibility}
              >
                <FaGlobe
                  className="text-xl mr-2 ml-2"
                  style={{
                    color: hoveredIcons[4]
                      ? "white"
                      : !visibility
                      ? "white"
                      : "black",
                  }}
                />
                {visibility ? "Make it private" : "Make it public"}
              </button>
            </>
          ) : null}

          {admin ? (
            <>
              <button
                className="rounded-lg w-40 h-10 border border-black flex align-items-center bg-red-600 hover:bg-red-400 hover:text-white"
                onMouseEnter={(e) =>
                  handleMouseEnter(6, "Delete this avatar", e)
                }
                onMouseLeave={() => handleMouseLeave(6)}
                onClick={deleteAvatar}
              >
                <FaTrash
                  className="text-xl mr-2 ml-2"
                  style={{ color: hoveredIcons[6] ? "white" : "black" }}
                />
                Delete
              </button>
            </>
          ) : null}

          {isOwner && (
            <>
              <button
                className="rounded-lg w-40 h-10 border border-black flex align-items-center hover:bg-black hover:text-white"
                onMouseEnter={(e) =>
                  handleMouseEnter(7, "Edit this avatar", e)
                }
                onMouseLeave={() => handleMouseLeave(7)}
                onClick={handleEditAvatar}
              >
                <FaEdit
                  className="text-xl mr-2 ml-2"
                  style={{ color: hoveredIcons[7] ? "white" : "black" }}
                />
                Edit Avatar
              </button>
            </>
          )}
        </div>
      </div>
      {/* <HelpBox
        text={helpBox.text}
        visible={helpBox.visible}
        x={helpBox.x}
        y={helpBox.y}
      /> */}
      <ModalVideo
        channel="youtube"
        isOpen={modalIsOpen}
        videoId={videoUrl}
        onClose={() => {
          setModalIsOpen(false);
          setYoutubeOptions({});
        }}
        youtube={youtubeOptions}
      />
      
      <EditAvatar
        show={showEditAvatarModal}
        userContext={userContext}
        avatarData={{
          _id,
          title,
          paragraph,
          imageUrl,
          videos,
          measurements,
          videoUrl,
        }}
        onHide={() => setShowEditAvatarModal(false)}
        oncomplete={() => {
          setShowEditAvatarModal(false);
          setAvatarsLoaded(false);
          setShowSuccessDialog(true);
        }}
      />
      
      {showSuccessDialog && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl mb-4">Success</h2>
            <p className="mb-4">Avatar updated successfully!</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
