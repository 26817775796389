import React, { useState } from "react";
import { LegendProps } from "../utils/interface/HSM";

interface LegendItemProps {
  label: string;
  color: string;
  icon: string;
  definition: string;
}

const LegendItem: React.FC<LegendItemProps> = ({ label, color, icon, definition }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <div 
      className="legend-item rounded-md cursor-pointer transition-all duration-200"
      style={{ backgroundColor: "white", marginBottom: "6px" }}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="flex items-center gap-3 py-2 px-4">
        <span className="legend-icon flex items-center justify-center w-8 h-8 rounded-md" style={{ backgroundColor: color, color: color === "#203864" ? "white" : "black" }}>
          {icon}
        </span>
        <span className="text-sm font-medium text-gray-800">{label}</span>
      </div>
      
      <div 
        className={`legend-definition text-xs text-gray-600 px-4 pb-2 overflow-hidden transition-all duration-200 ${isExpanded ? 'max-h-24' : 'max-h-0'}`}
        style={{ marginLeft: '40px' }}
      >
        {definition}
      </div>
    </div>
  );
};

const Legend: React.FC<LegendProps> = ({ active, setActive }) => {
  const legendItems = [
    { 
      label: "Activity", 
      color: "#203864", 
      icon: "A",
      definition: "A high-level goal or objective that typically involves multiple tasks. Activities represent the broadest level of organization in the hierarchy."
    },
    { 
      label: "Composite task", 
      color: "#BDD7EE", 
      icon: "CT",
      definition: "A collection of related tasks that work together to achieve a specific goal. Composite tasks help organize and group related tasks."
    },
    { 
      label: "Task", 
      color: "#FFF2CC", 
      icon: "T",
      definition: "A specific action or work item that needs to be completed. Tasks are concrete and actionable items."
    },
    { 
      label: "Composite skill", 
      color: "#C5C4C4", 
      icon: "CS",
      definition: "A group of related skills that together enable the completion of tasks. Composite skills represent broader capability areas."
    },
    { 
      label: "Skill", 
      color: "#CFCECE", 
      icon: "S",
      definition: "A specific ability or capability needed to perform tasks. Skills are the most fundamental elements in the hierarchy."
    },
  ];

  return (
    <div className="legend-container space-y-4">
      {active ? (
        <div className="legend bg-white p-3 rounded-lg shadow-lg space-y-1">
          <div className="legend-header flex justify-between items-center px-3 py-2 border-b border-gray-200 mb-2">
            <h3 className="text-gray-800 text-base font-semibold mb-0">Legend</h3>
            <button
              className="flex items-center justify-center p-2 bg-red-500 text-white rounded-full shadow hover:bg-red-600 transition-all"
              onClick={() => setActive(!active)}
              aria-label="Close legend"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-2 -2 24 24"
                width="16"
                fill="currentColor"
              >
                <path d="M11.414 10l2.829 2.828a1 1 0 0 1-1.415 1.415L10 11.414l-2.828 2.829a1 1 0 1 1-1.415-1.415L8.586 10 5.757 7.172a1 1 0 0 1 1.415-1.415L10 8.586l2.828-2.829a1 1 0 0 1 1.415 1.415L11.414 10z"></path>
              </svg>
            </button>
          </div>
          <div className="legend-content px-1">
            {legendItems.map((item) => (
              <LegendItem key={item.label} {...item} />
            ))}
          </div>
        </div>
      ) : (
        <button
          className="flex items-center justify-center w-12 h-12 bg-white text-gray-800 rounded-full shadow-lg hover:bg-gray-100 transition-all duration-200 border-2 border-gray-300"
          onClick={() => setActive(!active)}
          aria-label="Show legend"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20"
            height="20"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
          </svg>
        </button>
      )}
    </div>
  );
};

export default Legend;
