import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

const ADLSPageSearch = ({ onSearch }) => {
  const [searchText, setSearchText] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  
  // Apply search after user stops typing for a short period
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
    
    // Clear any previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    
    // Set a new timeout to delay the search until user stops typing
    const timeoutId = setTimeout(() => {
      onSearch(value);
    }, 300);
    
    setTypingTimeout(timeoutId);
  };
  
  // Clear the search text and reset filters
  const handleClearSearch = () => {
    setSearchText("");
    onSearch("");
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <div className="relative">
      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
        </svg>
      </span>
      <input
        type="text"
        placeholder="Search by name, category, status or review status..."
        value={searchText}
        onChange={handleSearch}
        className="py-2 pl-10 pr-10 w-full text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        aria-label="Search workflows"
      />
      {searchText && (
        <button
          className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500 hover:text-gray-700"
          onClick={handleClearSearch}
          aria-label="Clear search"
          title="Clear search"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default ADLSPageSearch;
