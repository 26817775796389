import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TextField,
  Button,
  Tooltip,
  Box,
  LinearProgress,
  Typography,
  IconButton,
  Grid,
  Paper
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoIcon from "@mui/icons-material/Info";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { BuildingBlocks } from "../model/BuildingBlocks";
import { BuildingBlockTableProps, StateBoxProps } from "../utils/interface/HSM";

// Help text for each model type
const MODEL_DESCRIPTIONS = {
  "User Functionality Model": "Describes what the user can do and their capabilities in this activity.",
  "User Behavior Model": "Describes how the user typically behaves during this activity.",
  "Environment Model": "Describes the physical environment and context where this activity takes place.",
  "Caregiver Functionality Model": "Describes what the caregiver can do and their capabilities in this activity.",
  "Caregiver Behavior Model": "Describes how the caregiver typically behaves during this activity.",
  "Robot Model": "Describes the robot's capabilities and behavior for this activity."
};

export function BuildingBlockTable({
  headers,
  isEditable,
  isHumanMode,
  activityProps,
  setActivityProps,
  setIsUnsavedChanges,
}: BuildingBlockTableProps) {
  const [previous, setPrevious] = useState([]);
  const [curr, setCurr] = useState([]);
  const [progress, setProgress] = useState<number[]>([]);

  const transposeData = (headers, data) => {
    return headers.map((header, index) => ({
      header,
      value: data[index],
    }));
  };

  function initBuildingBlocksStateValues(
    activityProps: StateBoxProps,
    isHumanMode: boolean
  ) {
    let newValues = Object.values(activityProps["buildingBlocks"]);

    if (isHumanMode) {
      newValues.pop();
    }

    newValues = newValues.map((value) => {
      if (value == "") {
        return "Undefined";
      } else {
        return value;
      }
    });

    // Calculate progress for each model
    const progressValues = newValues.map(value => {
      if (value === "Undefined") return 0;
      return value.length > 10 ? 100 : (value.length / 10) * 100;
    });
    setProgress(progressValues);

    return newValues;
  }

  function setBuildingBlockProperty(
    activityProps: StateBoxProps,
    setActivityProps: React.Dispatch<React.SetStateAction<StateBoxProps>>,
    idx: number,
    value: string
  ) {
    const prevBuildingBlocks = activityProps.buildingBlocks;
    const newBuildingBlocks: BuildingBlocks = {
      userFunctionality:
        idx == 0 ? value : prevBuildingBlocks.userFunctionality,
      userBehavior: idx == 1 ? value : prevBuildingBlocks.userBehavior,
      environmentModel: idx == 2 ? value : prevBuildingBlocks.environmentModel,
      caregiverFunctionality:
        idx == 3 ? value : prevBuildingBlocks.caregiverFunctionality,
      caregiverBehavior:
        idx == 4 ? value : prevBuildingBlocks.caregiverBehavior,
      robotModel: idx == 5 ? value : prevBuildingBlocks.robotModel,
    };
    setActivityProps({ ...activityProps, buildingBlocks: newBuildingBlocks });

    // Update progress when value changes
    const newProgress = [...progress];
    newProgress[idx] = value.length > 10 ? 100 : (value.length / 10) * 100;
    setProgress(newProgress);

    return [
      newBuildingBlocks.userFunctionality,
      newBuildingBlocks.userBehavior,
      newBuildingBlocks.environmentModel,
      newBuildingBlocks.caregiverFunctionality,
      newBuildingBlocks.caregiverBehavior,
      newBuildingBlocks.robotModel,
    ];
  }

  // Initialize the values of the building blocks
  useEffect(() => {
    const initialValues = initBuildingBlocksStateValues(
      activityProps,
      isHumanMode
    );
    setCurr(initialValues);
  }, [0]); // This guarantees to only run exactly once

  const onChange = (e, idx) => {
    if (previous.length == 0) {
      setPrevious(curr);
    }
    const newVal = e.target.value;

    const newValues = setBuildingBlockProperty(
      activityProps,
      setActivityProps,
      idx,
      newVal
    );
    setIsUnsavedChanges(true);

    setCurr(newValues);
  };

  // Group related models - user, environment, caregiver
  const groupedData = () => {
    const data = transposeData(headers, curr);
    return {
      user: data.filter(item => item.header.includes("User")),
      environment: data.filter(item => item.header.includes("Environment")),
      caregiver: data.filter(item => 
        item.header.includes("Caregiver")),
      robot: data.filter(item => item.header.includes("Robot"))
    };
  };

  const groups = groupedData();

  // Render a single model card
  const renderModelCard = (row, idx, actualIdx) => {
    const isUndefined = row.value === "Undefined";
    const isRobotModel = row.header.includes("Robot");
    
    return (
      <Grid item xs={12} key={idx}>
        <Paper elevation={1} sx={{ 
          p: 2.5, 
          mb: 2, 
          borderRadius: '8px',
          backgroundColor: 'rgba(255, 255, 255, 0.98)',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
          borderLeft: isRobotModel ? '4px solid #673ab7' : 'none'
        }} className={isRobotModel ? 'robot-model' : ''}>
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" sx={{ 
                fontWeight: 600,
                fontSize: '1rem',
                color: isRobotModel ? '#5e35b1' : '#333'
              }}>
                {row.header}
              </Typography>
              <Tooltip title={MODEL_DESCRIPTIONS[row.header] || "No description available"}>
                <IconButton size="small" sx={{ ml: 0.5 }}>
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '140px', justifyContent: 'flex-end' }}>
              <Box sx={{ width: '80px', mr: 1.5 }}>
                <LinearProgress 
                  variant="determinate"
                  value={progress[actualIdx] || 0} 
                  className={isRobotModel ? 'robot-progress' : ''}
                  sx={{ 
                    height: 8, 
                    borderRadius: 4,
                    backgroundColor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: isRobotModel ? '#673ab7' :
                                      progress[actualIdx] === 0 ? '#f44336' : 
                                      progress[actualIdx] < 50 ? '#ff9800' : '#4caf50'
                    }
                  }}
                />
              </Box>
              <Typography variant="caption" sx={{ 
                color: isRobotModel ? '#673ab7' :
                       isUndefined ? '#f44336' : 
                       progress[actualIdx] < 50 ? '#ff9800' : '#4caf50',
                fontWeight: 500,
                minWidth: '60px'
              }}>
                {isUndefined ? 'Not defined' : 
                progress[actualIdx] < 100 ? 'In progress' : 'Complete'}
              </Typography>
            </Box>
          </Box>
          
          {isEditable ? (
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              {isUndefined && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mb: 1.5, 
                  backgroundColor: 'rgba(255, 152, 0, 0.08)', 
                  p: 1, 
                  borderRadius: '4px' 
                }}>
                  <InfoIcon fontSize="small" color="warning" sx={{ mr: 1 }} />
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.85rem' }}>
                    This model needs to be defined.
                  </Typography>
                </Box>
              )}
              <TextField
                value={isUndefined ? "" : row.value}
                onChange={(e) => onChange(e, actualIdx)}
                size="small"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                placeholder={`Define the ${row.header.toLowerCase()}...`}
                sx={{
                  backgroundColor: '#fff',
                  flexGrow: 1,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: isUndefined ? '#ff9800' : '#e0e0e0',
                    },
                    '&:hover fieldset': {
                      borderColor: isUndefined ? '#f57c00' : '#bdbdbd',
                    },
                  }
                }}
              />
              {isUndefined && (
                <Button 
                  startIcon={<AddCircleOutlineIcon />} 
                  sx={{ mt: 1.5, alignSelf: 'flex-start' }}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => onChange({ target: { value: "" } }, actualIdx)}
                >
                  Define Model
                </Button>
              )}
            </Box>
          ) : (
            <Box sx={{ 
              p: 2, 
              backgroundColor: '#f8f8f8', 
              border: '1px solid #eee',
              borderRadius: '4px',
              maxHeight: '120px',
              overflow: 'auto',
              color: isUndefined ? '#9e9e9e' : '#333',
              flexGrow: 1
            }}>
              {!isUndefined ? row.value : (
                <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                  This model has not been defined yet.
                </Typography>
              )}
            </Box>
          )}
        </Paper>
      </Grid>
    );
  };

  // Render two categories of models side by side
  const renderCategoryRow = (category1, models1, startIdx1, category2, models2, startIdx2) => (
    <Box sx={{ mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ 
            fontWeight: 600, 
            fontSize: '1.1rem',
            mb: 2,
            pb: 1,
            borderBottom: '2px solid #f0f0f0',
            color: '#444'
          }}>
            {category1}
          </Typography>
          <Grid container>
            {models1.map((row, idx) => renderModelCard(row, idx, startIdx1 + idx))}
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ 
            fontWeight: 600, 
            fontSize: '1.1rem',
            mb: 2,
            pb: 1,
            borderBottom: '2px solid #f0f0f0',
            color: '#444'
          }}>
            {category2}
          </Typography>
          <Grid container>
            {models2.map((row, idx) => renderModelCard(row, idx, startIdx2 + idx))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  // Special case for environment model, which stands alone
  const renderEnvironmentRow = () => (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6" sx={{ 
        fontWeight: 600, 
        fontSize: '1.1rem',
        mb: 2,
        pb: 1,
        borderBottom: '2px solid #f0f0f0',
        color: '#444',
        textAlign: { xs: 'left', md: 'center' }
      }}>
        Environment
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          {groups.environment.map((row, idx) => 
            renderModelCard(row, idx, groups.user.length + idx)
          )}
        </Grid>
      </Grid>
    </Box>
  );

  // Special case for robot model, which is only shown in robot mode
  const renderRobotRow = () => {
    if (groups.robot.length === 0) return null;
    
    return (
      <Box sx={{ 
        mb: 3,
        backgroundColor: 'rgba(103, 58, 183, 0.03)',
        borderRadius: '8px',
        p: 3,
        mt: 4,
        border: '1px solid rgba(103, 58, 183, 0.1)'
      }}>
        <Typography variant="h6" sx={{ 
          fontWeight: 600, 
          fontSize: '1.1rem',
          mb: 2,
          pb: 1,
          borderBottom: '2px solid rgba(103, 58, 183, 0.2)',
          color: '#5e35b1',
          textAlign: { xs: 'left', md: 'center' }
        }}>
          Robot Model
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            {groups.robot.map((row, idx) => 
              renderModelCard(row, idx, groups.user.length + groups.environment.length + groups.caregiver.length + idx)
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ 
      p: { xs: 2, md: 3 }, 
      backgroundColor: '#fbfbfb',
      pl: { xs: 2, md: 3 },
      pr: { xs: 2, md: 3 }
    }}>
      {renderCategoryRow("User Models", groups.user, 0, "Caregiver Models", groups.caregiver, groups.user.length + groups.environment.length)}
      {renderEnvironmentRow()}
      {renderRobotRow()}
    </Box>
  );
}
