import axios, { AxiosError, AxiosResponse } from 'axios';
import { UserContext } from '../utils/interface/AppInterface';
import { addDoc, collection, db } from '../firebase';
import { ObjectId } from 'bson';

const HTTP_UNAUTHORIZED = 401;
const HTTP_NOT_FOUND = 404;

export const getWorkflowByIdRequest = async (
  id: string,
  handleNotFound?: (err: AxiosError) => void
) => {
  // First try to get from hsm_edit
  const editWorkflowResponse = await getEditWorkflowByIdRequest(id);
  
  if (editWorkflowResponse) {
    return editWorkflowResponse;
  }
  
  // If not found in edit, try human view
  const humanWorkflowRequestResponse = await getHumanWorkflowByIdRequest(id);

  if (humanWorkflowRequestResponse) {
    return humanWorkflowRequestResponse;
  } else {
    // Finally try robot view
    return await getRobotWorkflowByIdRequest(id, handleNotFound);
  }
};

export const getEditWorkflowByIdRequest = async (
  id: string,
  handleNotFound?: (err: AxiosError) => void
) => {
  try {
    // Get user data from session
    const userData = JSON.parse(sessionStorage.user || '{}');
    const accessToken = userData.access_token;
    const headers = accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : undefined;
    
    // Submit get request to hsm_edit endpoint
    const path = `/hsm_edit/${id}`;
    const responseData = (await submitGetRequest(
      path,
      undefined,
      handleNotFound,
      headers
    )) as AxiosResponse<any, any>;
    return responseData ? responseData.data : null;
  } catch (error) {
    console.error("Error in getEditWorkflowByIdRequest:", error);
    return null;
  }
};

export const getHumanWorkflowByIdRequest = async (
  id: string,
  handleNotFound?: (err: AxiosError) => void
) => {
  // submit get request to hsm_view_human endpoint
  const path = `/hsm_view_human/${id}`;
  const responseData = (await submitGetRequest(
    path,
    undefined,
    handleNotFound
  )) as AxiosResponse<any, any>;
  return responseData ? responseData.data : null;
};

export const getRobotWorkflowByIdRequest = async (
  id: string,
  handleNotFound?: (err: AxiosError) => void
) => {
  // submit get request to hsm_view endpoint
  const path = `/hsm_view/${id}`;
  const responseData = (await submitGetRequest(
    path,
    undefined,
    handleNotFound
  )) as AxiosResponse<any, any>;
  return responseData ? responseData.data : null;
};

export const createWorkflowRequest = async (
  user: UserContext,
  workflow: object,
  handleAuthError?: (err: AxiosError) => void
) => {
  const path = '/hsm_edit';
  const headers = buildAuthorizedRequestHeader(user);
  
  // Ensure workflow has all required properties
  const safeWorkflow = ensureWorkflowSafety(workflow);
  
  const responseData = (await submitPostRequest(
    path,
    safeWorkflow,
    headers,
    handleAuthError
  )) as AxiosResponse<any, any>;
  
  if (responseData && responseData.data) {
    const result = responseData.data;
    
    await addDoc(collection(db, "notifications"), {
      text: `${user.name} created new workflow`,
      category: "new_workflow",
      targeted_user: "admin",
      createdby: user.name,
      avatarId: result.avatar,
    });
    return result;
  } else {
    throw new Error('Workflow creation failed');
  }
};

// Function to ensure workflow has all required properties before sending to backend
function ensureWorkflowSafety(workflow: any): any {
  if (!workflow) {
    console.error("Workflow is null or undefined");
    return {};
  }
  
  try {
    // Make a copy to avoid modifying the original
    const safeWorkflow = JSON.parse(JSON.stringify(workflow));
    
    // Ensure the workflow has buildingBlocks
    if (!safeWorkflow.buildingBlocks) {
      const isHumanMode = safeWorkflow.viewMode === "human";
      safeWorkflow.buildingBlocks = {
        userFunctionality: "",
        userBehavior: "",
        environmentModel: "",
        caregiverFunctionality: "",
        caregiverBehavior: "",
        robotModel: isHumanMode ? null : ""
      };
    }
    
    // Ensure all top-level properties that might be accessed by the backend
    safeWorkflow.id = safeWorkflow.id || new ObjectId().toHexString();
    safeWorkflow.children = Array.isArray(safeWorkflow.children) ? safeWorkflow.children : [];
    
    return safeWorkflow;
  } catch (error) {
    console.error("Error ensuring workflow safety:", error);
    // Return a minimal valid workflow
    return {
      id: new ObjectId().toHexString(),
      children: [],
      buildingBlocks: {
        userFunctionality: "",
        userBehavior: "",
        environmentModel: "",
        caregiverFunctionality: "",
        caregiverBehavior: "",
        robotModel: null
      }
    };
  }
}

const submitGetRequest = async (
  path: string,
  handleAuthError?: (err: AxiosError) => void,
  handleNotFound?: (err: AxiosError) => void,
  config?: any
) => {
  const response = await axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URI}${path}`, config)
    .catch((err: AxiosError) => {
      if (err.response) {
        if (err.response.status === HTTP_UNAUTHORIZED && handleAuthError) {
          handleAuthError(err);
        } else if (err.response.status === HTTP_NOT_FOUND) {
          if (handleNotFound) {
            handleNotFound(err);
          }
          return null;
        }
        console.error('Caught AxiosError: ', err);
      }
    });

  return response;
};

const submitPostRequest = async (
  path: string,
  body?: any,
  config?: any,
  handleAuthError?: (err: AxiosError) => void
) => {
  try {
    const response = await axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URI}${path}`, body, config)
      .catch((err: AxiosError) => {
        if (err.response) {
          if (err.response.status === HTTP_UNAUTHORIZED && handleAuthError) {
            handleAuthError(err);
          }
          console.error('Caught AxiosError with response:', err);
        } else if (err.code === 'ERR_NETWORK' || err.message?.includes('Network Error')) {
          // Network error - likely server not running
          console.error('Backend server connection error:', err.message);
          const backendUrl = process.env.REACT_APP_BACKEND_BASE_URI;
          throw new Error(`Cannot connect to backend server at ${backendUrl}. Please ensure the server is running.`);
        } else {
          console.error('Caught other AxiosError:', err);
        }
        throw err;
      });

    return response;
  } catch (error) {
    console.error('Error in submitPostRequest:', error);
    throw error;
  }
};

const buildAuthorizedRequestHeader = (
  user: UserContext
): { headers: { Authorization: string } } => {
  return { headers: { Authorization: `Bearer ${user.access_token}` } };
};
