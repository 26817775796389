import { ObjectId } from "bson";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "./interface/AppInterface";
import {
  createWorkflowRequest,
  getWorkflowByIdRequest,
} from "../services/WorkflowService";
import { getNewStateProps } from "./stateUtils";
import { Visibility } from "@mui/icons-material";

export function templateActivityProps(parentProps: any, isHumanMode: boolean = false): any[] {
  // Handle null or undefined parentProps
  if (!parentProps) {
    return [];
  }
  
  // Handle missing children property
  if (!parentProps.children) {
    return [];
  }

  const children = Array.isArray(parentProps.children) 
    ? [...parentProps.children] 
    : [];

  // for each child, do the update, then call recursively on its list of children
  for (let i = 0; i < children.length; i++) {
    if (!children[i]) {
      // Skip undefined children
      continue;
    }
    
    children[i].template = children[i].uid || new ObjectId().toHexString();
    children[i].uid = new ObjectId().toHexString();
    
    // Ensure buildingBlocks exists on every state with all required fields
    children[i].buildingBlocks = {
      userFunctionality: children[i].buildingBlocks?.userFunctionality || "",
      userBehavior: children[i].buildingBlocks?.userBehavior || "",
      environmentModel: children[i].buildingBlocks?.environmentModel || "",
      caregiverFunctionality: children[i].buildingBlocks?.caregiverFunctionality || "",
      caregiverBehavior: children[i].buildingBlocks?.caregiverBehavior || "",
      // Handle robotModel based on mode
      robotModel: isHumanMode ? null : (children[i].buildingBlocks?.robotModel || "")
    };
    
    // Ensure all state attributes have empty strings instead of undefined values
    children[i].type = children[i].type || "";
    children[i].text = children[i].text || "";
    children[i].preconditions = children[i].preconditions || "";
    children[i].postconditions = children[i].postconditions || "";
    children[i].ownership = children[i].ownership || "";
    children[i].avatar = children[i].avatar || "";
    children[i].imageLink = children[i].imageLink || "";
    children[i].videoLink = children[i].videoLink || "";
    children[i].author = children[i].author || "";
    children[i].attribute = children[i].attribute || "";
    children[i].reviewStatus = children[i].reviewStatus || "";
    children[i].visibility = children[i].visibility !== undefined ? children[i].visibility : false;
    children[i].viewMode = children[i].viewMode || "";
    
    // Process nested children recursively
    if (Array.isArray(children[i].children) || children[i].children) {
      children[i].children = templateActivityProps(children[i], isHumanMode);
    } else {
      children[i].children = [];
    }
  }

  return children;
}

export function onWorkflowInserted(data: any) {
  window.open(`${window.origin}/edit/` + data._id, "_self");
}

export const useDefaultValues = () => {
  const [urlParams] = useSearchParams();

  const defaultAvatar = urlParams.get("avatar") || undefined;
  const defaultADL = urlParams.get("adl") || "Dressing";
  const defaultActivityName = urlParams.get("activity") || undefined;
  const defaultCaregiver =
    urlParams.get("caregiver") === "human"
      ? true
      : urlParams.get("caregiver") === "robot"
      ? false
      : true;
  const defaultParent = urlParams.get("parent") || undefined;

  return {
    defaultAvatar,
    defaultADL,
    defaultActivityName,
    defaultCaregiver,
    defaultParent,
  };
};

// Function to recursively update UIDs and ensure all required properties exist
function processStateHierarchy(state, isHumanMode = false) {
  if (!state) return null;
  
  // Store original UID as template reference
  state.template = state.uid || new ObjectId().toHexString();
  // Generate new UID
  state.uid = new ObjectId().toHexString();
  
  // Ensure buildingBlocks exists and has all required fields
  state.buildingBlocks = {
    userFunctionality: state.buildingBlocks?.userFunctionality || "",
    userBehavior: state.buildingBlocks?.userBehavior || "",
    environmentModel: state.buildingBlocks?.environmentModel || "",
    caregiverFunctionality: state.buildingBlocks?.caregiverFunctionality || "",
    caregiverBehavior: state.buildingBlocks?.caregiverBehavior || "",
    // For human mode, some backends might expect robotModel to be null rather than empty string
    robotModel: isHumanMode ? (state.buildingBlocks?.robotModel === undefined ? null : state.buildingBlocks?.robotModel) : (state.buildingBlocks?.robotModel || "")
  };
  
  // Ensure other essential properties exist
  state.type = state.type || "";
  state.text = state.text || "";
  state.preconditions = state.preconditions || "";
  state.postconditions = state.postconditions || "";
  state.ownership = state.ownership || "";
  state.attribute = state.attribute || "";
  state.reviewStatus = state.reviewStatus || "Pending";
  state.visibility = state.visibility !== undefined ? state.visibility : false;
  
  // Process children recursively
  if (Array.isArray(state.children)) {
    state.children = state.children.map(child => processStateHierarchy(child, isHumanMode)).filter(Boolean);
  } else {
    state.children = [];
  }
  
  return state;
}

// Deep sanitize workflow to prevent undefined errors
function sanitizeWorkflow(workflow) {
  if (!workflow) return {};
  
  try {
    // Create a deep copy to avoid modifying the original
    const sanitized = JSON.parse(JSON.stringify(workflow));
    
    // Ensure top-level properties
    sanitized.id = sanitized.id || new ObjectId().toHexString();
    sanitized.author = sanitized.author || 0;
    sanitized.avatar = sanitized.avatar || "";
    sanitized.adl = sanitized.adl || "";
    sanitized.text = sanitized.text || "";
    sanitized.attribute = sanitized.attribute || "";
    sanitized.reviewStatus = sanitized.reviewStatus || "Pending";
    sanitized.visibility = sanitized.visibility === undefined ? false : sanitized.visibility;
    sanitized.viewMode = sanitized.viewMode || "human";
    sanitized.template = sanitized.template || "";
    sanitized.preconditions = sanitized.preconditions || "";
    sanitized.postconditions = sanitized.postconditions || "";
    sanitized.ownership = sanitized.ownership || "";
    sanitized.imageLink = sanitized.imageLink || "";
    sanitized.videoLink = sanitized.videoLink || "";
    sanitized.type = sanitized.type || "";
    
    // Ensure buildingBlocks exists with all required fields
    const isHumanMode = (sanitized.viewMode === "human");
    if (!sanitized.buildingBlocks) {
      sanitized.buildingBlocks = {
        userFunctionality: "",
        userBehavior: "",
        environmentModel: "",
        caregiverFunctionality: "",
        caregiverBehavior: "",
        robotModel: isHumanMode ? null : ""
      };
    } else {
      // Ensure all buildingBlocks properties exist with safe values
      sanitized.buildingBlocks.userFunctionality = sanitized.buildingBlocks.userFunctionality || "";
      sanitized.buildingBlocks.userBehavior = sanitized.buildingBlocks.userBehavior || "";
      sanitized.buildingBlocks.environmentModel = sanitized.buildingBlocks.environmentModel || "";
      sanitized.buildingBlocks.caregiverFunctionality = sanitized.buildingBlocks.caregiverFunctionality || "";
      sanitized.buildingBlocks.caregiverBehavior = sanitized.buildingBlocks.caregiverBehavior || "";
      // Handle robotModel specially based on mode
      sanitized.buildingBlocks.robotModel = isHumanMode ? null : (sanitized.buildingBlocks.robotModel || "");
    }
    
    // Ensure children array exists and sanitize each child
    if (!Array.isArray(sanitized.children)) {
      sanitized.children = [];
    } else {
      sanitized.children = sanitized.children
        .filter(child => child !== null && child !== undefined)
        .map(child => {
          try {
            // Apply the same sanitization to each child recursively
            return sanitizeWorkflow(child);
          } catch (e) {
            console.error("Error sanitizing child:", e);
            // Return a minimal valid child if there's an error
            return {
              uid: new ObjectId().toHexString(),
              text: "",
              children: [],
              buildingBlocks: {
                userFunctionality: "",
                userBehavior: "",
                environmentModel: "",
                caregiverFunctionality: "",
                caregiverBehavior: "",
                robotModel: isHumanMode ? null : ""
              }
            };
          }
        });
    }
    
    return sanitized;
  } catch (e) {
    console.error("Error in sanitizeWorkflow:", e);
    // Return a minimal valid workflow if there's an error
    const isHumanMode = workflow?.viewMode === "human";
    return {
      id: new ObjectId().toHexString(),
      author: workflow?.author || 0,
      avatar: workflow?.avatar || "",
      adl: workflow?.adl || "",
      text: workflow?.text || "",
      attribute: "",
      reviewStatus: "Pending",
      visibility: false,
      viewMode: isHumanMode ? "human" : "robot",
      template: workflow?.template || "",
      children: [],
      buildingBlocks: {
        userFunctionality: "",
        userBehavior: "",
        environmentModel: "",
        caregiverFunctionality: "",
        caregiverBehavior: "",
        robotModel: isHumanMode ? null : ""
      }
    };
  }
}

export async function createWorkflowFromTemplate(
  user: UserContext,
  templateId: string,
  activity: string,
  caregiver: boolean,
  avatar?: string,
  adl?: string
) {
  if (!templateId || templateId.trim() === "") {
    console.error("No template ID provided");
    return;
  }

  try {
    console.log(`Fetching template workflow with ID: ${templateId}`);
    const templateWorkflow = await getWorkflowByIdRequest(templateId);
    
    if (!templateWorkflow) {
      console.error("Failed to fetch template workflow");
      alert("Template workflow not found.");
      return;
    }

    // Create a deep copy of the template workflow
    const workflowCopy = JSON.parse(JSON.stringify(templateWorkflow));
    
    // Define view mode
    const isHumanMode = caregiver;
    const targetViewMode = isHumanMode ? "human" : "robot";
    
    // Process the entire state hierarchy
    processStateHierarchy(workflowCopy, isHumanMode);
    
    // Generate a new ID for the copy
    const newId = new ObjectId().toHexString();
    
    // Ensure top-level properties are set correctly
    workflowCopy.id = newId;
    workflowCopy._id = undefined; // Let MongoDB generate this
    workflowCopy.text = activity || workflowCopy.text || "";
    workflowCopy.author = user.id;
    workflowCopy.template = templateId;
    workflowCopy.reviewStatus = "Pending";
    workflowCopy.visibility = false;
    workflowCopy.viewMode = targetViewMode;
    
    // Update avatar and ADL if provided
    workflowCopy.avatar = avatar || workflowCopy.avatar || "";
    workflowCopy.adl = adl || workflowCopy.adl || "";
    
    // Ensure buildingBlocks exists at the top level
    workflowCopy.buildingBlocks = {
      userFunctionality: workflowCopy.buildingBlocks?.userFunctionality || "",
      userBehavior: workflowCopy.buildingBlocks?.userBehavior || "",
      environmentModel: workflowCopy.buildingBlocks?.environmentModel || "",
      caregiverFunctionality: workflowCopy.buildingBlocks?.caregiverFunctionality || "",
      caregiverBehavior: workflowCopy.buildingBlocks?.caregiverBehavior || "",
      // Set robotModel based on viewMode
      robotModel: isHumanMode ? null : (workflowCopy.buildingBlocks?.robotModel || "")
    };
    
    // Final sanitization to ensure no undefined values
    const sanitizedWorkflow = sanitizeWorkflow(workflowCopy);
    
    console.log("Creating new workflow from template:", sanitizedWorkflow);
    
    // Save the sanitized workflow
    const newWorkflowResponse = await createWorkflowRequest(user, sanitizedWorkflow);
    onWorkflowInserted(newWorkflowResponse);
  } catch (error) {
    console.error("Failed to create workflow from template:", error);
    alert("Failed to create workflow: " + (error.message || "Unknown error"));
  }
}

export async function createWorkflow(
  user: UserContext,
  avatar: string,
  adl: string,
  activity: string,
  caregiver: boolean,
  parent: string,
  useSimpleCopy: boolean = true
) {
  if (parent && parent.trim() !== "" && useSimpleCopy) {
    return createWorkflowFromTemplate(user, parent, activity, caregiver, avatar, adl);
  }
  
  const isHumanMode = caregiver;
  const targetViewMode = isHumanMode ? "human" : "robot";
  
  // Only attempt to get parent workflow if a valid ID is provided
  let parentProps = {};
  if (parent && parent.trim() !== "") {
    try {
      console.log(`Trying to fetch template workflow with ID: ${parent}`);
      const response = await getWorkflowByIdRequest(parent);
      if (response) {
        console.log(`Successfully fetched template workflow:`, response);
        parentProps = response;
      } else {
        console.warn(`Unable to fetch parent workflow with ID: ${parent}`);
      }
    } catch (error) {
      console.error(`Error fetching parent workflow: ${error}`);
    }
  }

  const newWorkflowProps = !parent
    ? getNewStateProps(
        () => new ObjectId().toHexString(),
        4,
        (x) => {},
        [],
        (x) => {},
        (x) => {}
      )
    : {};

  // Create a safe deep copy of parent props
  let parentPropsCopy = {};
  try {
    if (Object.keys(parentProps).length > 0) {
      parentPropsCopy = JSON.parse(JSON.stringify(parentProps));
    }
  } catch (e) {
    console.error("Error creating deep copy of parentProps:", e);
  }
 
  // Always ensure we have valid children
  const templatedPropsChildren = parent && Object.keys(parentProps).length > 0
    ? templateActivityProps(parentProps, isHumanMode)
    : newWorkflowProps.children || [];

  // Create the base templatedProps object with required fields
  const templatedProps = {
    id: new ObjectId().toHexString(),
    children: templatedPropsChildren,
    author: user.id,
    avatar: avatar || "",
    adl: adl || "",
    text: activity || "",
    attribute: "",
    reviewStatus: "Pending",
    visibility: false,
    viewMode: targetViewMode || "",
    template: parent || "",
    // Ensure buildingBlocks exists with all required fields
    buildingBlocks: {
      userFunctionality: (parentProps as any)?.buildingBlocks?.userFunctionality || "",
      userBehavior: (parentProps as any)?.buildingBlocks?.userBehavior || "",
      environmentModel: (parentProps as any)?.buildingBlocks?.environmentModel || "",
      caregiverFunctionality: (parentProps as any)?.buildingBlocks?.caregiverFunctionality || "",
      caregiverBehavior: (parentProps as any)?.buildingBlocks?.caregiverBehavior || "",
      // Set robotModel based on viewMode - null for human mode, empty string for robot mode
      robotModel: isHumanMode ? null : ((parentProps as any)?.buildingBlocks?.robotModel || "")
    },
    preconditions: (parentProps as any)?.preconditions || "",
    postconditions: (parentProps as any)?.postconditions || "",
    ownership: (parentProps as any)?.ownership || "",
    imageLink: (parentProps as any)?.imageLink || "",
    videoLink: (parentProps as any)?.videoLink || "",
    type: (parentProps as any)?.type || ""
  };

  // Add any other properties from parentProps, but don't override our explicitly set ones
  if (Object.keys(parentPropsCopy).length > 0) {
    // Add any additional properties from the parent that we haven't explicitly set
    Object.keys(parentPropsCopy).forEach(key => {
      if (key !== 'id' && key !== 'children' && key !== 'author' && 
          key !== 'avatar' && key !== 'adl' && key !== 'text' && 
          key !== 'attribute' && key !== 'reviewStatus' && key !== 'visibility' && 
          key !== 'viewMode' && key !== 'template' && key !== 'buildingBlocks' && 
          key !== '_id' && key !== 'preconditions' && key !== 'postconditions' && 
          key !== 'ownership' && key !== 'imageLink' && key !== 'videoLink' &&
          key !== 'type') {
        // Ensure any additional attributes also use empty strings for undefined values
        templatedProps[key] = parentPropsCopy[key] || "";
      }
    });
  }

  try {
    // Final sanitization to ensure no undefined values
    const sanitizedProps = sanitizeWorkflow(templatedProps);
    
    const newWorkflowResponse = await createWorkflowRequest(user, sanitizedProps);
    onWorkflowInserted(newWorkflowResponse);
  } catch (error) {
    console.error("Failed to create workflow:", error);
    
    // Provide more specific error messages based on the error type
    if (error.message && error.message.includes('Cannot connect to backend server')) {
      alert("Cannot connect to the server. Please ensure the backend server is running and try again.");
    } else if (error.message === 'Workflow creation failed') {
      alert("The server was unable to create the workflow. Please try again or contact support.");
    } else {
      alert("Failed to create workflow: " + (error.message || "Unknown error"));
    }
  }
}
