import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaRuler, FaVideo } from "react-icons/fa";
import { PiNetworkFill } from "react-icons/pi";
import ModalVideo from "./modalvideo";

const Card = ({ imageUrl, title, paragraph, _id, measurements, videos }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [youtubeOptions, setYoutubeOptions] = useState({});

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const viewMeasurements = () => {
    // If measurements URL exists, open it in a new tab
    if (measurements) {
      window.open(measurements, "_blank");
    } else {
      // Fallback to the measurements page if no specific URL
      navigate("/measurements");
    }
  };

  const viewVideos = () => {
    // If videos URL exists, open it in the modal
    if (videos) {
      console.log("Processing video URL:", videos);
      
      // Check if videos might be empty or invalid
      if (videos === "null" || videos === "undefined" || videos === "") {
        console.log("Invalid or empty video URL, navigating to videos page");
        navigate("/videos");
        return;
      }
      
      try {
        // Handle different formats - sometimes videos might be comma-separated
        const videoUrls = videos.includes(',') ? videos.split(',') : [videos];
        const mainVideoUrl = videoUrls[0].trim(); // Use the first video URL
        
        // Check if the URL is valid
        if (!mainVideoUrl || mainVideoUrl === "null" || mainVideoUrl === "undefined") {
          console.log("Invalid main video URL, navigating to videos page");
          navigate("/videos");
          return;
        }
        
        console.log("Main video URL to process:", mainVideoUrl);
        
        // Check if it's a YouTube URL
        if (mainVideoUrl.includes('youtube.com') || mainVideoUrl.includes('youtu.be')) {
          let videoIdToUse = '';
          let playlistId = null;
          
          try {
            const url = new URL(mainVideoUrl);
            
            // Extract playlist ID if it exists
            playlistId = url.searchParams.get('list');
            console.log("Extracted playlist ID:", playlistId);
            
            // Extract video ID
            if (mainVideoUrl.includes('youtube.com/watch')) {
              videoIdToUse = url.searchParams.get('v') || '';
            } else if (mainVideoUrl.includes('youtu.be/')) {
              videoIdToUse = mainVideoUrl.split('youtu.be/')[1]?.split('?')[0] || '';
            } else if (mainVideoUrl.includes('youtube.com/embed/')) {
              videoIdToUse = mainVideoUrl.split('embed/')[1]?.split('?')[0] || '';
            }
            
            console.log("Extracted video ID:", videoIdToUse);
          } catch (urlError) {
            console.error("URL parsing error:", urlError);
            
            // Fallback: Try to extract video ID using regex
            const youtubeIdMatch = mainVideoUrl.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
            if (youtubeIdMatch && youtubeIdMatch[1]) {
              videoIdToUse = youtubeIdMatch[1];
              console.log("Extracted video ID using regex:", videoIdToUse);
            } else {
              console.error("Failed to extract video ID from URL:", mainVideoUrl);
              // If all extraction methods fail, just use the URL as is
              videoIdToUse = mainVideoUrl;
            }
          }
          
          // Only proceed if we have a valid video ID
          if (videoIdToUse) {
            setVideoId(videoIdToUse);
            
            // Set playlist options if available
            if (playlistId) {
              setYoutubeOptions({
                list: playlistId,
                listType: 'playlist'
              });
            }
            
            setModalIsOpen(true);
          } else {
            console.error("No valid video ID found, opening URL in new tab:", mainVideoUrl);
            window.open(mainVideoUrl, "_blank");
          }
        } else {
          // Not a YouTube URL, open in new tab
          console.log("Not a YouTube URL, opening in new tab:", mainVideoUrl);
          window.open(mainVideoUrl, "_blank");
        }
      } catch (e) {
        console.error("Error processing video URL:", e);
        // Fallback to opening the URL in a new tab
        window.open(videos, "_blank");
      }
    } else {
      // Fallback to the videos page if no specific URL
      console.log("No video URL provided, navigating to videos page");
      navigate("/videos");
    }
  };

  const viewPublicWorkflows = () => {
    if (_id) {
      navigate(`/adls/${_id}`);
    } else {
      // Fallback to avatars page if no ID is available
      navigate("/avatars");
    }
  };

  return (
    <div className="card">
      <div className="card bg-light" style={{ width: "auto", height: "auto" }}>
        <img 
          src={imageUrl} 
          className="card-img-top" 
          alt="Avatar"
          style={{ 
            width: "100%",
            height: "auto",
            objectFit: "contain",
            maxHeight: "300px",
            padding: "1rem"
          }}
        />
      </div>
      <div className="card-body">
        <h5 className="card-title mb-2 relative">{title}</h5>
        <div className="relative">
          <p
            className={`card-text ${expanded ? "expanded" : "collapsed"} mb-2`}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: expanded ? "none" : "3",
              lineHeight: "1.5",
              maxHeight: expanded ? "none" : "4.5em",
            }}
          >
            {paragraph}
          </p>
          {paragraph.length > 90 && (
            <button
              onClick={toggleExpanded}
              className="text-blue-600 hover:text-blue-800 text-sm font-medium transition-colors duration-200"
              style={{
                display: "block",
                width: "100%",
                textAlign: "left",
                padding: "4px 0",
              }}
            >
              {expanded ? "Show Less" : "Read More"}
            </button>
          )}
        </div>

        <div className="flex flex-col space-y-4 mr-2 ml-2">
          <button
            className="rounded-lg w-40 h-10 border flex align-items-center hover:text-white border-black hover:bg-black"
            onClick={viewMeasurements}
          >
            <FaRuler className="text-xl mr-2 ml-2" />
            <div>Measurements</div>
          </button>
          <button
            className="rounded-lg w-40 h-10 border flex align-items-center hover:text-white border-black hover:bg-black"
            onClick={viewVideos}
          >
            <FaVideo className="text-xl mr-2 ml-2" />
            <span>Mobility Videos</span>
          </button>
          <button
            className="rounded-lg w-40 h-10 border border-black flex align-items-center hover:bg-black hover:text-white"
            onClick={viewPublicWorkflows}
          >
            <PiNetworkFill className="text-xl mr-2 ml-2" />
            <div>View Workflow</div>
          </button>
        </div>
      </div>
      
      <ModalVideo
        channel="youtube"
        isOpen={modalIsOpen}
        videoId={videoId}
        onClose={() => {
          setModalIsOpen(false);
          setYoutubeOptions({});
        }}
        youtube={youtubeOptions}
      />
    </div>
  );
};

export default Card;
