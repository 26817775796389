import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { CenteredSpinner } from "./Spinner";
import ADLSPageTableHead from "./ADLSPageTableHead";
import ADLSPagination from "./ADLSPagination";
import ADLSRowcount from "./ADLSRowcount";
import ADLSPageSearch from "./ADLSPageSearch";
import { toast } from "react-toastify";
import axios from "axios";
import { db, collection, addDoc } from "../firebase";
import { Order, WorkflowProps, HeadCell } from "../utils/interface/ADLSPage";

function AdminWorkflow() {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof WorkflowProps>("category");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [workflowsLoaded, setWorkflowsLoaded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [userContext, setUserContext] = useState(null);
  const [highlightedRowId, setHighlightedRowId] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPage(value);
  };

  const filterRows = (text, rowsToFilter) => {
    if (!text || text.trim() === '') {
      return rowsToFilter;
    }
    
    const lowercasedFilter = text.toLowerCase().trim();
    return rowsToFilter.filter((row) => {
  return (
        (row.name && row.name.toLowerCase().includes(lowercasedFilter)) ||
        (row.category && row.category.toLowerCase().includes(lowercasedFilter)) ||
        (row.status && row.status.toLowerCase().includes(lowercasedFilter)) ||
        (row.avatarName && row.avatarName.toLowerCase().includes(lowercasedFilter)) ||
        (row.reviewStatus && row.reviewStatus.toLowerCase().includes(lowercasedFilter))
      );
    });
  };

  const handleSearch = (text) => {
    setSearchText(text);
    const filteredData = filterRows(text, rows);
    setFilteredRows(filteredData);
    
    // Reset to page 1 when filtering
    setCurrentPage(1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (url) => {
    window.open(url, "_self");
  };

  const calculatePageCount = (totalData, rowsPerPage) => {
    return Math.ceil(totalData / rowsPerPage) === 0
      ? 1
      : Math.ceil(totalData / rowsPerPage);
  };

  useEffect(() => {
    setPageCount(calculatePageCount(filteredRows.length, rowsPerPage));
  }, [rowsPerPage, filteredRows.length]);

  useEffect(() => {
    const userContextData = sessionStorage.getItem("user");
    if (userContextData) {
      setUserContext(JSON.parse(userContextData));
    }
  }, []);

  useEffect(() => {
    if (!userContext) return;

    // Only admins and superadmins can access this
    if (!(userContext.role === "admin" || userContext.role === "superadmin")) {
      return;
    }

    // Fetch all workflows with pending review status
    const url = `${process.env.REACT_APP_BACKEND_BASE_URI}/adls/admin/pending`;
    
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        // Transform data for table display
        const pendingWorkflows = [];
        
        data.forEach((category) => {
          category.children.forEach((workflow) => {
            if (workflow.reviewStatus === "Pending" || !workflow.reviewStatus) {
              pendingWorkflows.push({
                id: workflow._id,
                name: workflow.text,
                category: workflow.adl,
                status: !workflow.visibility ? "Private" : "Public",
                avatarName: workflow.avatarName || "Not specified", // Add avatar name
                url: `/view/${workflow._id}`,
                viewMode: workflow.viewMode,
                author: workflow.author,
                avatar: workflow.avatar,
                visibility: workflow.visibility,
                reviewStatus: workflow.reviewStatus || "Pending"
              });
            }
          });
        });
        
        setRows(pendingWorkflows);
        setFilteredRows(pendingWorkflows);
        console.log("Loaded pending workflows:", pendingWorkflows.length);
      })
      .then(() => {
        setRefresh(true);
        setWorkflowsLoaded(true);
      })
      .catch(err => {
        console.error("Error fetching pending workflows:", err);
        setWorkflowsLoaded(true); // Set to true to remove spinner even if error occurs
      });
  }, [userContext, refresh]);

  const updateWorkflowStatus = (workflowId, newStatus, newVisibility = null) => {
    // Update rows data
    const updatedRows = rows.map(row => {
      if (row.id === workflowId) {
        return { 
          ...row, 
          reviewStatus: newStatus,
          ...(newVisibility !== null && { 
            visibility: newVisibility,
            status: newVisibility ? "Public" : "Private" 
          })
        };
      }
      return row;
    });
    
    // Update state
    setRows(updatedRows);
    
    // Apply current search filter to keep the filtered view consistent
    const newFilteredRows = filterRows(searchText, updatedRows);
    setFilteredRows(newFilteredRows);
    
    // Find the index of the updated row in the filtered and sorted list
    const sortedFilteredRows = stableSort(newFilteredRows, getComparator(order, orderBy));
    const rowIndex = sortedFilteredRows.findIndex(row => row.id === workflowId);
    
    // If the row would be outside the current page view after filtering/sorting,
    // adjust the current page to ensure the row is visible
    if (rowIndex >= 0) {
      const targetPage = Math.floor(rowIndex / rowsPerPage) + 1;
      if (targetPage !== currentPage) {
        setCurrentPage(targetPage);
      }
    }
    
    // Highlight the row that was updated
    setHighlightedRowId(workflowId);
    
    // Remove highlight after 3 seconds
    setTimeout(() => {
      setHighlightedRowId(null);
    }, 3000);
  };

  const verifyWorkflowUpdate = async (workflowId, expectedStatus, expectedVisibility = null) => {
    console.log(`Verifying database update for workflow ${workflowId}...`);
    
    try {
      const verifyResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/adls_view/${workflowId}`
      );
      
      const workflow = verifyResponse.data;
      let isValid = true;
      
      // Check review status
      if (workflow.reviewStatus !== expectedStatus) {
        console.warn(`Verification error: Expected reviewStatus '${expectedStatus}' but got '${workflow.reviewStatus}'`);
        isValid = false;
      }
      
      // Check visibility if specified
      if (expectedVisibility !== null && workflow.visibility !== expectedVisibility) {
        console.warn(`Verification error: Expected visibility '${expectedVisibility}' but got '${workflow.visibility}'`);
        isValid = false;
      }
      
      if (isValid) {
        console.log(`✓ Database verification successful for workflow ${workflowId}`);
        return true;
      } else {
        console.log(`× Database verification failed for workflow ${workflowId}`);
        // Force a full refresh as database state doesn't match expected state
        setRefresh(false);
        return false;
      }
    } catch (error) {
      console.error(`Error verifying workflow update:`, error);
      return false;
    }
  };

  const handleApproveWorkflow = async (workflow) => {
    try {
      console.log(`Updating workflow ${workflow.id} to status 'Approved' and making it public...`);
      
      // Make the API call to update the database
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/adls_edit/${workflow.id}`,
        {
          updateData: { 
            reviewStatus: "Approved",
            visibility: true // Make workflow public when approved
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Verify the update was successful
      if (response.status !== 200) {
        throw new Error(`Server returned status ${response.status}: ${response.data?.error || 'Unknown error'}`);
      }

      console.log(`Successfully updated workflow ${workflow.id} in database:`, response.data);

      // Add notification
      await addDoc(collection(db, "notifications"), {
        text: `${workflow.name} Approved`,
        category: "new_workflow",
        targeted_user: workflow.author,
        createdby: "admin",
        avatarId: workflow.avatar,
      });

      // Add credits to the author
      await handleAddCredits(workflow.author, 10, userContext);
      
      // Update UI immediately - set reviewStatus to Approved and visibility to true (public)
      updateWorkflowStatus(workflow.id, "Approved", true);
      
      toast.success("Workflow approved and made public");
      
      // Verify database update with dedicated function
      await verifyWorkflowUpdate(workflow.id, "Approved", true);
      
      // Trigger a refresh afterward to ensure data consistency
      setTimeout(() => setRefresh(false), 500);
    } catch (error) {
      console.error("Error approving workflow:", error);
      toast.error("Failed to approve workflow");
      setRefresh(false); // Force refresh on error
    }
  };

  const handleReviewWorkflow = async (workflow) => {
    try {
      console.log(`Updating workflow ${workflow.id} status to 'Reviewed'...`);
      
      // Make the API call to update the database
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/adls_edit/${workflow.id}`,
        {
          updateData: { reviewStatus: "Reviewed" },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Verify the update was successful
      if (response.status !== 200) {
        throw new Error(`Server returned status ${response.status}: ${response.data?.error || 'Unknown error'}`);
      }

      console.log(`Successfully updated workflow ${workflow.id} in database:`, response.data);

      // Add notification
      await addDoc(collection(db, "notifications"), {
        text: `${workflow.name} has been reviewed`,
        category: "review_workflow",
        targeted_user: workflow.author,
        createdby: "admin",
        avatarId: workflow.avatar,
      });
      
      // Update UI immediately
      updateWorkflowStatus(workflow.id, "Reviewed");
      
      toast.success("Workflow marked as reviewed");
      
      // Verify database update with dedicated function
      await verifyWorkflowUpdate(workflow.id, "Reviewed");
      
      // Trigger a refresh afterward to ensure data consistency
      setTimeout(() => setRefresh(false), 500);
    } catch (error) {
      console.error("Error marking workflow as reviewed:", error);
      toast.error("Failed to mark workflow as reviewed");
      setRefresh(false); // Force refresh on error
    }
  };

  const handleAddCredits = async (userId, amount, user) => {
    try {
      console.log(`Adding ${amount} credits to user ${userId}...`);
      
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm/credits`,
        {
          userId,
          amount,
        }
      );

      if (response.status === 201) {
        console.log(`Successfully added credits: ${response.data.message}`);
        toast.success(` ${response.data.message}`);
        const newComment = {
          text: response.data.message,
          target: userId,
          user: user,
          timestamp: new Date(),
        };
        await saveCommentToDB(newComment);
      } else {
        console.warn(`Unexpected status when adding credits: ${response.status}`);
      }
    } catch (error) {
      console.error("Error adding credits:", error);
      toast.error("Failed to add credits to the author");
    }
  };

  const saveCommentToDB = async (comment) => {
    try {
      console.log(`Saving comment to database: "${comment.text}"`);
      
      const docRef = await addDoc(collection(db, "notifications"), {
        text: comment.text,
        category: "comment",
        targeted_user: comment.target,
        createdby: comment.user.role,
      });
      
      console.log(`Comment saved successfully with ID: ${docRef.id}`);
    } catch (err) {
      console.error("Error saving comment", err);
    }
  };

  // Custom header cells with avatar name column
  const headCells: readonly HeadCell[] = [
    {
      id: "count" as keyof WorkflowProps,
      disablePadding: false,
      label: "#",
      alwaysVisible: true
    },
    {
      id: "name" as keyof WorkflowProps,
      disablePadding: false,
      label: "Name",
      alwaysVisible: true
    },
    {
      id: "avatarName" as keyof WorkflowProps,
      disablePadding: false,
      label: "Avatar Name",
      alwaysVisible: true
    },
    {
      id: "category" as keyof WorkflowProps,
      disablePadding: false,
      label: "ADL",
      alwaysVisible: true
    },
    {
      id: "status" as keyof WorkflowProps,
      disablePadding: false,
      label: "Status",
      alwaysVisible: true
    },
    {
      id: "reviewStatus" as keyof WorkflowProps,
      disablePadding: false,
      label: "Review Status",
      alwaysVisible: true
    }
  ];

  // Simple comparator for sorting
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  // Stable sort function
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  return !workflowsLoaded ? (
    <div className="flex justify-center items-center w-full h-64">
      <CenteredSpinner />
    </div>
  ) : (
    <Box sx={{ width: "100%" }}>
      <div>
        <div className="flex gap-4 align-items-center mb-4">
          <ADLSRowcount
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <ADLSPageSearch onSearch={handleSearch} />
          {searchText && (
            <div className="text-sm text-gray-600">
              {filteredRows.length} results found for "{searchText}"
            </div>
          )}
        </div>

        <div className="w-full overflow-x-auto bg-white rounded-lg shadow-md">
          <table className="min-w-full divide-y divide-gray-200">
            <ADLSPageTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            {!refresh ? (
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td 
                    colSpan={headCells.length + 1} 
                    className="text-center py-12"
                  >
                    <div className="flex justify-center items-center">
                      <CenteredSpinner />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : filteredRows.length === 0 ? (
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td 
                    colSpan={headCells.length + 1} 
                    className="text-center py-12"
                  >
                    <div className="flex flex-col justify-center items-center">
                      <svg className="w-12 h-12 text-gray-400 mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>
                      <p className="text-gray-500 text-lg">
                        {searchText ? 'No workflows found matching your search' : 'No pending workflows found'}
                      </p>
                      {searchText && (
                        <button 
                          onClick={() => handleSearch('')}
                          className="mt-3 px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Clear Search
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="bg-white divide-y divide-gray-200">
                {stableSort(filteredRows, getComparator(order, orderBy))
                  .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
                  .map((row, index) => (
                    <tr
                      key={index}
                      className={`hover:bg-gray-50 border-b border-gray-200 transition-colors duration-150 ease-in-out ${
                        row.id === highlightedRowId 
                          ? 'bg-yellow-100 animate-pulse'
                          : ''
                      }`}
                    >
                      <td className="px-4 py-4 text-center">{(currentPage - 1) * rowsPerPage + index + 1}</td>
                      <td className="px-4 py-4 text-center font-medium text-gray-800">{row.name}</td>
                      <td className="px-4 py-4 text-center">{row.avatarName}</td>
                      <td className="px-4 py-4 text-center">{row.category}</td>
                      <td className="px-4 py-4 text-center">
                        <span className={`px-3 py-1.5 inline-block text-sm font-medium ${row.visibility ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800"} rounded-full`}>
                          {row.visibility ? "Public" : "Private"}
                        </span>
                      </td>
                      <td className="px-4 py-4 text-center">
                        <span className={`px-3 py-1.5 inline-block text-sm font-medium ${
                          row.reviewStatus === "Approved" 
                            ? "bg-green-100 text-green-800" 
                            : row.reviewStatus === "Reviewed" 
                              ? "bg-blue-100 text-blue-800"
                              : "bg-yellow-100 text-yellow-800"
                        } rounded-full`}>
                          {row.reviewStatus}
                        </span>
                      </td>
                      <td className="px-4 py-4 text-center">
                        <button
                          className="px-3 py-1.5 bg-blue-500 text-white text-sm rounded-md shadow-sm hover:bg-blue-600 transition-colors mr-2"
                          onClick={() => handleClick(row.url)}
                        >
                          View
                        </button>
                        <button
                          className="px-3 py-1.5 bg-purple-500 text-white text-sm rounded-md shadow-sm hover:bg-purple-600 transition-colors mr-2"
                          onClick={() => handleReviewWorkflow(row)}
                        >
                          Mark Reviewed
                        </button>
                        <button
                          className="px-3 py-1.5 bg-green-500 text-white text-sm rounded-md shadow-sm hover:bg-green-600 transition-colors"
                          onClick={() => handleApproveWorkflow(row)}
                        >
                          Approve
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
        
        {filteredRows.length > 0 && (
          <div className="flex align-items-center justify-center mt-5">
            <ADLSPagination
              count={pageCount}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </Box>
  );
}

export default AdminWorkflow;
