import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">1. Introduction</h2>
        <p className="mb-4">
          This Privacy Policy describes how we collect, use, and handle your personal information when you use our website and services.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">2. Information We Collect</h2>
        <p className="mb-4">
          We may collect the following types of information:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Personal Information:</strong> Such as your name, email address, and occupation when you create an account.</li>
          <li><strong>Usage Information:</strong> Information about how you use our website, including browsing activities and patterns.</li>
          <li><strong>Device Information:</strong> Information about the device you use to access our website, such as IP address, browser type, and operating system.</li>
        </ul>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">3. How We Use Your Information</h2>
        <p className="mb-4">
          We use the information we collect to:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Provide, maintain, and improve our services</li>
          <li>Create and manage your account</li>
          <li>Communicate with you, including sending service updates and responding to your inquiries</li>
          <li>Detect, investigate, and prevent security incidents and other malicious, deceptive, fraudulent, or illegal activity</li>
        </ul>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">4. Information Sharing and Disclosure</h2>
        <p className="mb-4">
          We do not share your personal information with third parties except in the following circumstances:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>With your consent</li>
          <li>To comply with legal obligations</li>
          <li>To protect the rights, property, or safety of our users, ourselves, or others</li>
          <li>In connection with a merger, sale of company assets, financing, or acquisition</li>
        </ul>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">5. Data Security</h2>
        <p className="mb-4">
          We take reasonable measures to help protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">6. Your Choices</h2>
        <p className="mb-4">
          You can access, update, or delete your account information at any time by logging into your account. If you wish to delete your account entirely, please contact us.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">7. Cookies and Similar Technologies</h2>
        <p className="mb-4">
          We use cookies and similar technologies to collect information about your browsing activities and to improve your experience on our website. You can manage your cookie preferences through your browser settings.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">8. Children's Privacy</h2>
        <p className="mb-4">
          Our services are not directed to children under the age of 13, and we do not knowingly collect personal information from children under 13.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">9. Changes to This Privacy Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </section>
      
      <section>
        <h2 className="text-xl font-semibold mb-3">10. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy, please contact us.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy; 