import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Card from "../components/AvatorCard";
import { Box, TextField, IconButton, useMediaQuery } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { db, collection, addDoc } from "../firebase";

import SpacsBox from "../components/HeroSection";

function CommentsPage({ userContext, setCursor }) {
  const { avatar } = useParams();
  const [avatars, setAvatars] = useState([]);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const [avatarsLoaded, setAvatarsLoaded] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const commentsEndRef = useRef(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${avatar}`
      );
      setComments(response.data);

      // scrollToBottom();
    } catch (err) {
      console.error("Error fetching comments", err);
    }
  };

  const saveCommentToDB = async (comment) => {
    try {
      console.log("Sending comment to backend:", JSON.stringify(comment));
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/saveComment`,
        comment
      );
      console.log("Response from backend:", response.data);

      await addDoc(collection(db, "notifications"), {
        text: comment.text,
        category: "comment",
        targeted_user:
          (userContext.role === "admin" || userContext.role === "superadmin") && avatars.length > 0 
            ? avatars[0].createdby 
            : "admin",
        createdby: userContext.name,
        avatarId: avatar,
      });
      fetchComments();
    } catch (err) {
      console.error("Error saving comment", err);
    }
  };

  const deleteCommentFromDB = async (id) => {
    try {
      // Get current user from session storage for permission check
      const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
      const userId = currentUser?.id || "";
      const role = currentUser?.role || "";
      
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${id}`,
        {
          params: {
            userId: userId,
            role: role
          }
        }
      );
      fetchComments();
    } catch (err) {
      console.error("Error deleting comment", err);
      if (err.response && err.response.status === 403) {
        alert("Permission denied: " + (err.response.data.error || "You can only delete your own comments"));
      }
    }
  };

  const handleCommentSubmit = async () => {
    const newComment = {
      text: commentText,
      user: userContext.name,
      userId: userContext.id,
      timestamp: new Date(),
      avatarId: avatar,
      stateId: null,        // Default value, will be populated when commenting on specific states
      statePath: [],        // Default value, will be populated when commenting on specific states
      stateText: "General"  // Default to "General" for comments not associated with a specific state
    };
    await saveCommentToDB(newComment);

    setCommentText("");
    // scrollToBottom();
  };

  const handleDeleteComment = async (id) => {
    await deleteCommentFromDB(id);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleCommentSubmit();
    }
  };

  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    async function getAvatars() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${avatar}`
      );

      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }
      let avatarsData = [];
      const avatarObj = await response.json();

      if (
        avatarObj.created_by === userContext.id ||
        userContext.role === "admin" || userContext.role === "superadmin"
      ) {
        avatarsData = [
          {
            title: avatarObj.name,
            _id: avatarObj._id,
            paragraph: avatarObj.description,
            setPlaylistOpen: setPlaylistOpen,
            setVideoUrl: setVideoUrl,
            videoUrl: avatarObj.videos,
            playlistOpen: playlistOpen,
            imageUrl: avatarObj.image,
            status: avatarObj.status,
            createdby: avatarObj.created_by,
            measurements: avatarObj.measurements,
          },
        ];
      } else {
        console.log("No avatars created by this user.");
      }

      setAvatars(avatarsData);
      setAvatarsLoaded(true);
    }

    if (!avatarsLoaded) {
      getAvatars();
    }

    fetchComments();
  }, [avatarsLoaded, avatar, userContext]);

  return (
    <div>
      <div className="container-md mt-10">
        <div className="row">
          <SpacsBox heading="" buttonTitle="Comments" />
        </div>
      </div>
      <div className="flex flex-wrap gap-5 justify-center w-full">
        <div className="flex flex-wrap w-full md:w-3/12 justify-center">
          {avatars.map((obj) => (
            <Card
              key={obj._id}
              userContext={userContext}
              {...obj}
              setAvatarsLoaded={setAvatarsLoaded}
              comment={true}
            />
          ))}
        </div>
        <div className="flex justify-center w-full md:w-8/12">
          <Box
            sx={{
              width: "100%",
              minHeight: "300px",
              maxHeight: comments.length > 5 ? "500px" : "auto",
              border: "1px solid black",
              borderRadius: "8px",
              padding: "16px",
              backgroundColor: "#ffffff",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflow: "hidden",
            }}
          >
            {/* Comments list area - will grow to fill available space */}
            <Box 
              sx={{ 
                flex: "1 1 auto", 
                overflowY: "auto", 
                marginBottom: 2,
                paddingRight: "8px", 
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#bbb",
                  borderRadius: "4px",
                  "&:hover": {
                    background: "#999",
                  },
                },
              }}
            >
              {comments.length === 0 ? (
                <div style={{ 
                  textAlign: "center", 
                  padding: "30px 0",
                  color: "#666" 
                }}>
                  <p>No comments yet</p>
                  <p style={{ fontSize: "14px", marginTop: "8px" }}>Be the first to leave a comment</p>
                </div>
              ) : (
                comments.map((comment) => {
                  // Check if user owns comment or is admin
                  const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
                  const isAdmin = currentUser?.role === "admin" || currentUser?.role === "superadmin";
                  const canDelete = comment.userId === currentUser?.id || isAdmin;
                  
                  return (
                    <Box
                      key={comment._id}
                      sx={{
                        border: "none",
                        borderRadius: "8px",
                        padding: "16px",
                        marginBottom: "8px",
                        backgroundColor: "#f0f0f0",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          width: "90%",
                          textAlign: "left",
                        }}
                      >
                        <strong>{comment.user}</strong>
                        <p style={{ wordBreak: "break-word", margin: "4px 0" }}>{comment.text}</p>
                        {comment.stateText && comment.stateText !== "General" && (
                          <small style={{ color: "#666", display: "block", marginTop: "4px" }}>
                            Task: {comment.stateText}
                          </small>
                        )}
                        <small style={{ display: "block", marginTop: "4px", color: "#666" }}>
                          {new Date(comment.timestamp).toLocaleString()}
                        </small>
                      </Box>
                      {canDelete && (
                        <IconButton
                          onClick={() => handleDeleteComment(comment._id)}
                          sx={{ color: "red" }}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  );
                })
              )}
              <div ref={commentsEndRef} />
            </Box>
            
            {/* Comment input form - always at the bottom */}
            <Box
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: "100%",
                paddingTop: "16px",
                borderTop: "1px solid #ddd",
                flex: "0 0 auto",
              }}
              onSubmit={(e) => {
                e.preventDefault();
                handleCommentSubmit();
              }}
            >
              <TextField
                label="Write a comment"
                variant="outlined"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                onKeyPress={handleKeyPress}
                fullWidth
                multiline
                maxRows={3}
                size="small"
              />
              <IconButton
                type="submit"
                sx={{ 
                  backgroundColor: commentText.trim() ? "black" : "#ccc", 
                  color: "white",
                  '&:hover': {
                    backgroundColor: commentText.trim() ? "#333" : "#ccc",
                  }
                }}
                disabled={!commentText.trim()}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default CommentsPage;
