import axios, { AxiosError, AxiosResponse } from 'axios';
import { UserContext, UserOccupation } from '../utils/interface/AppInterface';

const HTTP_UNAUTHORIZED = 401;

export const signup = async (
  user: UserContext,
  name: string,
  experience: string,
  robotsExperience: string,
  occupation: string,
  affiliation: string,
  handleAuthError?: (err: AxiosError) => void
) => {

  const path = '/signup';
  const headers = buildAuthorizedRequestHeader(user);
  const body = { 
    id: user.id, 
    occupation: occupation,
    name: name,
    experience: experience,
    robotsExperience: robotsExperience,
    affiliation: affiliation 
  };
  const responseData = (await submitPostRequest(
    path,
    body,
    headers,
    handleAuthError
  )) as AxiosResponse<any, any>;
  return responseData.data as UserContext;
};

const submitPostRequest = async (
  path: string,
  body?: any,
  config?: any,
  handleAuthError?: (err: AxiosError) => void
) => {
  const response = await axios
    .post<UserContext>(
      `${process.env.REACT_APP_BACKEND_BASE_URI}${path}`,
      body,
      config
    )
    .catch((err: AxiosError) => {
      if (err.response) {
        if (err.response.status === HTTP_UNAUTHORIZED && handleAuthError) {
          handleAuthError(err);
        }
        console.error('Caught AxiosError: ', err);
      }
    });

  return response;
};

const buildAuthorizedRequestHeader = (
  user: UserContext
): { headers: { Authorization: string } } => {
  return { headers: { Authorization: `Bearer ${user.access_token}` } };
};
