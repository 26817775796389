import React, { useState, useEffect } from "react";
import axios from "axios";

export type HSMCommentsAggregatorProps = {
  activityId: string;
  onNavigateToState?: (stateId: string) => void; // Optional now since we're not using it
};

const HSMCommentsAggregator = ({
  activityId
}: HSMCommentsAggregatorProps) => {
  const [allComments, setAllComments] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get current user info for permissions
  const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
  const isAdmin = currentUser?.role === "admin" || currentUser?.role === "superadmin";
  const currentUserId = currentUser?.id || "";

  // Fetch all comments for this HSM
  useEffect(() => {
    const fetchAllComments = async () => {
      try {
        setLoading(true);
        
        // Fetch comments
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${activityId}`
        );
        
        // Log the data we got for debugging
        console.log("Comments data:", response.data);
        
        // Process and sort comments
        const processedComments = response.data.map(comment => {
          // Use any existing information in the comment object
          return {
            ...comment,
            // Add default values for display purposes if needed
            stateText: comment.stateText || "General"
          };
        });
        
        // Sort comments by timestamp
        const sortedComments = processedComments.sort((a, b) => {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        });
        
        setAllComments(sortedComments);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching comments:", err);
        setAllComments([]);
        setLoading(false);
      }
    };

    if (activityId) {
      fetchAllComments();
    }
  }, [activityId]);

  // Delete comment function
  const handleDeleteComment = async (commentId, event) => {
    if (event) {
      event.stopPropagation(); // Keeping this to prevent any potential bubbling
    }
    
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${commentId}`, 
        {
          params: {
            userId: currentUserId,
            role: currentUser?.role || ''
          }
        }
      );
      // Update comments list after deletion
      setAllComments(allComments.filter(comment => comment._id !== commentId));
    } catch (err) {
      console.error("Error deleting comment:", err);
      if (err.response && err.response.status === 403) {
        alert("Permission denied: " + (err.response.data.error || "You can only delete your own comments"));
      }
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div className="card mb-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0">All Comments ({allComments.length})</h5>
      </div>
      <div className="card-body" style={{ maxHeight: "350px", overflowY: "auto" }}>
        {loading ? (
          <p>Loading comments...</p>
        ) : allComments.length === 0 ? (
          <p>No comments found for this HSM.</p>
        ) : (
          <div className="list-group">
            {allComments.map((comment) => (
              <div
                key={comment._id}
                className="list-group-item mb-2"
                style={{ borderRadius: "4px" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="mb-1">{comment.user} {comment.occupation ? `(${comment.occupation})` : ''}</h6>
                  <small>{formatDate(comment.timestamp)}</small>
                </div>
                <p className="mb-1">{comment.text}</p>
                <div className="d-flex justify-content-between align-items-center">
                  <small className="text-muted">
                    {comment.stateText || "General"}
                  </small>
                  
                  {(comment.userId === currentUserId || isAdmin) && (
                    <button 
                      className="btn btn-sm btn-outline-danger" 
                      onClick={(e) => handleDeleteComment(comment._id, e)}
                      aria-label="delete comment"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HSMCommentsAggregator; 