import React, { useEffect, useState } from "react";
import { db, collection, addDoc } from "../firebase";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { toast } from "react-toastify";

interface ValidationErrors {
  name?: string;
  description?: string;
  image?: string;
  videos?: string;
  measurement?: string;
  server?: string;
}

const imageUrl = [
  "./61ff3d3df7e387abdedc64e2.gif",
  "./61ff3d6ef7e387abdedc64e3.gif",
  "./61ff3d7ef7e387abdedc64e4.gif",
  "./61ff3d8af7e387abdedc64e6.gif",
  "./61ff3d8ff7e387abdedc64e7.gif",
  "./61ff3d84f7e387abdedc64e5.gif",
];

const EditAvatar = ({ show, onHide, oncomplete, userContext, avatarData }) => {
  const [selectedImage, setSelectedImage] = useState(avatarData?.image || imageUrl[0]);
  const [formData, setFormData] = useState<ValidationErrors>({
    name: avatarData?.title || "",
    description: avatarData?.paragraph || "",
    image: avatarData?.imageUrl || "",
    videos: avatarData?.videoUrl || avatarData?.video || "",
    measurement: avatarData?.measurements || "",
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, [isFormValid]);

  useEffect(() => {
    if (avatarData) {
      setFormData({
        name: avatarData.title || "",
        description: avatarData.paragraph || "",
        image: avatarData.imageUrl || "",
        videos: avatarData.videoUrl || avatarData.videos || "",
        measurement: avatarData.measurements || "",
      });
      setSelectedImage(avatarData.imageUrl || imageUrl[0]);
    }
  }, [avatarData]);

  useEffect(() => {
    const isValid =
      formData.name.trim() !== "" &&
      formData.description.trim() !== "" &&
      formData.image.trim() !== "" &&
      formData.videos.trim() !== "" &&
      formData.measurement.trim() !== "";
    setIsFormValid(isValid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCardClick = (url) => {
    setSelectedImage(url);
    setFormData((prevState) => ({
      ...prevState,
      image: url,
    }));
  };

  const isValidYouTubeUrl = (url) => {
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
  };

  const isValidUrl = (url) => {
    const urlRegex = /^(http|https):\/\/[^\s$.?#].[^\s]*$/;
    return urlRegex.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      toast.error("You must be logged in to edit an avatar");
      return;
    }

    const validationErrors: ValidationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!formData.description.trim()) {
      validationErrors.description = "Description is required";
    }
    if (!formData.image.trim()) {
      validationErrors.image = "Image is required";
    }
    if (!formData.videos.trim()) {
      validationErrors.videos = "Video URLs are required";
    } else {
      const videoUrls = formData.videos.split(",");
      for (const url of videoUrls) {
        if (!isValidYouTubeUrl(url.trim())) {
          validationErrors.videos =
            "Each video URL must be a valid YouTube URL";
          break;
        }
      }
    }
    if (!formData.measurement.trim()) {
      validationErrors.measurement = "Measurement details are required";
    } else if (!isValidUrl(formData.measurement.trim())) {
      validationErrors.measurement = "Measurement must be a valid URL";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const userData = JSON.parse(sessionStorage.getItem("user"));
    const accessToken = userData.access_token;
    
    try {
      // Use the correct endpoint that matches the backend implementation
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/avatar/${avatarData._id}`,
        {
          name: formData.name,
          description: formData.description,
          videos: formData.videos,
          image: formData.image,
          measurements: formData.measurement,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log("Avatar updated successfully:", response.data);
        await addDoc(collection(db, "notifications"), {
          text: `${userContext.name} updated avatar: ${formData.name}`,
          category: "update_avatar",
          targeted_user: "admin",
          createdby: userContext.name,
          avatarId: avatarData._id,
        });
        
        toast.success("Avatar updated successfully! Note: Your avatar will need to be re-approved by an administrator.");
        setErrors({});
        oncomplete();
      } else {
        console.error("Error updating avatar:", response.data.error);
        setErrors({ ...errors, server: response.data.error });
        toast.error("Failed to update avatar");
      }
    } catch (err) {
      console.error("Network error:", err);
      setErrors({
        ...errors,
        server: "Network error occurred. Please try again.",
      });
      toast.error("Network error occurred. Please try again.");
    }
  };

  return (
    <>
      {show && (
        <div className="fixed z-10 mt-4 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="relative bg-white w-[70vw] rounded-lg border-2 border-black">
              <div className="p-2">
                <div className="flex justify-between items-center border-b pb-2">
                  <h2 className="text-2xl font-bold">Edit Avatar</h2>
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={onHide}
                  >
                    Close
                  </button>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 text-white d-flex align-items-top justify-content-center">
                      <div>
                        <div className="container">
                          <div className="row">
                            {imageUrl.map((url, index) => (
                              <div key={index} className="col-md-4 mb-4">
                                <div
                                  className="card bg-light"
                                  style={{
                                    margin: "20px",
                                    cursor: "pointer",
                                    transition:
                                      "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                                    transform:
                                      selectedImage === url
                                        ? "scale(1.05)"
                                        : "scale(1)",
                                    boxShadow:
                                      selectedImage === url
                                        ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                                        : "none",
                                    borderColor:
                                      selectedImage === url ? "blue" : "gray",
                                    borderWidth: "2px",
                                    borderStyle: "solid",
                                  }}
                                  onClick={() => handleCardClick(url)}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.transform =
                                      "scale(1.05)";
                                    e.currentTarget.style.borderColor = "blue";
                                    e.currentTarget.style.boxShadow =
                                      "0 4px 8px rgba(0, 0, 0, 0.2)";
                                  }}
                                  onMouseLeave={(e) => {
                                    if (selectedImage !== url) {
                                      e.currentTarget.style.transform =
                                        "scale(1)";
                                      e.currentTarget.style.borderColor =
                                        "gray";
                                      e.currentTarget.style.boxShadow = "none";
                                    }
                                  }}
                                >
                                  <img
                                    src={url}
                                    className="card-img-top"
                                    alt="Card"
                                    style={{ width: "100%", height: "auto" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group my-3">
                          <label
                            htmlFor="name"
                            className="block text-gray-700 text-sm font-bold mb-2"
                          >
                            Name
                            <Tooltip title="Give your avatar a descriptive name">
                              <IconButton>
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                              errors.name ? "border-red-500" : ""
                            }`}
                            name="name"
                            id="name"
                            placeholder="Avatar name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                          {errors.name && (
                            <p className="text-red-500 text-xs italic">
                              {errors.name}
                            </p>
                          )}
                        </div>
                        <div className="form-group my-3">
                          <label
                            htmlFor="description"
                            className="block text-gray-700 text-sm font-bold mb-2"
                          >
                            Description
                            <Tooltip title="Describe your avatar's characteristics and other relevant details">
                              <IconButton>
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <textarea
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                              errors.description ? "border-red-500" : ""
                            }`}
                            name="description"
                            id="description"
                            placeholder="Avatar description"
                            value={formData.description}
                            onChange={handleChange}
                            rows={4}
                          ></textarea>
                          {errors.description && (
                            <p className="text-red-500 text-xs italic">
                              {errors.description}
                            </p>
                          )}
                        </div>
                        <div className="form-group my-3">
                          <label
                            htmlFor="videos"
                            className="block text-gray-700 text-sm font-bold mb-2"
                          >
                            YouTube Videos
                            <Tooltip title="Enter YouTube video URLs, separated by commas. These will showcase your avatar's mobility. See existing avatars for reference.">
                              <IconButton>
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                              errors.videos ? "border-red-500" : ""
                            }`}
                            name="videos"
                            id="videos"
                            placeholder="YouTube video URLs (comma-separated)"
                            value={formData.videos}
                            onChange={handleChange}
                          />
                          {errors.videos && (
                            <p className="text-red-500 text-xs italic">
                              {errors.videos}
                            </p>
                          )}
                        </div>
                        <div className="form-group my-3">
                          <label
                            htmlFor="measurement"
                            className="block text-gray-700 text-sm font-bold mb-2"
                          >
                            Measurement URL
                            <Tooltip title="Provide a drive/video link to detailed measurements (ROM/MMT) of your avatar. See existing avatars for reference.">
                              <IconButton>
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </label>
                          <input
                            type="text"
                            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                              errors.measurement ? "border-red-500" : ""
                            }`}
                            name="measurement"
                            id="measurement"
                            placeholder="URL to detailed measurements"
                            value={formData.measurement}
                            onChange={handleChange}
                          />
                          {errors.measurement && (
                            <p className="text-red-500 text-xs italic">
                              {errors.measurement}
                            </p>
                          )}
                        </div>
                        {errors.server && (
                          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-3">
                            <strong className="font-bold">Error! </strong>
                            <span className="block sm:inline">
                              {errors.server}
                            </span>
                          </div>
                        )}
                        <div className="form-group mt-4 mb-2">
                          <button
                            type="submit"
                            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                              !isFormValid ? "opacity-50 cursor-not-allowed" : ""
                            }`}
                            disabled={!isFormValid}
                          >
                            Update Avatar
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditAvatar; 