import { useState, useEffect } from "react";
import { CenteredSpinner } from "../components/Spinner";
import { UserContext } from "../utils/interface/AppInterface";
import SpacsBox from "../components/HeroSection";
import AdminAvatars from "../components/AdminAvatars";
import AdminUsers from "../components/AdminUsers";
import AdminWorkflow from "../components/AdminWorkflow";
import AdminCredits from "../components/AdminCredits";

export type NavigationBarProps = {
  userContext: UserContext;
  setUserContext: (x: any) => void;
  handleButtonClick: (id: number) => void;
};

export const Admin = ({
  userContext,
  setCursor,
  activeButton,
  handleButtonClick,
}) => {
  const loggedIn = userContext !== undefined;
  const isSuperAdmin = userContext && userContext.role === "superadmin";

  const [avatarfilter, setavatarfilter] = useState("");
  
  // Redirect regular admins from Users or Credits page to Avatars
  useEffect(() => {
    if (userContext && !isSuperAdmin && (activeButton === 1 || activeButton === 4)) {
      handleButtonClick(2); // Redirect to Avatars page
    }
  }, [userContext, activeButton, isSuperAdmin, handleButtonClick]);

  const handleAvatarFilter = (filter) => {
    setavatarfilter(filter);
  };

  const buttons = [
    { id: 1, label: "Users", content: "Users info" },
    { id: 2, label: "All avatars", content: "Review Avatars" },
    { id: 3, label: "Worflows", content: "Review Workflows" },
    { id: 4, label: "Credits", content: "Users Credits" },
  ];

  return !activeButton ? (
    <CenteredSpinner />
  ) : (
    <div className="col mt-10">
      <div className="container-md mt-10">
        <div className="row">
          <SpacsBox heading="" buttonTitle="Admin" />
        </div>
      </div>

      <div className="container border-8">
        <div className="row justify-content-center align-items-center">
          <h2 className="font-weight-bold text-center mt-3">
            {buttons.find((button) => button.id === activeButton)?.content}
          </h2>
        </div>
        <div className="mt-4">
          {activeButton === 1 && isSuperAdmin ? (
            <AdminUsers
              userContext={userContext}
              setCursor={setCursor}
              handleAvatarFilter={handleAvatarFilter}
              handleButtonClick={handleButtonClick}
            />
          ) : null}
          {activeButton === 2 ? (
            <AdminAvatars
              userContext={userContext}
              activeButton={activeButton}
              filterid={avatarfilter}
              setavatarfilter={setavatarfilter}
            />
          ) : null}
          {activeButton === 3 ? <AdminWorkflow /> : null}
          {activeButton === 4 && isSuperAdmin ? (
            <AdminCredits
              userContext={userContext}
              setCursor={setCursor}
              handleAvatarFilter={handleAvatarFilter}
              handleButtonClick={handleButtonClick}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Admin;
