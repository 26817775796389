import React, { useState, useEffect } from "react";
import axios from "axios";

export type HSMPapersAggregatorProps = {
  activityId: string;
};

const HSMPapersAggregator = ({
  activityId
}: HSMPapersAggregatorProps) => {
  const [allPapers, setAllPapers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get current user info for permissions
  const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
  const isAdmin = currentUser?.role === "admin" || currentUser?.role === "superadmin";
  const currentUserId = currentUser?.id || "";

  // Fetch all papers for this HSM
  useEffect(() => {
    const fetchAllPapers = async () => {
      try {
        setLoading(true);
        
        // Fetch papers
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URI}/papers/${activityId}`
        );
        
        // Log the data we got for debugging
        console.log("Papers data:", response.data);
        
        // Process and sort papers
        const processedPapers = response.data.map(paper => {
          // Use any existing information in the paper object
          return {
            ...paper,
            // Add default values for display purposes if needed
            stateText: paper.stateText || "General"
          };
        });
        
        // Sort papers by timestamp (newest first)
        const sortedPapers = processedPapers.sort((a, b) => {
          return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        });
        
        setAllPapers(sortedPapers);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching papers:", err);
        setAllPapers([]);
        setLoading(false);
      }
    };

    if (activityId) {
      fetchAllPapers();
    }
  }, [activityId]);

  // Delete paper function
  const handleDeletePaper = async (paperId, event) => {
    if (event) {
      event.stopPropagation();
    }
    
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/papers/${paperId}`, 
        {
          params: {
            userId: currentUserId,
            role: currentUser?.role || ''
          }
        }
      );
      // Update papers list after deletion
      setAllPapers(allPapers.filter(paper => paper._id !== paperId));
    } catch (err) {
      console.error("Error deleting paper:", err);
      if (err.response && err.response.status === 403) {
        alert("Permission denied: " + (err.response.data.error || "You can only delete your own papers"));
      }
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div className="card mb-4">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0">All Papers ({allPapers.length})</h5>
      </div>
      <div className="card-body" style={{ maxHeight: "350px", overflowY: "auto" }}>
        {loading ? (
          <p>Loading papers...</p>
        ) : allPapers.length === 0 ? (
          <p>No papers found for this HSM.</p>
        ) : (
          <div className="list-group">
            {allPapers.map((paper) => (
              <div
                key={paper._id}
                className="list-group-item mb-2"
                style={{ borderRadius: "4px" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="mb-1">{paper.title}</h6>
                  <small>{formatDate(paper.timestamp)}</small>
                </div>
                <p className="mb-1">
                  <a href={paper.url} target="_blank" rel="noopener noreferrer" className="text-primary">
                    {paper.url}
                  </a>
                </p>
                {paper.modelInfo && <p className="mb-1 small text-muted">Model Info: {paper.modelInfo}</p>}
                <div className="d-flex justify-content-between align-items-center">
                  <small className="text-muted">
                    Posted by: {paper.user} • State: {paper.stateText || "General"}
                  </small>
                  
                  {(paper.userId === currentUserId || isAdmin) && (
                    <button 
                      className="btn btn-sm btn-outline-danger" 
                      onClick={(e) => handleDeletePaper(paper._id, e)}
                      aria-label="delete paper"
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HSMPapersAggregator; 