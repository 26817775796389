import React, { useEffect, useState } from "react";
import { UserContext, UserOccupation } from "../utils/interface/AppInterface";
import { signup } from "../services/UserSignupService";
import { AxiosError } from "axios";

interface ValidationErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  experience?: string;
  robotsExperience?: string;
  occupation?: string;
  otherOccupation?: string;
  affiliation?: string;
  agree?: string;
}

const SignupPage = ({
  user,
  setUser,
}: {
  user: UserContext;
  setUser: (user: UserContext) => void;
}) => {
  const [formData, setFormData] = useState<ValidationErrors>({
    firstName: "",
    lastName: "",
    email: "",
    experience: "",
    robotsExperience: "",
    occupation: "",
    otherOccupation: "",
    affiliation: "",
    agree: "",
  });

  const [errors, setErrors] = useState<ValidationErrors>({});
  const [selectedOccupation, setSelectedOccupation] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (user) {
      const nameParts = user.name.split(" ");
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(" ");
      setFormData((prevState) => ({
        ...prevState,
        firstName,
        lastName,
        email: user.email,
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "occupation") {
      setSelectedOccupation(value);
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors: ValidationErrors = {};
    if (!formData.firstName.trim()) {
      validationErrors.firstName = "First name is required";
    }
    if (!formData.lastName.trim()) {
      validationErrors.lastName = "Last name is required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email address";
    }
    if (!formData.experience.trim()) {
      validationErrors.experience = "Years of experience is required";
    }
    if (!formData.affiliation.trim()) {
      validationErrors.affiliation = "Affiliation is required";
    }
    if (
      selectedOccupation === UserOccupation.ROBOTICIST &&
      !formData.robotsExperience.trim()
    ) {
      validationErrors.robotsExperience = "Experience with robots is required";
    }
    if (
      selectedOccupation === UserOccupation.OTHER &&
      !formData.otherOccupation.trim()
    ) {
      validationErrors.otherOccupation = "Please specify your occupation";
    }
    if (!formData.agree) {
      validationErrors.agree = "You must agree to the terms and conditions";
    }
    if (!formData.occupation) {
      validationErrors.occupation = "Occupation is required";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const handleAuthError = (err: AxiosError) => {
        // TODO handle auth error
      };
      
      // Prepare occupation value - if OTHER is selected, use the custom text
      const occupationValue = selectedOccupation === UserOccupation.OTHER 
        ? formData.otherOccupation 
        : formData.occupation;
        
      let updatedUser = await signup(
        user,
        formData.firstName + " " + formData.lastName,
        formData.experience,
        formData.robotsExperience,
        occupationValue,
        formData.affiliation,
        handleAuthError
      );
      updatedUser = { ...updatedUser, access_token: user.access_token };
      setUser(updatedUser);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className=" col-md-6  text-white d-flex align-items-top justify-content-center">
          <div>
            <img src="/Signup.svg" alt="" />
          </div>
        </div>
        <div className="col-md-4 align-items-center justify-content-center">
          <div>
            <div className="flex justify-between items-center mb-4 ">
              <h2 className="text-2xl font-bold  ">Create an Account</h2>
              <button className=" text-black font-bold   px-2 rounded-full border-gray-100 border-2 text-xs  ">
                Welcome
              </button>
            </div>

            <form onSubmit={handleSubmit} className="max-w mx-auto">
              <div className="mb-4">
                <label
                  htmlFor="firstName"
                  className="block text-xs font-medium text-gray-400"
                >
                  First name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                  required
                />
                {errors.firstName && (
                  <small className="text-red-500">{errors.firstName}</small>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="block text-xs font-medium text-gray-400"
                >
                  Last name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                  required
                />
                {errors.lastName && (
                  <small className="text-red-500">{errors.lastName}</small>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-xs font-medium text-gray-400"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                  required
                  disabled
                />
                {errors.email && (
                  <small className="text-red-500">{errors.email}</small>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="experience"
                  className="block text-xs font-medium text-gray-400"
                >
                  Years of Experience
                </label>
                <input
                  type="text"
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                  required
                />
                {errors.experience && (
                  <small className="text-red-500">{errors.experience}</small>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="affiliation"
                  className="block text-xs font-medium text-gray-400"
                >
                  Affiliation
                </label>
                <input
                  type="text"
                  name="affiliation"
                  value={formData.affiliation}
                  onChange={handleChange}
                  className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                  required
                />
                {errors.affiliation && (
                  <small className="text-red-500">{errors.affiliation}</small>
                )}
              </div>
              {selectedOccupation === UserOccupation.ROBOTICIST && (
                <div className="mb-4">
                  <label
                    htmlFor="robotsExperience"
                    className="block text-xs font-medium text-gray-400"
                  >
                    Experience with robots
                  </label>
                  <input
                    type="text"
                    name="robotsExperience"
                    value={formData.robotsExperience}
                    onChange={handleChange}
                    className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                    required
                  />
                  {errors.robotsExperience && (
                    <small className="text-red-500">
                      {errors.robotsExperience}
                    </small>
                  )}
                </div>
              )}
              <div className="mb-4">
                <label className="block text-xs font-medium text-gray-400">
                  Occupation
                </label>
                <div className="mb-3">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="occupation"
                      id="clinician"
                      value={UserOccupation.CLINICIAN}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="clinician">
                      Clinician
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="occupation"
                      id="roboticist"
                      value={UserOccupation.ROBOTICIST}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="roboticist">
                      Roboticist
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="occupation"
                      id="other"
                      value={UserOccupation.OTHER}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="other">
                      Other
                    </label>
                  </div>
                  {errors.occupation && (
                    <small className="text-red-500">{errors.occupation}</small>
                  )}
                </div>
              </div>
              {selectedOccupation === UserOccupation.OTHER && (
                <div className="mb-4">
                  <label
                    htmlFor="otherOccupation"
                    className="block text-xs font-medium text-gray-400"
                  >
                    Please specify your occupation
                  </label>
                  <input
                    type="text"
                    name="otherOccupation"
                    value={formData.otherOccupation}
                    onChange={handleChange}
                    className="form-input mt-1 py-2 block w-full border border-gray-100 rounded-md"
                    required
                  />
                  {errors.otherOccupation && (
                    <small className="text-red-500">
                      {errors.otherOccupation}
                    </small>
                  )}
                </div>
              )}
              <div className="mb-4 flex items-center">
                <input
                  type="checkbox"
                  name="agree"
                  checked={!!formData.agree}
                  onChange={handleChange}
                  className="form-checkbox h-4 w-4 text-gray-900 border-gray-300 rounded-md"
                  id="agree"
                  required
                />
                <label htmlFor="agree" className="ml-2 text-sm text-gray-900">
                  By creating an account, I agree to the{" "}
                  <a href="/terms" className="text-indigo-600">
                    terms of use
                  </a>{" "}
                  and{" "}
                  <a href="/privacy-policy" className="text-indigo-600">
                    privacy policy
                  </a>
                  .
                </label>
                {errors.agree && (
                  <small className="text-red-500">{errors.agree}</small>
                )}
              </div>
              <button
                type="submit"
                className="w-full bg-gray-400 text-white py-2 px-4 rounded-full"
              >
                Create Account
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
