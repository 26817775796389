import React, { useEffect, useState } from "react";
import {
  EnhancedTableHeadProps,
  WorkflowProps,
} from "../utils/interface/ADLSPage";

function ADLSPageTableHead(props: EnhancedTableHeadProps) {
  const { order, orderBy, onRequestSort } = props;
  const [userContext, setUserContext] = useState(null);

  useEffect(() => {
    const userContextData = sessionStorage.getItem("user");
    if (userContextData) {
      setUserContext(JSON.parse(userContextData));
    }
  }, []);

  const createSortHandler =
    (property: keyof WorkflowProps) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <thead className="bg-gray-50 border-b border-gray-200">
      <tr>
        {props.headCells.map((headCell) => {
          if (!userContext && headCell.alwaysVisible === false) {
            return null;
          }
          return (
            <th
              key={headCell.id}
              className="px-4 py-3 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider"
            >
              {headCell.label}
            </th>
          );
        })}
        <th className="px-4 py-3 text-center text-sm font-semibold text-gray-700 uppercase tracking-wider">Actions</th>
      </tr>
    </thead>
  );
}

export default ADLSPageTableHead;
