import React from "react";

const ADLSRowcount = ({ value, onChange }) => {
  const handleRowsPerPageChange = (e) => {
    onChange(Number(e.target.value));
  };

  return (
    <div className="flex items-center space-x-2">
      <label className="text-sm font-medium text-gray-600">Show</label>
      <select
        value={value}
        onChange={handleRowsPerPageChange}
        className="bg-white border border-gray-300 text-gray-700 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <option value={5}>5</option>
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
      </select>
      <span className="text-sm font-medium text-gray-600">entries</span>
    </div>
  );
};

export default ADLSRowcount;
