import { styled, TableCell } from "@mui/material";
import { HeadCell, Order } from "./interface/ADLSPage";

export const headCells: readonly HeadCell[] = [
  {
    id: "count",
    disablePadding: false,
    label: "#",
    alwaysVisible: true
  },
  {
    id: "name",
    disablePadding: false,
    label: "Name",
    alwaysVisible: true
  },
  {
    id: "caregiver",
    disablePadding: false,
    label: "Primary Caregiver",
    alwaysVisible: true
  },
  {
    id: "category",
    disablePadding: false,
    label: "ADL",
    alwaysVisible: true
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    alwaysVisible: false
  },
  {
    id: "reviewStatus",
    disablePadding: false,
    label: "Review Status",
    alwaysVisible: false
  },
];

export const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "30px",
});

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // Special case for reviewStatus - use custom priority order
  if (orderBy === 'reviewStatus' as any) {
    const getReviewStatusPriority = (status) => {
      if (!status || status === "Approved") return 0;
      if (status === "Reviewed") return 1;
      if (status === "Pending") return 2;
      return 3; // any other status
    };
    
    const aStatus = (a as any).reviewStatus;
    const bStatus = (b as any).reviewStatus;
    
    return getReviewStatusPriority(bStatus) - getReviewStatusPriority(aStatus);
  }
  
  // Standard comparison for other columns
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  // Helper function to get review status priority
  const getReviewStatusPriority = (status) => {
    if (!status || status === "Approved") return 0;
    if (status === "Reviewed") return 1;
    if (status === "Pending") return 2;
    return 3; // any other status
  };
  
  return order === "desc"
    ? (a, b) => {
        // First compare by the selected column
        const result = descendingComparator(a, b, orderBy);
        // If they are equal, use review status as a secondary sort
        if (result === 0 && orderBy !== 'reviewStatus') {
          const aStatus = (a as any).reviewStatus;
          const bStatus = (b as any).reviewStatus;
          if (aStatus && bStatus) {
            return getReviewStatusPriority(aStatus) - getReviewStatusPriority(bStatus);
          }
        }
        return result;
      }
    : (a, b) => {
        // First compare by the selected column
        const result = -descendingComparator(a, b, orderBy);
        // If they are equal, use review status as a secondary sort
        if (result === 0 && orderBy !== 'reviewStatus') {
          const aStatus = (a as any).reviewStatus;
          const bStatus = (b as any).reviewStatus;
          if (aStatus && bStatus) {
            return getReviewStatusPriority(aStatus) - getReviewStatusPriority(bStatus);
          }
        }
        return result;
      };
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
