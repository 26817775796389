import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { MdOutlineAddComment } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { BsFillCameraReelsFill } from "react-icons/bs";
import { RiImageAddFill } from "react-icons/ri";
import { StateToolsProps } from "../utils/interface/HSM";
import { v4 as uuidv4 } from "uuid";

const HSMStateTools = ({
  onClickComments,
  onClickPapers,
  onClickTrash,
  onClickConditions,
  type,
  editing,
  setCursorAuto,
  setCursorPointer,
  isHumanMode,
  invis,
  onImageFileSelected,
  onVideoUrlEntered,
}: StateToolsProps) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const userVar = window.sessionStorage?.getItem("user");
  let buttons = [];

  const handleImageSelection = () => {
    if (selectedImage) {
      onImageFileSelected(selectedImage);
    }
    setShowImageModal(false);
  };

  const handleVideoUrlSubmit = () => {
    if(userVar){
      if (videoUrl) {
        // Add basic URL validation
        if (!videoUrl.startsWith('http://') && !videoUrl.startsWith('https://')) {
          alert('Please enter a valid URL beginning with http:// or https://');
          return;
        }
        
        // Check if it's a YouTube URL and extract just the video ID if needed
        let processedUrl = videoUrl;
        if (videoUrl.includes('youtube.com/watch?v=')) {
          const videoId = new URLSearchParams(new URL(videoUrl).search).get('v');
          if (videoId) {
            // You could use the YouTube embed URL format if preferred
            // processedUrl = `https://www.youtube.com/embed/${videoId}`;
            processedUrl = videoUrl; // Keep the original URL
          }
        }
        
        onVideoUrlEntered(processedUrl);
        console.log("Video URL submitted:", processedUrl);
      }
      setShowVideoModal(false);
      setVideoUrl(""); // Reset the input field after submission
    }
    else{
      alert("Please log in to add videos.");
      setShowVideoModal(false);
    }
  };

  const CommentIcon = () => (
    <span
      onMouseEnter={setCursorPointer}
      onMouseLeave={setCursorAuto}
      key="comment"
      className="comments-icon tool-button"
    >
      <MdOutlineAddComment onClick={() => {
          if (userVar) {
            console.log("Comment icon clicked, calling onClickComments for state type:", type);
            if (onClickComments) {
              onClickComments();
            } else {
              console.error("onClickComments callback is undefined for state type:", type);
            }
          } else {
            alert("Please log in to view and add comments.");
          }
        }} size={24} />
    </span>
  );

  const PaperIcon = () => (
    <span
      onMouseEnter={setCursorPointer}
      onMouseLeave={setCursorAuto}
      key="paper"
      className="papers-icon tool-button"
    >
      <CgFileDocument onClick={() => {
          if (userVar) {
            console.log("Paper icon clicked, calling onClickPapers for state type:", type);
            if (onClickPapers) {
              onClickPapers();
            } else {
              console.error("onClickPapers callback is undefined for state type:", type);
            }
          } else {
            alert("Please log in to view and add research papers.");
          }
        }} size={24} />
    </span>
  );

  const Videolink = () => (
    <span 
      onMouseEnter={setCursorPointer} 
      onMouseLeave={setCursorAuto}
      key="video"
      className="tool-button"
    >
      <BsFillCameraReelsFill
        onClick={() => {
          if (userVar) {
            setShowVideoModal(true);
          } else {
            alert("Please log in to add a video.");
            
          }
        }}
        size={24}
      />
    </span>
  );

  const Imagelink = () => (
    <span 
      onMouseEnter={setCursorPointer} 
      onMouseLeave={setCursorAuto}
      key="image"
      className="tool-button"
    >
      <RiImageAddFill onClick={() => {
          if (userVar) {
            setShowImageModal(true);
          } else {
            alert("Please log in to add an image.");
          }
        }} size={24} />
    </span>
  );

  const TrashIcon = ({ onClick }: any) => (
    <span
      onMouseEnter={setCursorPointer}
      onMouseLeave={setCursorAuto}
      key="trash"
      className="tool-button"
    >
      <svg
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-3 -2 24 24"
        width="24"
        fill="currentColor"
      >
        <path d="M6 2V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h4a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-.133l-.68 10.2a3 3 0 0 1-2.993 2.8H5.826a3 3 0 0 1-2.993-2.796L2.137 7H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4zm10 2H2v1h14V4zM4.141 7l.687 10.068a1 1 0 0 0 .998.932h6.368a1 1 0 0 0 .998-.934L13.862 7h-9.72zM7 8a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0V9a1 1 0 0 1 1-1z"></path>
      </svg>
    </span>
  );

  const ConditionsIcon = ({ onClick }: any) => (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-2 -2 24 24"
      width="24"
      fill="currentColor"
      key="conditions"
      className="tool-button"
    >
      <path d="M6 0h8a6 6 0 0 1 6 6v8a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6zm0 2a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6zm6 7h3a1 1 0 0 1 0 2h-3a1 1 0 0 1 0-2zm-2 4h5a1 1 0 0 1 0 2h-5a1 1 0 0 1 0-2zm0-8h5a1 1 0 0 1 0 2h-5a1 1 0 1 1 0-2zm-4.172 5.243L7.95 8.12a1 1 0 1 1 1.414 1.415l-2.828 2.828a1 1 0 0 1-1.415 0L3.707 10.95a1 1 0 0 1 1.414-1.414l.707.707z"></path>
    </svg>
  );

  // Always add these icons regardless of state type with index-based keys
  buttons.push(<Imagelink key="tool-image" />);
  buttons.push(<Videolink key="tool-video" />);
  buttons.push(<CommentIcon key="tool-comment" />);
  buttons.push(<PaperIcon key="tool-paper" />);
  
  // Add conditions icon only for tasks
  if (!isHumanMode && type === "task") {
    buttons.push(<ConditionsIcon key="tool-conditions" onClick={onClickConditions} />);
  }

  // We're intentionally not using onClickDeeper to prevent users 
  // from clicking into state cards. This function is passed as a prop
  // but we don't use it here to disable the state card click navigation.

  if (editing) {
    buttons.push(<TrashIcon key="tool-trash" onClick={onClickTrash} />);
  }

  const invisible = buttons.length === 0;
  if (invisible) {
    buttons.push(<CommentIcon key="tool-comment-fallback" />);
  }

  const className = invis || invisible ? "tools invis" : "tools";

  return (
    <>
      <div
        className={className}
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {buttons}
      </div>

      <Modal show={showVideoModal} onHide={() => setShowVideoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Video Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Video Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter video link"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowVideoModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleVideoUrlSubmit()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="file"
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              const files = target.files;
              if (files && files.length > 0) {
                setSelectedImage(files[0]);
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleImageSelection}>
            Upload Image
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HSMStateTools;
