import React from "react";
import { Box, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SmartToyIcon from "@mui/icons-material/SmartToy";

export const SelectCaregiver = ({
  humanSelected,
  setHumanSelected,
}: {
  humanSelected: boolean;
  setHumanSelected: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Box 
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
        borderRadius: "32px",
        padding: "4px",
        backgroundColor: "#f0f0f0",
        boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
        maxWidth: "400px",
        margin: "0 auto"
      }}
    >
      <CaregiverButton 
        id="btnHuman"
        label="Human caregiver"
        isSelected={humanSelected}
        icon={<PersonIcon />}
        onClick={() => setHumanSelected(true)}
      />
      <CaregiverButton 
        id="btnRobot"
        label="Robot caregiver"
        isSelected={!humanSelected}
        icon={<SmartToyIcon />}
        onClick={() => setHumanSelected(false)}
      />
    </Box>
  );
};

interface CaregiverButtonProps {
  id: string;
  label: string;
  isSelected: boolean;
  icon: React.ReactNode;
  onClick: () => void;
}

const CaregiverButton = ({ id, label, isSelected, icon, onClick }: CaregiverButtonProps) => {
  return (
    <Box
      component="button"
      id={id}
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        padding: "10px 20px",
        borderRadius: "28px",
        border: "none",
        cursor: "pointer",
        transition: "all 0.2s ease",
        flexGrow: 1,
        fontWeight: "medium",
        backgroundColor: isSelected ? "#ffffff" : "transparent",
        color: isSelected ? "#333333" : "#666666",
        boxShadow: isSelected ? "0 2px 4px rgba(0,0,0,0.1)" : "none",
        "&:hover": {
          backgroundColor: isSelected ? "#ffffff" : "rgba(0,0,0,0.05)",
        },
        "&:focus": {
          outline: "none",
          boxShadow: isSelected 
            ? "0 2px 4px rgba(0,0,0,0.1), 0 0 0 2px rgba(66, 153, 225, 0.3)" 
            : "0 0 0 2px rgba(66, 153, 225, 0.3)",
        }
      }}
    >
      {icon}
      <Typography 
        component="span"
        sx={{
          fontWeight: isSelected ? 600 : 500,
          fontSize: "15px"
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
