import React, { useEffect } from "react";
import AdminNavButton from "../components/AdminNavButton";
import NotificationComponent from "../components/NotificationComponent";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const AdminNavbar = ({
  userContext,
  setUserContext,
  activeButton,
  handleButtonClick,
}) => {
  const loggedIn = userContext !== undefined;
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const user = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/auth/google`,
        {
          code,
        }
      );

      setUserContext(user.data);
      localStorage.setItem("refreshToken", user.data.refresh_token); // Store the refresh token

      if (!user.data.occupation) {
        navigate("signup", { replace: true });
      }
    },
    flow: "auth-code",
  });

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) return;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/auth/google/refresh-token`,
        {
          refreshToken,
        }
      );

      const newuser = {
        ...userContext,
        access_token: response.data.access_token,
      };

      setUserContext(newuser);
    } catch (err) {
      console.error("Failed to refresh access token", err);
    }
  };

  useEffect(() => {
    if (userContext) {
      const intervalId = setInterval(refreshAccessToken, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [userContext]);

  // Define buttons array conditionally based on user role
  const isSuperAdmin = userContext && userContext.role === "superadmin";
  
  // Create buttons array with or without the Users button based on role
  const buttons = [
    ...(isSuperAdmin ? [{ id: 1, label: "Users" }] : []),
    { id: 2, label: "All avatars" },
    { id: 3, label: "Workflows" },
    ...(isSuperAdmin ? [{ id: 4, label: "Credits" }] : []),
  ];

  return (
    <div
      className="d-flex justify-content-center align-items-center mt-10"
      style={{ marginTop: "-50px" }}
    >
      <div
        className="d-inline-block"
        style={{
          backgroundColor: "black",
          padding: "5px",
          borderRadius: "50px",
        }}
      >
        {buttons.map((button) => (
          <button
            key={button.id}
            className="btn custom-button"
            style={{
              backgroundColor:
                activeButton === button.id ? "white" : "transparent",
              color: activeButton === button.id ? "black" : "white",
              padding: "10px 20px",
              margin: "5px",
              borderRadius: "30px",
              border: "none",
            }}
            onClick={() => handleButtonClick(button.id)}
          >
            {button.label}
          </button>
        ))}
        <div
          className="btn custom-button"
          style={{
            backgroundColor: activeButton === 7 ? "white" : "transparent",
            color: activeButton === 7 ? "black" : "white",
            padding: "10px 20px",
            margin: "5px",
            borderRadius: "30px",
            border: "none",
          }}
        >
          {loggedIn ? (
            <AdminNavButton
              userContext={userContext}
              setUserContext={setUserContext}
              handleButtonClick={(id) => handleButtonClick(id)}
            />
          ) : (
            <div className="flex  " style={{ paddingLeft: "1rem" }}>
              <Link
                to="."
                className="btn btn-dark  link-light "
                style={{
                  width: "80px",
                  marginRight: "20px",
                  height: "30px",
                  padding: "0px",
                }}
                onClick={login}
              >
                Sign in
              </Link>
            </div>
          )}
        </div>
      </div>
      {!userContext ? (
        ""
      ) : (
        <div className="ml-3">
          <NotificationComponent userContext={userContext} />
        </div>
      )}
    </div>
  );
};

export default AdminNavbar;
