import Accordion from "react-bootstrap/Accordion";
import { BuildingBlockTableContainerProps } from "../utils/interface/HSM";
import { HUMAN_HEADERS, ROBOTS_HEADERS } from "../utils/HSMUtils";
import { BuildingBlockTable } from "./BuildingBlockTable";
import { Box, Typography, createStyles } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import "./BuildingBlockStyles.css";

export function BuildingBlockTableContainer({
  activityProps,
  setActivityProps,
  setIsUnsavedChanges,
  isEditable,
  isExpand,
  isHumanMode,
}: BuildingBlockTableContainerProps) {
  const headers = isHumanMode ? HUMAN_HEADERS : ROBOTS_HEADERS;
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(isExpand);
  const accordionRef = useRef<HTMLDivElement>(null);

  // Calculate completion status
  const calculateCompletionStatus = () => {
    // Add null check and default for buildingBlocks
    const buildingBlocks = activityProps?.buildingBlocks || {
      userFunctionality: "",
      userBehavior: "",
      environmentModel: "",
      caregiverFunctionality: "",
      caregiverBehavior: "",
      robotModel: ""
    };
    
    // Determine which models to consider based on mode
    let relevantValues = [];
    
    if (isHumanMode) {
      // In human mode, use all except robot model
      relevantValues = [
        buildingBlocks.userFunctionality || "",
        buildingBlocks.userBehavior || "",
        buildingBlocks.environmentModel || "",
        buildingBlocks.caregiverFunctionality || "",
        buildingBlocks.caregiverBehavior || ""
      ];
    } else {
      // In robot mode, use all models
      relevantValues = Object.values(buildingBlocks).map(val => val || "");
    }
    
    const definedCount = relevantValues.filter(value => value !== "" && value !== "Undefined").length;
    const totalCount = relevantValues.length;
    
    return {
      definedCount,
      totalCount,
      percentage: totalCount > 0 ? Math.round((definedCount / totalCount) * 100) : 0
    };
  };

  // Safely calculate completion with error handling
  let completion = { definedCount: 0, totalCount: 1, percentage: 0 };
  try {
    completion = calculateCompletionStatus();
  } catch (error) {
    console.error("Error calculating building blocks completion status:", error);
  }

  // Handle accordion toggle
  const handleAccordionToggle = (eventKey: string | null) => {
    setIsAccordionOpen(eventKey === "0");
  };

  // Use effect to hide the default model list that follows
  useEffect(() => {
    // This is a direct approach to hide the model list
    // We'll look for elements after our component
    const tryHideElements = () => {
      const mainElement = accordionRef.current?.parentElement;
      if (!mainElement) return;
      
      const parentElement = mainElement.parentElement;
      if (!parentElement) return;
      
      // Direct siblings that follow our container
      let currentSibling = mainElement.nextElementSibling;
      while (currentSibling) {
        if (currentSibling instanceof HTMLElement) {
          // This specifically hides the entire model list
          currentSibling.style.display = 'none';
        }
        currentSibling = currentSibling.nextElementSibling;
      }
    };

    // Run immediately
    tryHideElements();
    
    // Also run after a small delay to ensure DOM is fully updated
    const timeoutId = setTimeout(tryHideElements, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="col-10 offset-1">
      <div ref={accordionRef}>
        <Accordion 
          defaultActiveKey={isExpand ? "0" : undefined}
          onSelect={handleAccordionToggle}
          className="building-blocks-accordion mt-3 mb-4"
        >
          <Accordion.Item
            eventKey="0"
            style={{
              border: "none",
              borderRadius: "12px",
              boxShadow: "0 3px 6px rgba(0,0,0,0.08)",
              overflow: "hidden",
            }}
          >
            <Accordion.Header
              className="d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: "#f8f9fa",
                borderRadius: "12px 12px 0 0",
                border: "none",
                paddingRight: "16px"
              }}
            >
              <Box sx={{ 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "space-between",
                width: "100%",
                padding: "0.75rem 0rem",
              }}>
                <Typography 
                  component="h3" 
                  sx={{ 
                    fontWeight: "700", 
                    fontSize: "20px", 
                    color: "#2c3e50",
                    letterSpacing: "-0.5px"
                  }}
                >
                  Building Blocks
                </Typography>
                
                <Box sx={{ 
                  display: "flex", 
                  alignItems: "center", 
                  gap: 2,
                  marginRight: "20px"
                }}>
                  <Box sx={{ 
                    display: "flex", 
                    alignItems: "center", 
                    backgroundColor: "#e3f2fd", 
                    padding: "6px 14px",
                    borderRadius: "20px",
                    border: "1px solid #bbdefb"
                  }}>
                    <Typography component="span" sx={{ 
                      fontSize: "14px", 
                      fontWeight: "600",
                      color: "#1976d2"
                    }}>
                      {completion.definedCount}/{completion.totalCount} defined
                    </Typography>
                  </Box>
                  
                  <Box sx={{ 
                    height: "10px", 
                    width: "140px", 
                    backgroundColor: "#e0e0e0",
                    borderRadius: "5px",
                    overflow: "hidden"
                  }}>
                    <Box sx={{ 
                      height: "100%", 
                      width: `${completion.percentage}%`,
                      backgroundColor: completion.percentage === 100 
                        ? "#4caf50" 
                        : completion.percentage > 50 
                          ? "#ff9800" 
                          : "#f44336",
                      transition: "width 0.3s ease-in-out"
                    }} />
                  </Box>
                  
                  <Typography component="span" sx={{ 
                    fontSize: "14px", 
                    fontWeight: "500",
                    color: "#555",
                    minWidth: "40px"
                  }}>
                    {completion.percentage}%
                  </Typography>
                </Box>
              </Box>
            </Accordion.Header>
            <Accordion.Body style={{
              padding: "0px",
              backgroundColor: "#fbfbfb",
              borderRadius: "0 0 12px 12px",
            }}>
              <BuildingBlockTable
                headers={headers}
                isEditable={isEditable}
                isHumanMode={isHumanMode}
                activityProps={activityProps}
                setActivityProps={setActivityProps}
                setIsUnsavedChanges={setIsUnsavedChanges}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}
