import React, { useState, useEffect, useRef } from "react";
import { CommentsSidebar, PapersSidebar } from "../components/Sidebar";
import {
  CommentsSidebarProps,
  PapersSidebarProps,
} from "../components/Sidebar";
import rightArrow from "../imgs/arrow.png";
import {
  getPropsOfState,
  addComment,
  addPaper,
  collectCommentsOneLevelDeep,
  collectPapersOneLevelDeep,
  updateTitleOfActivity,
} from "../utils/stateUtils";
import {
  BCNavProps,
  CommentProps,
  HSMRendererProps,
  PaperProps,
  StateBoxProps,
  TitleInputProps,
} from "../utils/interface/HSM";
import BCNav from "../components/BreadCrumbNav";
import Legend from "../components/HSMLegend";
import { BuildingBlockTableContainer } from "../components/BuildingBlockTableContainer";
import HSMTitleInput from "../components/HSMTitleInput";
import HSMContainer from "../components/HSMContainer";
import SpacsBox from "../components/HeroSection";
import HSMCommentsAggregator from "../components/HSMCommentsAggregator";
import HSMPapersAggregator from "../components/HSMPapersAggregator";
import axios from "axios";

const HSMRenderer = ({
  userContext,
  activityId,
  activityProps,
  setActivityProps,
  showingCommentsFor,
  setShowingCommentsFor,
  showingPapersFor,
  setShowingPapersFor,
  crumbIds,
  setCrumbIds,
  setLayer,
  setCursor,
  toolbar,
  children,
  editing,
  inEditMode = false,
  setIsUnsavedChanges,
  isHumanMode,
  center,
}: HSMRendererProps) => {
  const setCursorPointer = () => setCursor("pointer");
  const setCursorAuto = () => setCursor("auto");
  const [legendActive, setLegendActive] = useState(false);
  const [dbComments, setDbComments] = useState([]);
  const [dbPapers, setDbPapers] = useState([]);  // Add state for database papers
  const [showAllComments, setShowAllComments] = useState(true); // Toggle for comments visibility
  const [showAllPapers, setShowAllPapers] = useState(true); // Toggle for papers visibility

  // Reference map for state elements to enable scrolling
  const stateRefs = useRef({});

  const updateEndpoint = editing
    ? `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_edit/update/`
    : isHumanMode
    ? `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_view_human/update/`
    : `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_view/update/`;

  // Fetch comments from database when stateId changes - only if user is logged in
  useEffect(() => {
    if (showingCommentsFor && userContext) {
      console.log(`\n==== FETCHING COMMENTS ====`);
      console.log(`State ID: ${showingCommentsFor}`);
      console.log(`Activity ID: ${activityId}`);
      console.log(`Endpoint: ${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${activityId}/state/${showingCommentsFor}`);
      
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${activityId}/state/${showingCommentsFor}`)
        .then(response => {
          if (response.data && response.data.length > 0) {
            console.log(`\n✅ FOUND ${response.data.length} COMMENTS FOR STATE: ${showingCommentsFor}`);
            console.log("========= COMMENTS DATA =========");
            response.data.forEach((comment, index) => {
              console.log(`\nCOMMENT #${index + 1}:`);
              console.log(`User: ${comment.user}`);
              console.log(`Text: ${comment.text}`);
              console.log(`Timestamp: ${new Date(comment.timestamp).toLocaleString()}`);
              if (comment.occupation) console.log(`Occupation: ${comment.occupation}`);
              console.log(`ID: ${comment._id}`);
              console.log("--------------------------");
            });
            console.log("================================\n");
            setDbComments(response.data);
          } else {
            console.log(`\n⚠️ NO COMMENTS AVAILABLE FOR STATE: ${showingCommentsFor}`);
            console.log("The database query returned no results for this state.");
            console.log("================================\n");
            setDbComments([]);
          }
        })
        .catch(err => {
          console.error(`\n❌ ERROR FETCHING COMMENTS FOR STATE: ${showingCommentsFor}`, err);
          console.log("================================\n");
          setDbComments([]);
        });
    } else {
      setDbComments([]);
    }
  }, [showingCommentsFor, activityId, userContext]);

  // Fetch papers from database when stateId changes - only if user is logged in
  useEffect(() => {
    if (showingPapersFor && userContext) {
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URI}/papers/${activityId}/state/${showingPapersFor}`)
        .then(response => {
          console.log("Fetched papers for state:", response.data);
          setDbPapers(response.data);
        })
        .catch(err => {
          console.error("Error fetching papers for state:", err);
          setDbPapers([]);
        });
    } else {
      setDbPapers([]);
    }
  }, [showingPapersFor, activityId, userContext]);

  // Function to delete a comment from the database
  const deleteDbComment = (commentId) => {
    // Get current user info from session storage
    const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
    const userId = currentUser?.id || "";
    const role = currentUser?.role || "";
    
    axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${commentId}`, {
      params: {
        userId: userId,
        role: role
      }
    })
      .then(() => {
        console.log("Comment deleted successfully");
        // Refresh the comments list
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${activityId}/state/${showingCommentsFor}`)
          .then(response => {
            console.log("Refreshed comments for state:", response.data);
            setDbComments(response.data);
          })
          .catch(err => {
            console.error("Error refreshing comments for state:", err);
          });
      })
      .catch(err => {
        console.error("Error deleting comment:", err);
        if (err.response && err.response.status === 403) {
          alert("Permission denied: " + (err.response.data.error || "You can only delete your own comments"));
        }
      });
  };

  // Function to delete a paper from the database
  const deleteDbPaper = (paperId) => {
    // Get current user info from session storage
    const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
    const userId = currentUser?.id || "";
    const role = currentUser?.role || "";
    
    axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URI}/papers/${paperId}`, {
      params: {
        userId: userId,
        role: role
      }
    })
      .then(() => {
        console.log("Paper deleted successfully");
        // Refresh the papers list
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URI}/papers/${activityId}/state/${showingPapersFor}`)
          .then(response => {
            console.log("Refreshed papers for state:", response.data);
            setDbPapers(response.data);
          })
          .catch(err => {
            console.error("Error refreshing papers for state:", err);
          });
      })
      .catch(err => {
        console.error("Error deleting paper:", err);
        if (err.response && err.response.status === 403) {
          alert("Permission denied: " + (err.response.data.error || "You can only delete your own papers"));
        }
      });
  };

  let commentsSidebar = null;

  if (showingCommentsFor && userContext) {
    let propsOfActiveCommentState = getPropsOfState(
      activityProps,
      crumbIds,
      showingCommentsFor
    );

    if (!propsOfActiveCommentState) {
      setShowingCommentsFor(undefined);
    } else {
      // Get comments from the HSM structure
      const hsmComments = collectCommentsOneLevelDeep(propsOfActiveCommentState);
      
      // Transform database comments to match CommentProps format
      const formattedDbComments = dbComments.map(comment => ({
        author: comment.user,
        occupation: comment.occupation || "", // Use empty string if occupation is not available
        text: comment.text,
        stateText: comment.stateText,
        timestamp: new Date(comment.timestamp), // Add timestamp for sorting
        fromDb: true, // Mark as from database for potential UI differentiation
        _id: comment._id, // Keep the original ID for potential deletion
        userId: comment.userId // Include the userId for permission checks
      }));
      
      // Combine both types of comments and sort by timestamp if available
      const allComments = [...hsmComments, ...formattedDbComments].sort((a, b) => {
        if (a.timestamp && b.timestamp) {
          return a.timestamp.getTime() - b.timestamp.getTime();
        }
        return 0;
      });

      let commentsSidebarProps: CommentsSidebarProps = {
        stateProps: propsOfActiveCommentState,
        addComment: (commentText: string) => {
          let commentProps: CommentProps = {
            author: userContext.name,
            occupation: userContext.occupation,
            text: commentText,
            stateText: propsOfActiveCommentState.text,
          };
          let newActivityProps = {
            ...activityProps,
            children: addComment(
              activityProps,
              crumbIds,
              showingCommentsFor,
              commentProps
            ),
          };

          fetch(updateEndpoint + activityId, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newActivityProps),
          }).then((res) => {
            if (res.ok) {
              setActivityProps(newActivityProps);
              
              const dbComment = {
                text: commentText,
                user: userContext.name,
                userId: userContext.id,
                timestamp: new Date(),
                avatarId: activityId,
                stateId: showingCommentsFor,
                statePath: crumbIds,
                stateText: propsOfActiveCommentState.text
              };
              
              console.log("Sending comment with state info:", JSON.stringify(dbComment));
              axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URI}/saveComment`,
                dbComment
              ).then(response => {
                console.log("Comment saved with state info:", response.data);
              }).catch(err => {
                console.error("Error saving comment to database:", err);
              });
            }
          });
        },
        hideComments: () => setShowingCommentsFor(undefined),
        children: allComments,
        deleteDbComment: deleteDbComment
      };

      commentsSidebar = <CommentsSidebar {...commentsSidebarProps} />;
    }
  }

  let papersSidebar = null;

  if (showingPapersFor && userContext) {
    let propsOfActivePaperState = getPropsOfState(
      activityProps,
      crumbIds,
      showingPapersFor
    );

    if (!propsOfActivePaperState) {
      setShowingPapersFor(undefined);
    } else {
      // Transform database papers to match PaperProps format
      const formattedDbPapers = dbPapers.map(paper => ({
        title: paper.title,
        author: paper.user,
        url: paper.url,
        modelInfo: paper.modelInfo || "",
        stateText: paper.stateText,
        fromDb: true, // Mark as from database for UI differentiation
        _id: paper._id, // Keep the original ID for deletion
        userId: paper.userId // Include the userId for permission checks
      }));
      
      // Combine HSM papers with DB papers if needed
      const hsmPapers = collectPapersOneLevelDeep(propsOfActivePaperState);
      const allPapers = [...formattedDbPapers]; // Only use DB papers in this new approach
      
      let papersProps: PapersSidebarProps = {
        stateProps: propsOfActivePaperState,
        addPaper: (
          paperTitle: string,
          paperUrl: string,
          modelInfo: string = ""
        ) => {
          // Create paper data
          const dbPaper = {
            title: paperTitle,
            url: paperUrl,
            modelInfo: modelInfo,
            user: userContext.name,
            userId: userContext.id,
            timestamp: new Date(),
            avatarId: activityId,
            stateId: showingPapersFor,
            statePath: crumbIds,
            stateText: propsOfActivePaperState.text
          };
          
          // Save paper to database directly
          console.log("Sending paper to database:", JSON.stringify(dbPaper));
          axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URI}/savePaper`,
            dbPaper
          ).then(response => {
            console.log("Paper saved to database:", response.data);
            // Refresh the papers list
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URI}/papers/${activityId}/state/${showingPapersFor}`)
              .then(response => {
                console.log("Refreshed papers for state:", response.data);
                setDbPapers(response.data);
              })
              .catch(err => {
                console.error("Error refreshing papers for state:", err);
              });
          }).catch(err => {
            console.error("Error saving paper to database:", err);
          });
        },
        hidePapers: () => setShowingPapersFor(undefined),
        children: allPapers,
        deleteDbPaper: deleteDbPaper
      };

      papersSidebar = <PapersSidebar {...papersProps} />;
    }
  }

  const bcNavProps: BCNavProps = {
    crumbIds,
    setCrumbIds,
    setLayer,
    activityProps,
    onMouseEnterCrumb: setCursorPointer,
    onMouseLeaveCrumb: setCursorAuto,
  };

  if (crumbIds.length === 0) return null;

  function handleTitleChange(newTitle: string) {
    setActivityProps(updateTitleOfActivity(activityProps, newTitle));
  }

  const titleInputProps: TitleInputProps = {
    title: activityProps.text,
    setTitle: handleTitleChange,
    setIsUnsavedChanges,
  };

  let i = 1;
  let ancestorProps: StateBoxProps[] = [
    {
      type: "activity",
      text: activityProps.text,
      imageLink: "",
      videoLink: "",
      setImageLink: (link: string) =>
        setActivityProps((prev: any) => ({
          ...prev,
          imageLink: link,
        })),
      setVideoLink: (link: string) =>
        setActivityProps((prev: any) => ({
          ...prev,
          videoLink: link,
        })),
      setActivityProps: setActivityProps,
    },
  ];

  while (i < crumbIds.length) {
    const stateProps = getPropsOfState(activityProps, crumbIds.slice(0, i), crumbIds[i]);
    if (stateProps) {
      // Ensure all necessary properties exist to prevent errors in HSMContainer
      const safeStateProps = {
        ...stateProps,
        imageLink: stateProps.imageLink || "",
        videoLink: stateProps.videoLink || "",
        setImageLink: stateProps.setImageLink || ((link: string) => {
          setActivityProps((prev: any) => {
            const updatedActivity = JSON.parse(JSON.stringify(prev));
            // Find state by path and update it
            const path = crumbIds.slice(0, i);
            let current = updatedActivity;
            for (const id of path) {
              if (current.children) {
                const child = current.children.find(c => c.uid === id);
                if (child) current = child;
              }
            }
            if (current.children) {
              const target = current.children.find(c => c.uid === crumbIds[i]);
              if (target) target.imageLink = link;
            }
            return updatedActivity;
          });
        }),
        setVideoLink: stateProps.setVideoLink || ((link: string) => {
          setActivityProps((prev: any) => {
            const updatedActivity = JSON.parse(JSON.stringify(prev));
            // Find state by path and update it
            const path = crumbIds.slice(0, i);
            let current = updatedActivity;
            for (const id of path) {
              if (current.children) {
                const child = current.children.find(c => c.uid === id);
                if (child) current = child;
              }
            }
            if (current.children) {
              const target = current.children.find(c => c.uid === crumbIds[i]);
              if (target) target.videoLink = link;
            }
            return updatedActivity;
          });
        }),
        setActivityProps: stateProps.setActivityProps || setActivityProps
      };
      ancestorProps.push(safeStateProps);
    } else {
      console.warn(`Failed to get props for state at path: ${crumbIds.slice(0, i).join('/')}/${crumbIds[i]}`);
    }
    i++;
  }

  return (
    <div className="col">
      <div className="container-md">
        <div className="row">
          <SpacsBox heading="" buttonTitle="Workflow" />
        </div>
      </div>
      <div className="HSMRenderer" style={{ marginTop: "-70px" }}>
        {center()}
        <div className="flex items-center justify-between col-10 offset-1">
          <BCNav {...bcNavProps} />
          {toolbar}
        </div>

        {inEditMode && <HSMTitleInput {...titleInputProps} />}
        <div className="BuildingBlockContainer">
          <BuildingBlockTableContainer
            activityProps={activityProps}
            setActivityProps={setActivityProps}
            setIsUnsavedChanges={setIsUnsavedChanges}
            isEditable={inEditMode}
            isExpand={true}
            isHumanMode={isHumanMode}
          />
        </div>
        {children.map((child, idx) => (
          <div className="HSMContainerRoot" key={idx}>
            {idx === 0 && (
              <HSMContainer
                stateAncestors={ancestorProps}
                level={0}
                hsm={child}
                crumbIds={crumbIds}
                setCrumbIds={setCrumbIds}
                activityProps={activityProps}
                setActivityProps={setActivityProps}
                setLayer={setLayer}
              />
            )}
            {idx !== 0 && child}
          </div>
        ))}

        {/* Section toggles - only show for logged in users */}
        {userContext && (
          <div className="BuildingBlockContainer">
            <div className="col-10 offset-1 my-3">
              <div className="d-flex gap-3 justify-content-start">
                <button 
                  className={`btn ${showAllComments ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => setShowAllComments(!showAllComments)}
                >
                  {showAllComments ? 'Hide All Comments' : 'Show All Comments'}
                </button>
                <button 
                  className={`btn ${showAllPapers ? 'btn-primary' : 'btn-outline-primary'}`}
                  onClick={() => setShowAllPapers(!showAllPapers)}
                >
                  {showAllPapers ? 'Hide All Papers' : 'Show All Papers'}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Aggregated comments section - only show for logged in users */}
        {userContext && showAllComments && (
          <div className="col-10 offset-1">
            <HSMCommentsAggregator activityId={activityId} />
          </div>
        )}

        {/* Aggregated papers section - only show for logged in users */}
        {userContext && showAllPapers && (
          <div className="col-10 offset-1">
            <HSMPapersAggregator activityId={activityId} />
          </div>
        )}

        <Legend active={legendActive} setActive={setLegendActive} />
        {commentsSidebar}
        {papersSidebar}
      </div>
    </div>
  );
};

export default HSMRenderer;
