import React from "react";

const Terms = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing and using this website, you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by these terms, please do not use this website.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">2. Use License</h2>
        <p className="mb-4">
          Permission is granted to temporarily use this website for personal, non-commercial purposes only. This is the grant of a license, not a transfer of title, and under this license you may not:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Modify or copy the materials</li>
          <li>Use the materials for any commercial purpose</li>
          <li>Attempt to decompile or reverse engineer any software contained on the website</li>
          <li>Remove any copyright or other proprietary notations from the materials</li>
          <li>Transfer the materials to another person or "mirror" the materials on any other server</li>
        </ul>
        <p>This license shall automatically terminate if you violate any of these restrictions and may be terminated at any time.</p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">3. User Accounts</h2>
        <p className="mb-4">
          To access certain features of the website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
        </p>
        <p className="mb-4">
          You agree to provide accurate and complete information when creating your account and to update your information to keep it accurate and complete.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">4. Content and Conduct</h2>
        <p className="mb-4">
          Users may be able to post content or communicate with others through the website. You are solely responsible for your conduct and any content that you submit, post, or display.
        </p>
        <p className="mb-4">
          You agree not to use the website to:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Violate any applicable laws or regulations</li>
          <li>Infringe the intellectual property rights of others</li>
          <li>Harass, abuse, or harm another person</li>
          <li>Upload or transmit viruses or malicious code</li>
          <li>Interfere with the proper functioning of the website</li>
        </ul>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">5. Disclaimer</h2>
        <p className="mb-4">
          The materials on this website are provided "as is". We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">6. Limitations</h2>
        <p className="mb-4">
          In no event shall the website or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to interruption) arising out of the use or inability to use the materials on the website, even if we or an authorized representative has been notified orally or in writing of the possibility of such damage.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">7. Revisions and Errata</h2>
        <p className="mb-4">
          The materials appearing on the website could include technical, typographical, or photographic errors. We do not warrant that any of the materials on the website are accurate, complete, or current. We may make changes to the materials contained on the website at any time without notice.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">8. Links</h2>
        <p className="mb-4">
          We have not reviewed all of the sites linked to our website and are not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by us of the site. Use of any such linked website is at your own risk.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-3">9. Governing Law</h2>
        <p className="mb-4">
          These terms and conditions are governed by and construed in accordance with the laws and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
        </p>
      </section>
      
      <section>
        <h2 className="text-xl font-semibold mb-3">10. Contact Information</h2>
        <p className="mb-4">
          If you have any questions about these Terms of Use, please contact us.
        </p>
      </section>
    </div>
  );
};

export default Terms; 