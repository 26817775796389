import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateTitleOfActivity } from "../utils/stateUtils";
import { HSMToolBar } from "./HSMToolBar";
import { HSMRendererProps, HSMViewProps } from "../utils/interface/HSM";
import HSMView from "./HSMView";
import HSMRenderer from "../Pages/HSMRenderer";
import { UserContext } from "../utils/interface/AppInterface";
import { SaveChanges } from "../utils/HSMUtils";
import { EditModeButton } from "./EditModeButton";

export type EditableHSMRendererProps = {
  userContext: UserContext;
  nextUID: () => string;
  setCursor: React.Dispatch<React.SetStateAction<string>>;
};

export const EditableHSMRenderer = ({
  userContext,
  nextUID,
  setCursor,
}: EditableHSMRendererProps) => {
  const [inEditMode, setInEditMode] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [savedChangesText, setSavedChangesText] = useState("");
  const [showingCommentsFor, setShowingCommentsFor] = useState(undefined);
  const [showingPapersFor, setShowingPapersFor] = useState(undefined);
  const { id } = useParams();
  const [activityProps, setActivityProps] = useState<any>({});
  const [crumbIds, setCrumbIds] = useState([]);
  const [hsmLoaded, setHsmLoaded] = useState(false);

  function showCommentsWrapper(id: string) {
    setShowingPapersFor(undefined);

    if (id === showingCommentsFor) {
      // toggle comments off
      setShowingCommentsFor(-1);
    } else {
      setShowingCommentsFor(id);
    }
  }

  function showPapersWrapper(id: string) {
    setShowingCommentsFor(-1);
    if (id === showingPapersFor) {
      // toggle comments off
      setShowingPapersFor(-1);
    } else {
      setShowingPapersFor(id);
    }
  }

  function onSave() {
    if (activityProps.text === "") {
      setActivityProps(
        updateTitleOfActivity(activityProps, "Untitled workflow")
      );
    }
    
    // Add validation before saving
    console.log("Preparing to save activity:", activityProps);
    
    // Ensure we can stringify the activity
    let activityToSave;
    try {
      // First try to serialize to validate
      const serialized = JSON.stringify(activityProps);
      // Then parse back to get a clean copy
      activityToSave = JSON.parse(serialized);
      
      console.log("Activity serialized successfully, saving...");
    } catch (error) {
      console.error("Failed to serialize activity for saving:", error);
      alert("Error saving changes. Please try again or refresh the page.");
      return;
    }
    
    fetch(`${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_edit/update/` + id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(activityToSave),
    })
    .then((res) => {
      if (!res.ok) {
        console.error("Error saving workflow:", res.status, res.statusText);
        throw new Error("Failed to save workflow");
      }
      return res.json();
    })
    .then((data) => {
      console.log("Save successful:", data);
      setIsUnsavedChanges(false);
      setSavedChangesText("All changes saved");
    })
    .catch((error) => {
      console.error("Error in save operation:", error);
      alert("Error saving changes. Please try again.");
    });
  }

  useEffect(() => {
    async function getHsm() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm_edit/` + id
      );

      if (!response.ok) {
        const message = `An error occured: ${response.statusText}`;
        window.alert(message);
        return;
      }

      const hsmRes = await response.json();

      if (userContext.id != hsmRes.author) {
        // the current user is not the owner of this HSM, so we return
        return;
      }

      // Check if the workflow is approved - if yes, redirect to view page
      if (hsmRes.reviewStatus === "Approved") {
        window.alert("This workflow has been approved and cannot be edited. Redirecting to view page.");
        window.location.href = `/view/${id}`;
        return;
      }

      setHsmLoaded(true);
      setActivityProps(hsmRes);
      setCrumbIds([hsmRes.uid]);
    }

    getHsm();

    return;
  }, [hsmLoaded]);

  if (!hsmLoaded) {
    return null;
  }

  const EditableHSMRendererToolbar = () => {
    return (
      <HSMToolBar>
        <EditModeButton inEditMode={inEditMode} setInEditMode={setInEditMode} />
        <SaveChanges
          savedChangesText={savedChangesText}
          isUnsavedChanges={isUnsavedChanges}
          onSave={onSave}
        />
      </HSMToolBar>
    );
  };

  const human = activityProps.viewMode === "human";
  const centerComponent = (
    <div className="viewmode ">
      <div className="btn-group flex gap-2 ">
        <input
          type="radio"
          className="btn-check"
          name="options"
          id="radio1"
          autoComplete="off"
          checked={human}
          disabled={!human}
        />
        <label
          className={`w-full py-2 px-4 rounded-full  flex justify-center items-center ${
            human ? "bg-gray-800 text-white " : "bg-gray-400 text-white"
          }`}
          htmlFor="radio1"
        >
          Human caregiver
        </label>

        <input
          type="radio"
          className="btn-check"
          name="options"
          id="radio2"
          autoComplete="off"
          checked={!human}
          disabled={human}
        />
        <label
          className={`w-full py-2 px-4 rounded-full flex justify-center items-center ${
            !human ? "bg-gray-800 text-white " : "bg-gray-400 text-white"
          }`}
          htmlFor="radio2"
        >
          Robot caregiver
        </label>
      </div>
    </div>
  );

  const hsmViewProps: HSMViewProps = {
    activityProps: activityProps,
    setActivityProps: setActivityProps,
    crumbIds: crumbIds,
    setCrumbIds: setCrumbIds,
    discussionButtons: false,
    viewOnlyMode: false,
    editing: inEditMode,
    setCursor: setCursor,
    nextUID: nextUID,
    setNextUID: () => {},
    setShowingCommentsFor: showCommentsWrapper,
    setShowingPapersFor: showPapersWrapper,
    setIsUnsavedChanges: setIsUnsavedChanges,
    isHumanMode: human,
  };

  const hsmRendererProps: HSMRendererProps = {
    userContext: userContext,
    activityId: id,
    activityProps: activityProps,
    setActivityProps: setActivityProps,
    showingCommentsFor: showingCommentsFor,
    setShowingCommentsFor: setShowingCommentsFor,
    showingPapersFor: showingPapersFor,
    setShowingPapersFor: setShowingPapersFor,
    crumbIds: crumbIds,
    setCrumbIds: setCrumbIds,
    setCursor: setCursor,
    toolbar: <EditableHSMRendererToolbar />,
    children: [<HSMView {...hsmViewProps} />],
    editing: true,
    inEditMode: inEditMode,
    setIsUnsavedChanges: setIsUnsavedChanges,
    center: () => centerComponent,
    isHumanMode: human,
  };

  return <HSMRenderer {...hsmRendererProps} />;
};

export default EditableHSMRenderer;
