import React from "react";
import {
  HSMViewProps,
  StateBoxProps,
} from "../utils/interface/HSM";
import {
  deleteStateUid,
  getStatesCurrentLayer,
} from "../utils/stateUtils";
import HSMTreeView from "./HSMTreeView";

const HSMView = ({
  activityProps,
  setActivityProps,
  crumbIds,
  setCrumbIds,
  setLayer,
  discussionButtons,
  viewOnlyMode,
  editing,
  setCursor,
  nextUID,
  setShowingCommentsFor,
  setShowingPapersFor,
  setIsUnsavedChanges,
  isHumanMode,
}: HSMViewProps) => {
  const statesProps = getStatesCurrentLayer(activityProps, crumbIds);

  if (!Array.isArray(statesProps) || statesProps.length === 0) {
    return <h1 className="mx-auto">Workflow coming soon</h1>;
  }

  // Generate the common state props to pass to the tree view
  const commonStateProps: Partial<StateBoxProps> = {
    viewOnlyMode,
    discussionButtons,
    editing,
    setActivityProps: (updatedActivity) => {
      setActivityProps((prevActivity) => {
        setIsUnsavedChanges(true);
        try {
          // Additional validation to prevent the 'undefined' string issue
          if (updatedActivity === undefined || updatedActivity === null) {
            console.error("Cannot update with undefined/null activity");
            return prevActivity;
          }
          
          // Handle the case where updatedActivity might be the string "undefined"
          if (typeof updatedActivity === 'string' && updatedActivity === 'undefined') {
            console.error("Received 'undefined' string as activity");
            return prevActivity;
          }
          
          // Check if updatedActivity is a function - this happens when a state updater function is passed
          if (typeof updatedActivity === 'function') {
            // Call the updater function with the previous activity
            try {
              const result = updatedActivity(prevActivity);
              return result;
            } catch (funcError) {
              console.error("Error calling updater function:", funcError);
              return prevActivity;
            }
          }
          
          // Ensure we're not trying to stringify undefined or malformed objects
          if (typeof updatedActivity !== 'object') {
            console.error("updatedActivity is not an object:", typeof updatedActivity);
            return prevActivity;
          }
          
          // Instead of stringifying and parsing, create a safe deep copy
          try {
            const copy = JSON.parse(JSON.stringify(updatedActivity));
            return copy;
          } catch (jsonError) {
            console.error("Failed to copy activity via JSON:", jsonError);
            // Fall back to a basic object copy method if JSON fails
            const manualCopy = {...prevActivity};
            
            // Only update specific fields we know should be safe
            if (updatedActivity.text) manualCopy.text = updatedActivity.text;
            if (updatedActivity.children) manualCopy.children = [...updatedActivity.children];
            if (updatedActivity.imageLink) manualCopy.imageLink = updatedActivity.imageLink;
            if (updatedActivity.videoLink) manualCopy.videoLink = updatedActivity.videoLink;
            
            return manualCopy;
          }
        } catch (error) {
          console.error("Error updating activity:", error);
          return prevActivity; // Return the previous state unchanged on error
        }
      });
    },
    deleteSelf: (stateUid?: string) => {
      setActivityProps((prevActivity) => {
        setIsUnsavedChanges(true);
        try {
          // Additional validation for the prevActivity
          if (prevActivity === undefined || prevActivity === null) {
            console.error("Cannot delete from undefined/null activity");
            return {};
          }
          
          // Handle string "undefined" case
          if (typeof prevActivity === 'string' && prevActivity === 'undefined') {
            console.error("Received 'undefined' string as activity in deleteSelf");
            return {};
          }
          
          // Safely stringify and parse
          const stringified = JSON.stringify(prevActivity);
          if (!stringified || stringified === 'undefined' || stringified === 'null') {
            console.error("Invalid JSON stringification result in deleteSelf:", stringified);
            return {};
          }
          
          const updatedActivity = JSON.parse(stringified);
          // If a specific stateUid is provided, use it, otherwise default behavior
          deleteStateUid(updatedActivity, crumbIds, stateUid || "");
          return updatedActivity;
        } catch (error) {
          console.error("Error during state deletion:", error);
          return prevActivity; // Return the previous state unchanged on error
        }
      });
    },
    // Define better handlers that will work at all levels
    showComments: (stateUid?: string) => {
      console.log("HSMView showComments called with:", stateUid || "default");
      setShowingCommentsFor(stateUid || "");
    },
    showPapers: (stateUid?: string) => {
      console.log("HSMView showPapers called with:", stateUid || "default");
      setShowingPapersFor(stateUid || "");
    },
    setCursorPointer: () => setCursor("pointer"),
    setCursorAuto: () => setCursor("auto"),
    humanMode: isHumanMode,
    // Add setImageLink and setVideoLink
    setImageLink: (link: string) => {
      console.log("HSMView setImageLink called with:", link);
      // Update activity props with the image link
      setActivityProps((prevActivity) => {
        setIsUnsavedChanges(true);
        try {
          // Additional validation for the prevActivity
          if (prevActivity === undefined || prevActivity === null) {
            console.error("Cannot update image link on undefined/null activity");
            return {};
          }
          
          // Handle string "undefined" case
          if (typeof prevActivity === 'string' && prevActivity === 'undefined') {
            console.error("Received 'undefined' string as activity in setImageLink");
            return {};
          }
          
          // Safely stringify and parse
          const stringified = JSON.stringify(prevActivity);
          if (!stringified || stringified === 'undefined' || stringified === 'null') {
            console.error("Invalid JSON stringification result in setImageLink:", stringified);
            return {};
          }
          
          const updatedActivity = JSON.parse(stringified);
          updatedActivity.imageLink = link;
          return updatedActivity;
        } catch (error) {
          console.error("Error updating image link:", error);
          return prevActivity; // Return the previous state unchanged on error
        }
      });
    },
    setVideoLink: (link: string) => {
      console.log("HSMView setVideoLink called with:", link);
      // Update activity props with the video link
      setActivityProps((prevActivity) => {
        setIsUnsavedChanges(true);
        try {
          // Additional validation for the prevActivity
          if (prevActivity === undefined || prevActivity === null) {
            console.error("Cannot update video link on undefined/null activity");
            return {};
          }
          
          // Handle string "undefined" case
          if (typeof prevActivity === 'string' && prevActivity === 'undefined') {
            console.error("Received 'undefined' string as activity in setVideoLink");
            return {};
          }
          
          // Safely stringify and parse
          const stringified = JSON.stringify(prevActivity);
          if (!stringified || stringified === 'undefined' || stringified === 'null') {
            console.error("Invalid JSON stringification result in setVideoLink:", stringified);
            return {};
          }
          
          const updatedActivity = JSON.parse(stringified);
          
          // Update the video link in the activity state
          updatedActivity.videoLink = link;
          
          // Log the update for debugging
          console.log("Updated activity with video link:", link);
          
          return updatedActivity;
        } catch (error) {
          console.error("Error updating video link:", error);
          return prevActivity; // Return the previous state unchanged on error
        }
      });
    }
  };

  return (
    <div className="w-full max-w-full overflow-x-auto mx-auto">
      <HSMTreeView 
        rootStates={statesProps} 
        crumbIds={crumbIds} 
        setCrumbIds={setCrumbIds} 
        setLayer={setLayer}
        commonStateProps={commonStateProps}
        setShowingCommentsFor={setShowingCommentsFor}
        setShowingPapersFor={setShowingPapersFor}
        setIsUnsavedChanges={setIsUnsavedChanges}
      />
    </div>
  );
};

export default HSMView;
