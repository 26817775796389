import { useEffect, useState } from "react";
import { getComparator, stableSort } from "../utils/ADLSPageUtils";
import { db, collection, addDoc } from "../firebase";
import axios from "axios";
import { toast } from "react-toastify";

const ADLSPAGETableBody = ({
  rows,
  order,
  orderBy,
  currentPage,
  rowsPerPage,
  handleClick,
  props,
  setrefresh,
}) => {
  const [startIndex, setstartIndex] = useState(0);
  const [endIndex, setendIndex] = useState(rowsPerPage);
  const [userContext, setUserContext] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    // Log rows data for debugging
    console.log("Rows data:", rows);
    console.log("Rows with review status:", rows.map(row => ({
      name: row.name,
      reviewStatus: row.reviewStatus,
      visibility: row.visibility
    })));
    
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    setstartIndex(start);
    setendIndex(end);

    const userContextData = sessionStorage.getItem("user");
    if (userContextData) {
      const parsedUserContext = JSON.parse(userContextData);
      setUserContext(parsedUserContext);
      
      // No additional filtering needed - the rows are already properly filtered in ADLSPageTable
      setFilteredRows(rows);
      console.log("Table body using pre-filtered rows:", rows.length);
    } else {
      // If not logged in, use the rows as provided (already filtered for public visibility)
      setFilteredRows(rows);
    }
  }, [currentPage, rowsPerPage, rows]);

  const handleStatusClick = (row) => {
    // Check if user has permission to change status
    if (!userContext || !(userContext.name === "admin" || userContext.role === "admin" || userContext.role === "superadmin")) {
      toast.error("You don't have permission to change the status.");
      return;
    }
    
    const newVisibility = !row.visibility;
    const confirmation = window.confirm(
      `Are you sure you want to change the status to ${newVisibility ? "public" : "private"}?`
    );

    if (confirmation) {
      handleUpdate(row, newVisibility, undefined);
    }
  };

  const handleReviewStatusClick = async (row, newStatus) => {
    // Check if user has permission to change review status
    if (!userContext || !(userContext.name === "admin" || userContext.role === "admin" || userContext.role === "superadmin")) {
      toast.error("You don't have permission to change review status.");
      return;
    }
    
    try {
      await handleUpdate(row, undefined, newStatus);
      
      // If approving, also add credits to the author
      if (newStatus === "Approved") {
        await handleAddCredits(row.author, 10, userContext);
      }
    } catch (error) {
      console.error("Error adding notification or updating document:", error);
    }
  };

  const handleUpdate = async (row, visibility, reviewStatus) => {
    const updateData = {
      ...(visibility !== undefined && { visibility }),
      ...(reviewStatus !== undefined && { reviewStatus }),
    };
    if (Object.keys(updateData).length === 0) {
      alert("No fields to update");
      return;
    }
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/adls_edit/${row.id}`,
        {
          updateData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (reviewStatus !== undefined) {
        // Ensure consistent category naming
        const notificationType = reviewStatus === "Approved" ? "new_workflow" : "review_workflow";
        const notificationText = reviewStatus === "Approved" ? 
          `${row.name} Approved` : 
          `${row.name} has been ${reviewStatus.toLowerCase()}`;
        
        // Log notification data before creation
        console.log("Creating notification with data:", {
          text: notificationText,
          category: notificationType,
          targeted_user: row.author,
          createdby: "admin",
          avatarId: row.avatar,
          workflowId: row.id
        });
        
        // Create the notification document
        const notificationRef = await addDoc(collection(db, "notifications"), {
          text: notificationText,
          category: notificationType,
          targeted_user: row.author,
          createdby: "admin",
          avatarId: row.avatar,
          workflowId: row.id
        });
        
        console.log("Created notification with ID:", notificationRef.id);
      }
      
      // Add credits to author only if workflow is being approved
      if (reviewStatus === "Approved") {
        handleAddCredits(row.author, 10, userContext);
      }
      
      setrefresh(false);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const saveCommentToDB = async (comment) => {
    try {
      await addDoc(collection(db, "notifications"), {
        text: comment.text,
        category: "comment",
        targeted_user: comment.target,
        createdby: comment.user.role,
      });
    } catch (err) {
      console.error("Error saving comment", err);
    }
  };

  const handleAddCredits = async (userId, amount, user) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URI}/hsm/credits`,
        {
          userId,
          amount,
        }
      );

      if (response.status === 201) {
        toast.success(` ${response.data.message}`);
        const newComment = {
          text: response.data.message,
          target: userId,
          user: user,
          timestamp: new Date(),
        };
        await saveCommentToDB(newComment);
      }
    } catch (error) {
      console.error("Error adding credits:", error);
    }
  };

  const renderReviewStatusButtons = (row) => {
    if (row.reviewStatus === "Approved") {
      return null;
    }
    
    // Only render the review buttons for admin/superadmin
    if (userContext && (userContext.name === "admin" || userContext.role === "admin" || userContext.role === "superadmin")) {
      return (
        <>
          {row.reviewStatus !== "Reviewed" && (
            <button
              className="ml-2 px-3 py-1.5 bg-purple-500 text-white text-sm rounded-md shadow-sm hover:bg-purple-600 transition-colors"
              onClick={() => handleReviewStatusClick(row, "Reviewed")}
            >
              Mark Reviewed
            </button>
          )}
          <button
            className="ml-2 px-3 py-1.5 bg-green-500 text-white text-sm rounded-md shadow-sm hover:bg-green-600 transition-colors"
            onClick={() => handleReviewStatusClick(row, "Approved")}
          >
            Approve
          </button>
        </>
      );
    }
    
    return null;
  };

  return (
    <tbody>
      {filteredRows.length === 0 ? (
        <tr>
          <td 
            colSpan={userContext ? 7 : 5}
            className="px-4 py-8 text-center text-gray-500"
          >
            No workflows found matching your search criteria
          </td>
        </tr>
      ) : (
        stableSort(filteredRows, getComparator(order, orderBy))
          .slice(startIndex, endIndex)
          .map((row, index) => {
            // Debug log for each row
            console.log(`Row ${index+1} - ${row.name}:`, {
              reviewStatus: row.reviewStatus,
              visibility: row.visibility,
              showNotApproved: row.reviewStatus !== "Approved"
            });
            
            return (
              <tr
                key={index}
                className="hover:bg-gray-50 border-b border-gray-200 transition-colors duration-150 ease-in-out"
                onMouseEnter={() => props.setCursor("pointer")}
                onMouseLeave={() => props.setCursor("auto")}
              >
                <td className="px-4 py-4 text-center">{startIndex + index + 1}</td>
                <td className="px-4 py-4 text-center font-medium text-gray-800">{row.name}</td>
                <td className="px-4 py-4 text-center">
                  <button
                    className="px-3 py-1.5 bg-blue-500 text-white text-sm rounded-md shadow-sm hover:bg-blue-600 transition-colors"
                    onClick={() => handleClick(row.url)}
                  >
                    {row.viewMode || "Primary Caregiver"}
                  </button>
                </td>
                <td className="px-4 py-4 text-center">{row.category}</td>

                {/* Only show Status column for logged-in users */}
                {userContext && (
                  <td className="px-4 py-4 text-center">
                    {userContext && (userContext.name === "admin" || userContext.role === "admin" || userContext.role === "superadmin") ? (
                      <button
                        className="px-3 py-1.5 bg-blue-500 text-white text-sm rounded-md shadow-sm hover:bg-blue-600 transition-colors"
                        onClick={() => handleStatusClick(row)}
                      >
                        {row.visibility ? "Public" : "Private"}
                      </button>
                    ) : (
                      <span className="px-3 py-1.5 inline-block text-sm font-medium text-gray-700">
                        {row.visibility ? "Public" : "Private"}
                      </span>
                    )}
                    {userContext && (userContext.name === "admin" || userContext.role === "admin" || userContext.role === "superadmin") && row.reviewStatus !== "Approved" && renderReviewStatusButtons(row)}
                  </td>
                )}
                
                {/* Only show Review Status column for logged-in users */}
                {userContext && (
                  <td className="px-4 py-4 text-center">
                    {/* Only show review status for user's own workflows or for admins */}
                    {(userContext.id === row.author || 
                      userContext.name === "admin" || 
                      userContext.role === "admin" || 
                      userContext.role === "superadmin") && (
                      row.reviewStatus === "Pending" ? (
                        <span className="inline-flex items-center px-3 py-1 rounded-md text-xs font-medium bg-yellow-100 text-yellow-800 border border-yellow-200">
                          Pending
                        </span>
                      ) : row.reviewStatus === "Reviewed" ? (
                        <span className="inline-flex items-center px-3 py-1 rounded-md text-xs font-medium bg-blue-100 text-blue-800 border border-blue-200">
                          Reviewed
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-3 py-1 rounded-md text-xs font-medium bg-green-100 text-green-800 border border-green-200">
                          Approved
                        </span>
                      )
                    )}
                  </td>
                )}

                <td className="px-4 py-4 text-center">
                  {userContext && userContext.id === row.author && row.reviewStatus !== "Approved" ? (
                    <div className="relative group">
                      <button
                        className={`px-3 py-1.5 ${row.reviewStatus === "Reviewed" ? "bg-orange-500 hover:bg-orange-600" : "bg-yellow-500 hover:bg-yellow-600"} text-white text-sm rounded-md shadow-sm transition-colors`}
                        onClick={() => handleClick(row.url)}
                      >
                        Edit
                      </button>
                      {row.reviewStatus === "Reviewed" && (
                        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-800 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
                          Editing will reset review status to Pending
                        </div>
                      )}
                    </div>
                  ) : (
                    <button
                      className="px-3 py-1.5 bg-gray-500 text-white text-sm rounded-md shadow-sm hover:bg-gray-600 transition-colors"
                      onClick={() => handleClick(row.url)}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            );
          })
      )}
    </tbody>
  );
};

export default ADLSPAGETableBody;
