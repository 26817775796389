import React, { useState, useEffect } from "react";
import { StateBoxProps } from "../utils/interface/HSM";
import HSMStateBox from "./HSMStateBox";
import { ChevronRight, ChevronDown, Plus } from "react-feather";
import { deleteStateUid } from "../utils/stateUtils";

type HSMTreeNodeProps = {
  state: StateBoxProps;
  level: number;
  stateProps: Partial<StateBoxProps>;
  crumbIds: string[];
  setCrumbIds: (crumbs: string[]) => void;
  setLayer?: React.Dispatch<React.SetStateAction<string>>;
  setShowingCommentsFor: (id: string) => void;
  setShowingPapersFor: (id: string) => void;
  setIsUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
};

const HSMTreeNode: React.FC<HSMTreeNodeProps> = ({
  state,
  level,
  stateProps,
  crumbIds,
  setCrumbIds,
  setLayer,
  setShowingCommentsFor,
  setShowingPapersFor,
  setIsUnsavedChanges
}) => {
  const isHumanMode = stateProps.humanMode === true;
  const isRobotMode = !isHumanMode;
  
  // In both human and robot modes, composite tasks should be collapsed by default
  const [expanded, setExpanded] = useState(false);
  const hasChildren = state.children && state.children.length > 0;
  
  // Local state for image and video links
  const [imageLink, setImageLink] = useState(state.imageLink || '');
  const [videoLink, setVideoLink] = useState(state.videoLink || '');
  
  // Update local state when props change
  useEffect(() => {
    setImageLink(state.imageLink || '');
  }, [state.imageLink]);
  
  useEffect(() => {
    setVideoLink(state.videoLink || '');
  }, [state.videoLink]);
  
  // Log state info for debugging
  console.log(`TreeNode for state ${state.uid}: imageLink=${state.imageLink}, videoLink=${state.videoLink}`);
  
  // Don't render composite skill and skill level states when humanMode is true
  if (isHumanMode && (state.type === "composite skill" || state.type === "skill")) {
    return null;
  }

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  const handleStateClick = () => {
    // This function is intentionally not used anymore.
    // We've removed the ability to click state cards to navigate through the HSM.
    // The onClick prop is no longer passed to HSMStateBox.
    console.log("State card click navigation has been disabled");
    
    // Original functionality:
    /*
    if (hasChildren && !stateProps.viewOnlyMode) {
      setCrumbIds([...crumbIds, state.uid]);
      if (setLayer && state.children && state.children.length > 0) {
        setLayer(state.children[0].uid);
      }
    }
    */
  };

  // Custom handlers that directly call the right functions
  const handleShowComments = () => {
    console.log(`Showing comments for state ${state.uid}`);
    setShowingCommentsFor(state.uid);
  };
  
  const handleShowPapers = () => {
    console.log(`Showing papers for state ${state.uid}`);
    setShowingPapersFor(state.uid);
  };
  
  const handleDeleteSelf = () => {
    console.log(`Deleting state ${state.uid}`);
    if (stateProps.setActivityProps && setIsUnsavedChanges) {
      stateProps.setActivityProps((prevActivity: any) => {
        const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
        // Find and remove this state
        const removeState = (parent) => {
          if (!parent) return false;
          
          if (parent.children) {
            const index = parent.children.findIndex(child => child.uid === state.uid);
            if (index !== -1) {
              parent.children.splice(index, 1);
              return true;
            }
            
            // Try to find in nested children
            for (const child of parent.children) {
              if (removeState(child)) {
                return true;
              }
            }
          }
          return false;
        };
        
        removeState(updatedActivity);
        setIsUnsavedChanges(true);
        return updatedActivity;
      });
    }
  };

  // Function to create a new state card
  const createNewStateCard = (position: 'above' | 'below') => {
    if (!stateProps.setActivityProps || !setIsUnsavedChanges) return;
    
    // Both buttons should create new state cards of the same type
    // Parent-child relationship depends on the card's type
    let newType = state.type;
    let addAsChild = position === 'below';
    
    // Create a hierarchical structure based on the type
    const createHierarchyState = (type: string, parentId: string): any => {
      const stateId = `new-state-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      const newState = {
        uid: stateId,
        type: type,
        text: `New ${type}`,
        children: [],
        // Add required properties to satisfy TypeScript
        setImageLink: () => {},
        setVideoLink: () => {},
        setActivityProps: () => {}
      };
      
      // Create child states based on hierarchy
      let childState = null;
      
      if (type === "composite task") {
        childState = createHierarchyState("task", stateId);
        newState.children = [childState];
      } else if (type === "task") {
        childState = createHierarchyState("composite skill", stateId);
        newState.children = [childState];
      } else if (type === "composite skill") {
        childState = createHierarchyState("skill", stateId);
        newState.children = [childState];
      }
      
      return newState;
    };
    
    // Create the new state with its hierarchy
    const newStateWithHierarchy = createHierarchyState(newType, "");
    
    stateProps.setActivityProps((prevActivity: any) => {
      const updatedActivity = JSON.parse(JSON.stringify(prevActivity));
      
      // Function to find and update a state in the tree
      const addState = (parent) => {
        if (!parent) return false;
        
        // If this is the parent we're looking for
        if (parent.uid === state.uid) {
          if (addAsChild) {
            // Add as a child of current state
            if (!parent.children) parent.children = [];
            parent.children.push(newStateWithHierarchy);
            return true;
          } else {
            // We're adding a sibling, so we need to go up a level
            return false; // This will be handled outside this function
          }
        }
        
        // If this is a direct parent of our target state
        if (parent.children) {
          const index = parent.children.findIndex(child => child.uid === state.uid);
          if (index !== -1) {
            // Add as a sibling
            if (position === 'above') {
              parent.children.splice(index, 0, newStateWithHierarchy);
            } else {
              parent.children.splice(index + 1, 0, newStateWithHierarchy);
            }
            return true;
          }
          
          // Try to find in nested children
          for (const child of parent.children) {
            if (addState(child)) {
              return true;
            }
          }
        }
        
        return false;
      };
      
      // Try to add the state to the activity
      if (!addState(updatedActivity)) {
        // If we couldn't find the parent state directly, add it at the root level
        if (!updatedActivity.children) updatedActivity.children = [];
        
        // Find the current state in the root children
        const rootIndex = updatedActivity.children.findIndex(child => child.uid === state.uid);
        if (rootIndex !== -1) {
          if (position === 'above') {
            updatedActivity.children.splice(rootIndex, 0, newStateWithHierarchy);
          } else if (position === 'below' && !addAsChild) {
            updatedActivity.children.splice(rootIndex + 1, 0, newStateWithHierarchy);
          } else if (position === 'below' && addAsChild) {
            // Add as a child
            if (!updatedActivity.children[rootIndex].children) {
              updatedActivity.children[rootIndex].children = [];
            }
            updatedActivity.children[rootIndex].children.push(newStateWithHierarchy);
          }
        } else {
          // If we can't find the state, just add to the end
          updatedActivity.children.push(newStateWithHierarchy);
        }
      }
      
      setIsUnsavedChanges(true);
      return updatedActivity;
    });
  };

  // Render add button styles
  const renderAddButton = (position: 'above' | 'below') => {
    if (stateProps.viewOnlyMode || !stateProps.editing) return null;
    
    // Both buttons should show the same state type
    const tooltipText = `Add new ${state.type}`;
    
    return (
      <div
        className="add-state-button flex items-center justify-center"
        onClick={(e) => {
          e.stopPropagation();
          createNewStateCard(position);
        }}
        style={{
          cursor: 'pointer',
          height: '24px',
          width: '24px',
          background: '#f0f0f0',
          borderRadius: '50%',
          margin: '4px auto',
          border: '1px solid #ddd'
        }}
        data-tooltip={tooltipText}
      >
        <Plus size={16} />
      </div>
    );
  };

  return (
    <div className="hsm-tree-node" style={{ 
      marginLeft: level === 0 ? 0 : `${level * 20}px`,
      marginBottom: level === 0 ? '0.4rem' : '0.4rem'
    }}>
      {renderAddButton('above')}
      <div className="hsm-tree-node-header flex items-center">
        <div 
          className="hsm-tree-toggle mr-3" 
          onClick={toggleExpand}
          style={{ 
            cursor: hasChildren ? "pointer" : "default",
            opacity: hasChildren ? 1 : 0.3,
            width: "26px",
            height: "26px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#555",
            marginTop: level === 0 ? "25px" : "15px",
            backgroundColor: hasChildren 
              ? expanded 
                ? "#e0e0e0" 
                : "#f0f0f0" 
              : "transparent",
            border: hasChildren 
              ? expanded 
                ? "1px solid #aaa" 
                : "1px solid #ddd" 
              : "1px solid transparent",
            boxShadow: expanded && hasChildren ? "0 1px 3px rgba(0,0,0,0.1)" : "none"
          }}
        >
          {hasChildren ? (
            expanded ? (
              <ChevronDown size={20} stroke="#333" />
            ) : (
              <ChevronRight size={20} stroke="#333" />
            )
          ) : (
            <ChevronRight size={20} stroke="#ccc" />
          )}
        </div>
        <div className="hsm-tree-state-container flex-grow">
          <HSMStateBox
            key={state.uid}
            {...state}
            {...stateProps}
            imageLink={imageLink}
            videoLink={videoLink}
            setImageLink={setImageLink}
            setVideoLink={setVideoLink}
            showComments={handleShowComments}
            showPapers={handleShowPapers}
            deleteSelf={handleDeleteSelf}
            onClickDeepout={() => {
              if (crumbIds.length > 1) {
                setCrumbIds(crumbIds.slice(0, -1));
              }
            }}
          />
        </div>
      </div>
      {renderAddButton('below')}
      {expanded && hasChildren && (
        <div className="hsm-tree-children" style={{ paddingLeft: level === 0 ? 0 : '20px' }}>
          {state.children
            .filter(childState => {
              // For robot mode, apply hierarchical filtering
              if (!isHumanMode) {
                // If parent is a composite task, only show direct task children
                if (state.type === "composite task") {
                  return childState.type === "task";
                }
                // If parent is a task, only show direct composite skill children
                else if (state.type === "task") {
                  return childState.type === "composite skill";
                }
                // If parent is a composite skill, only show direct skill children
                else if (state.type === "composite skill") {
                  return childState.type === "skill";
                }
              }
              // In human mode, already filtered at the component level
              return true;
            })
            .map((childState) => (
              <HSMTreeNode
                key={childState.uid}
                state={childState}
                level={level + 1}
                stateProps={stateProps}
                crumbIds={crumbIds}
                setCrumbIds={setCrumbIds}
                setLayer={setLayer}
                setShowingCommentsFor={setShowingCommentsFor}
                setShowingPapersFor={setShowingPapersFor} 
                setIsUnsavedChanges={setIsUnsavedChanges}
              />
            ))}
        </div>
      )}
    </div>
  );
};

type HSMTreeViewProps = {
  rootStates: StateBoxProps[];
  crumbIds: string[];
  setCrumbIds: (crumbs: string[]) => void;
  setLayer?: React.Dispatch<React.SetStateAction<string>>;
  commonStateProps: Partial<StateBoxProps>;
  setShowingCommentsFor: (id: string) => void;
  setShowingPapersFor: (id: string) => void;
  setIsUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
};

const HSMTreeView: React.FC<HSMTreeViewProps> = ({
  rootStates,
  crumbIds,
  setCrumbIds,
  setLayer,
  commonStateProps,
  setShowingCommentsFor,
  setShowingPapersFor,
  setIsUnsavedChanges
}) => {
  if (!Array.isArray(rootStates) || rootStates.length === 0) {
    return <h1 className="mx-auto">Workflow coming soon</h1>;
  }

  // Ensure the root level has properly defined image and video handlers
  const rootStateProps = {
    ...commonStateProps,
    // Explicitly set these at the root level to ensure they're defined
    showComments: commonStateProps.showComments || (() => {
      console.log("Root level comment handler called");
      setShowingCommentsFor(rootStates[0]?.uid || "");
    }),
    showPapers: commonStateProps.showPapers || (() => {
      console.log("Root level paper handler called");
      setShowingPapersFor(rootStates[0]?.uid || "");
    }),
    discussionButtons: commonStateProps.discussionButtons ?? true,
    // Ensure we have setImageLink and setVideoLink defined
    setImageLink: commonStateProps.setImageLink || ((link: string) => {
      console.log("Root level setImageLink called with:", link);
      // If we have a way to update the activity props, use it
      if (commonStateProps.setActivityProps) {
        commonStateProps.setActivityProps((prevProps: any) => ({
          ...prevProps,
          imageLink: link
        }));
      }
    }),
    setVideoLink: commonStateProps.setVideoLink || ((link: string) => {
      console.log("Root level setVideoLink called with:", link);
      // If we have a way to update the activity props, use it
      if (commonStateProps.setActivityProps) {
        commonStateProps.setActivityProps((prevProps: any) => ({
          ...prevProps,
          videoLink: link
        }));
      }
    })
  };

  const isRobotMode = rootStateProps.humanMode === false;

  return (
    <div className="hsm-tree-view w-full max-w-full overflow-x-auto mx-auto" style={{ padding: '0.5rem 0' }}>
      <div className="tree-view-container" style={{ paddingLeft: '7rem' }}>
        {rootStates
          .filter(state => {
            // For robot mode, initially only show composite tasks
            if (isRobotMode && state.type !== "composite task") {
              return false;
            }
            
            // For human mode, filter out composite skill and skill level states
            if (rootStateProps.humanMode === true) {
              return state.type !== "composite skill" && state.type !== "skill";
            }
            
            return true;
          })
          .map((state) => (
            <HSMTreeNode
              key={state.uid}
              state={state}
              level={0}
              stateProps={rootStateProps}
              crumbIds={crumbIds}
              setCrumbIds={setCrumbIds}
              setLayer={setLayer}
              setShowingCommentsFor={setShowingCommentsFor}
              setShowingPapersFor={setShowingPapersFor}
              setIsUnsavedChanges={setIsUnsavedChanges}
            />
          ))}
      </div>
    </div>
  );
};

export default HSMTreeView; 