import { ObjectId } from "bson";

export function nextUID(): string {
  return new ObjectId().toHexString();
}

export enum UserOccupation {
  ROBOTICIST = "Roboticist",
  CLINICIAN = "Clinician",
  OTHER = "Other",
}
