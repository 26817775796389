import React, { useState } from "react";
import { ObjectId } from "bson";
import NavigationBar from "./components/NavBar";
import Footer from "./components/Footer";
import PageRoutes from "./Pages/PageRoutes";
import AdminNavbar from "./components/AdminNavbar";
import "./App.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

const nextUID = () => new ObjectId().toHexString();

function App() {
  const location = useLocation();
  const [cursor, setCursor] = useState("auto");
  const userVar = window.sessionStorage?.getItem("user");
  const [user, setUser] = useState(
    userVar !== null ? JSON.parse(userVar) : undefined
  );
  const isLoggedIn = user !== undefined;
  const isSuperAdmin = user && user.role === "superadmin";
  const loggedInWithoutOccupation = isLoggedIn && !user.occupation;
  const PublicWorkflowTableProps = {
    userContext: user,
    isPrivate: false,
    setCursor: setCursor,
  };
  
  const [activeButton, setActiveButton] = useState(() => {
    const storedActiveButton = window.sessionStorage?.getItem("activeButton");
    // If user is not a superadmin and stored button is 1 (Users) or 4 (Credits), default to 2 (Avatars)
    if ((storedActiveButton === "1" || storedActiveButton === "4") && isLoggedIn && !isSuperAdmin) {
      return 2;
    }
    return storedActiveButton !== null ? parseInt(storedActiveButton) : 2;
  });

  const rendererProps = {
    userContext: user,
    nextUID: nextUID,
    setCursor: setCursor,
  };

  const setUserWrapper = (newUser) => {
    if (newUser) {
      const { password, ...userWithoutSensitiveInfo } = newUser;
      setUser(userWithoutSensitiveInfo);

      window.sessionStorage.setItem(
        "user",
        JSON.stringify(userWithoutSensitiveInfo)
      );
    } else {
      setUser(undefined);
      window.sessionStorage.removeItem("user");
    }
    console.log("New user set", newUser);
  };

  const handleButtonClick = (buttonId) => {
    // Prevent non-superadmins from accessing Users or Credits pages
    if ((buttonId === 1 || buttonId === 4) && isLoggedIn && !isSuperAdmin) {
      buttonId = 2; // Redirect to Avatars instead
    }
    
    if (window.location.pathname !== "/admin") {
      window.location.href = "/admin";
      window.sessionStorage.setItem("activeButton", buttonId);
    } else {
      setActiveButton(buttonId);
      window.sessionStorage.setItem("activeButton", buttonId);
    }
  };

  return (
    <div>
      {location.pathname !== "/admin" && (
        <NavigationBar userContext={user} setUserContext={setUserWrapper} />
      )}
      <div className="App" style={{ cursor }}>
        <PageRoutes
          user={user}
          setUserWrapper={setUserWrapper}
          loggedInWithoutOccupation={loggedInWithoutOccupation}
          setCursor={setCursor}
          isLoggedIn={isLoggedIn}
          rendererProps={rendererProps}
          PublicWorkflowTableProps={PublicWorkflowTableProps}
          activeButton={activeButton}
          handleButtonClick={handleButtonClick}
        />
      </div>
      <Footer />
    </div>
  );
}

export default App;
