import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { StateBoxProps } from "../utils/interface/HSM";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

export type SidebarTextInputProps = {
  placeholder: string;
  text: string;
  setText: React.Dispatch<React.SetStateAction<string>>;
};

export const SidebarTextInput = ({
  placeholder,
  text,
  setText,
}: SidebarTextInputProps) => {
  return (
    <textarea
      className="w-full p-3 border border-gray-300 rounded-md resize-none overflow-y-auto focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
      placeholder={placeholder}
      onChange={(e) => setText(e.target.value)}
      value={text}
      rows={3}
      style={{ minHeight: "80px" }}
    />
  );
};

export type SidebarProps = {
  title: string;
  description: string;
  children?: JSX.Element[];
  innerClassName: string;
  textInputs: any[];
  canSubmit: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

export const Sidebar = ({
  title,
  description,
  children = [],
  innerClassName,
  textInputs,
  canSubmit,
  onSubmit,
  onCancel,
}: SidebarProps) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white w-[500px] max-w-[90vw] p-4 rounded-lg shadow-2xl">
        <div className="flex justify-between items-center mb-3 border-b pb-2">
          <h2 className="text-xl font-bold text-gray-800">{title}</h2>
          <button
            onClick={onCancel}
            className="text-gray-500 hover:text-gray-700 transition-colors focus:outline-none"
            aria-label="Close"
          >
            <RiCloseLine className="h-6 w-6" />
          </button>
        </div>
        
        <div className={`${innerClassName} mb-3`}>{children}</div>

        <div className="mb-2 text-gray-700 font-medium">
          <p>{description}</p>
        </div>
        
        {textInputs.map((prop, index) => (
          prop.Component ? 
            <prop.Component key={index} {...prop} /> : 
            <SidebarTextInput key={index} {...prop} />
        ))}

        <div className="flex justify-end space-x-3 mt-3">
          <button
            onClick={onCancel}
            className="px-4 py-1.5 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={canSubmit ? onSubmit : null}
            disabled={!canSubmit}
            className={`px-4 py-1.5 rounded-md text-white ${
              canSubmit 
                ? "bg-blue-600 hover:bg-blue-700" 
                : "bg-blue-300 cursor-not-allowed"
            } transition-colors`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export type SidebarContentProps = {
  title: string;
  description: string;
  text: string;
  modelInfo: string;
  textAsLink: boolean;
  extraInfo?: string;
};

export const SidebarContentBox = ({
  title,
  description,
  text,
  modelInfo,
  textAsLink,
  extraInfo,
}: SidebarContentProps) => {
  const formattedText =
    textAsLink && text.indexOf("http") === -1 ? "http://" + text : text;

  const renderTitle = () => {
    if (textAsLink) {
      return (
        <a target="_blank" href={formattedText} className="hover:underline">
          <h4 className="text-sm font-bold text-blue-600">{title}</h4>
        </a>
      );
    } else {
      return <h4 className="text-sm font-bold text-gray-800">{title}</h4>;
    }
  };

  const renderText = () => {
    if (textAsLink) {
      return (
        <p className="my-1 text-sm">
          <span className="font-medium">Link: </span>
          <a href={formattedText} className="text-blue-500 hover:underline truncate inline-block max-w-[300px]" target="_blank">{formattedText}</a>
          {modelInfo && (
            <div className="text-xs mt-1">
              <span className="font-medium">Model Info: </span>
              {modelInfo}
            </div>
          )}
        </p>
      );
    } else {
      return <p className="my-1 text-sm text-gray-700">{text}</p>;
    }
  };

  return (
    <div className="bg-white p-2 rounded-md mb-2 shadow-sm">
      <div className="flex items-center space-x-2 flex-wrap mb-1">
        {renderTitle()}
        <span className="text-xs px-1.5 py-0.5 bg-gray-200 rounded-full text-gray-700">{description}</span>
      </div>
      <div>{renderText()}</div>
      {extraInfo && <p className="text-xs text-gray-500">{extraInfo}</p>}
    </div>
  );
};

export type CommentsSidebarProps = {
  stateProps: StateBoxProps; // props for the state being commented on
  // type: string;
  // stateText: string;
  children?: any[];
  addComment: (comment: string) => void;
  hideComments: () => void;
  deleteDbComment?: (commentId: string) => void; // Add function to delete db comments
};

export const CommentsSidebar = ({
  stateProps,
  children = [],
  addComment,
  hideComments,
  deleteDbComment,
}: CommentsSidebarProps) => {
  const [commentText, setCommentText] = useState("");
  useEffect(() => {
    setCommentText("");
  }, [stateProps.uid]);

  // Get current user from session storage
  const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
  const isAdmin = currentUser?.role === "admin" || currentUser?.role === "superadmin";
  const currentUserId = currentUser?.id || "";
  
  console.log("Current user info:", {
    name: currentUser?.name,
    id: currentUserId,
    role: currentUser?.role,
    isAdmin,
    sessionData: JSON.stringify(currentUser, null, 2)
  });

  const handleDeleteComment = (comment) => {
    console.log("Attempting to delete comment:", {
      commentId: comment._id,
      commentAuthor: comment.author,
      commentUserId: comment.userId,
      currentUserId,
      isOwner: comment.userId === currentUserId,
      isAdmin
    });
    
    if (comment.fromDb && comment._id) {
      // Check if user has permission to delete this comment
      if (comment.userId === currentUserId || isAdmin) {
        console.log("Permission granted to delete comment");
        // If we have a dedicated delete function, use it
        if (deleteDbComment) {
          deleteDbComment(comment._id);
        } else {
          // Otherwise, call the API directly
          axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URI}/comments/${comment._id}`, {
            params: {
              userId: currentUserId,
              role: currentUser?.role || ''
            }
          })
            .then(() => {
              console.log("Comment deleted successfully");
              // Reload the page to refresh comments
              window.location.reload();
            })
            .catch(err => {
              console.error("Error deleting comment:", err);
              if (err.response && err.response.status === 403) {
                alert("Permission denied: " + (err.response.data.error || "You can only delete your own comments"));
              }
            });
        }
      } else {
        console.log("Permission denied: You can only delete your own comments");
        alert("You can only delete your own comments");
      }
    }
  };

  if (!stateProps) {
    hideComments();
    return null;
  }

  const stateType = stateProps.type;
  const stateText = stateProps.text;
  let textAreaProps: SidebarTextInputProps = {
    placeholder: "Enter your comment here...",
    setText: setCommentText,
    text: commentText,
  };

  const getStateTypeLabel = (type) => {
    switch(type?.toLowerCase()) {
      case 'comptask': return 'Composite Task';
      case 'task': return 'Task';
      case 'compskill': return 'Composite Skill';
      case 'skill': return 'Skill';
      default: return type || 'State';
    }
  };

  let sidebarProps: SidebarProps = {
    title: "Comments",
    innerClassName: "max-h-[350px] overflow-y-auto bg-gray-100 p-3 rounded",
    description: `${getStateTypeLabel(stateType)}: ${stateText}`,
    textInputs: [textAreaProps],
    canSubmit: commentText.trim() !== "",
    onSubmit: () => {
      addComment(commentText);
      setCommentText("");
    },
    onCancel: hideComments,
  };

  return (
    <Sidebar {...sidebarProps}>
      {children.length === 0 ? (
        [<div key="no-comments" className="text-center py-4 text-gray-500">
          No comments yet. Be the first to comment!
        </div>]
      ) : (
        children.map((comment, index) => {
          const timestamp = comment.timestamp ? new Date(comment.timestamp).toLocaleString() : '';
          let contentProps: SidebarContentProps = {
            title: comment.author,
            modelInfo: "",
            description: comment.occupation || "User",
            text: comment.text,
            textAsLink: false,
            extraInfo: timestamp
          };
          
          // Only show delete button if user owns the comment or is admin
          const canDelete = comment.fromDb && (comment.userId === currentUserId || isAdmin);
          
          return (
            <div key={index} className="relative">
              <SidebarContentBox {...contentProps} />
              {canDelete && (
                <div className="absolute top-3 right-3">
                  <button 
                    onClick={() => handleDeleteComment(comment)}
                    className="text-red-500 hover:text-red-700 transition-colors p-1 rounded-full hover:bg-red-100"
                  >
                    <DeleteIcon fontSize="small" />
                  </button>
                </div>
              )}
            </div>
          );
        })
      )}
    </Sidebar>
  );
};

export type PapersSidebarProps = {
  stateProps: StateBoxProps;
  children?: any[];
  addPaper: (title: string, url: string, modelInfo: string) => void;
  hidePapers: () => void;
  deleteDbPaper?: (paperId: string) => void; // Add function to delete db papers
};

export const PapersSidebar = ({
  stateProps,
  children = [],
  addPaper,
  hidePapers,
  deleteDbPaper,
}: PapersSidebarProps) => {
  const [paperTitle, setPaperTitle] = useState("");
  const [paperUrl, setPaperUrl] = useState("");
  const [modelInfo, setModelInfo] = useState("");
  useEffect(() => {
    setPaperTitle("");
    setPaperUrl("");
    setModelInfo("");
  }, [stateProps.uid]);

  // Get current user from session storage
  const currentUser = JSON.parse(sessionStorage.getItem("user") || "{}");
  const isAdmin = currentUser?.role === "admin" || currentUser?.role === "superadmin";
  const currentUserId = currentUser?.id || "";

  const handleDeletePaper = (paper) => {
    if (paper.fromDb && paper._id) {
      // Check if user has permission to delete this paper
      if (paper.userId === currentUserId || isAdmin) {
        console.log("Permission granted to delete paper");
        // If we have a dedicated delete function, use it
        if (deleteDbPaper) {
          deleteDbPaper(paper._id);
        } else {
          // Otherwise, call the API directly
          axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URI}/papers/${paper._id}`, {
            params: {
              userId: currentUserId,
              role: currentUser?.role || ''
            }
          })
            .then(() => {
              console.log("Paper deleted successfully");
              // Reload the page to refresh papers
              window.location.reload();
            })
            .catch(err => {
              console.error("Error deleting paper:", err);
              if (err.response && err.response.status === 403) {
                alert("Permission denied: " + (err.response.data.error || "You can only delete your own papers"));
              }
            });
        }
      } else {
        console.log("Permission denied: You can only delete your own papers");
        alert("You can only delete your own papers");
      }
    }
  };

  if (!stateProps) {
    hidePapers();
    return null;
  }

  const stateType = stateProps.type;
  const stateText = stateProps.text;
  
  // Compact text input specifically for papers form
  const PaperTextInput = ({ placeholder, text, setText }) => {
    return (
      <textarea
        className="w-full p-2 mb-2 border border-gray-300 rounded-md resize-none overflow-y-auto focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-transparent"
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
        value={text}
        rows={2}
        style={{ minHeight: "40px" }}
      />
    );
  };
  
  let sidebarProps: SidebarProps = {
    title: "Related papers",
    innerClassName: "max-h-[200px] overflow-y-auto bg-gray-100 p-2 rounded",
    description: `Research papers relating to '${stateType}': '${stateText}'`,
    textInputs: [
      { 
        placeholder: "Enter paper title...",
        setText: setPaperTitle,
        text: paperTitle,
        Component: PaperTextInput
      },
      { 
        placeholder: "Enter paper URL...",
        setText: setPaperUrl,
        text: paperUrl,
        Component: PaperTextInput
      },
      { 
        placeholder: "(optional) Enter model information...",
        setText: setModelInfo,
        text: modelInfo,
        Component: PaperTextInput
      }
    ],
    canSubmit: paperTitle !== "" && paperUrl !== "",
    onSubmit: () => {
      addPaper(paperTitle, paperUrl, modelInfo);
      setPaperTitle("");
      setPaperUrl("");
      setModelInfo("");
    },
    onCancel: hidePapers,
  };

  return (
    <Sidebar {...sidebarProps}>
      {children.length === 0 ? (
        [<div key="no-papers" className="text-center py-4 text-gray-500">
          No papers yet. Add the first research paper!
        </div>]
      ) : (
        children.map((paper, index) => {
          const canDelete = paper.fromDb && (paper.userId === currentUserId || isAdmin);
          
          let contentProps: SidebarContentProps = {
            title: paper.title,
            modelInfo: paper.modelInfo,
            description: `Posted by ${paper.author} ${paper.stateText ? `(For: ${paper.stateText})` : ""}`,
            text: paper.url,
            textAsLink: true,
          };
          
          return (
            <div key={index} className="relative">
              <SidebarContentBox {...contentProps} />
              {canDelete && (
                <div className="absolute top-3 right-3">
                  <button 
                    onClick={() => handleDeletePaper(paper)}
                    className="text-red-500 hover:text-red-700 transition-colors p-1 rounded-full hover:bg-red-100"
                  >
                    <DeleteIcon fontSize="small" />
                  </button>
                </div>
              )}
            </div>
          );
        })
      )}
    </Sidebar>
  );
};
